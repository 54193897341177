import React, { useState } from 'react';
import Button from '@mui/material/Button';
import './Amazon.css';
// import OlaF from "public./OlaF.png";

const ProductDetails = ({ product }) => {
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedDisplayType, setSelectedDisplayType] = useState('');
    const [selectedDimensions, setSelectedDimensions] = useState('');
    const [selectedAge, setSelectedAge] = useState('');

    return (
        <div className='intro2'>
        <div className='port-5'>
            <a href={product.link}>
           
                <img src={product.image} alt="OlaF" width="235px" target="_blank" rel="noopener noreferrer" loading="lazy" className="pfp1" />
                {/* {product.image} */}
              

            </a>
    
            <h1>{product.title}</h1>
            <h2>{product.description}</h2>
            

            <div className="dropdown-section">
                <label>Brand:</label>
                <select value={selectedBrand} onChange={(e) => setSelectedBrand(e.target.value)}>
                    <option value="">Select Brand</option>
                    {product.brands.map((brand, index) => (
                        <option key={index} value={brand}>{brand}</option>
                    ))}
                </select>

                <label>Color:</label>
                <select value={selectedColor} onChange={(e) => setSelectedColor(e.target.value)}>
                    <option value="">Select Color</option>
                    {product.colors.map((color, index) => (
                        <option key={index} value={color}>{color}</option>
                    ))}
                </select>

                <label>Display Type:</label>
                <select value={selectedDisplayType} onChange={(e) => setSelectedDisplayType(e.target.value)}>
                    <option value="">Select Display Type</option>
                    {product.displayTypes.map((displayType, index) => (
                        <option key={index} value={displayType}>{displayType}</option>
                    ))}
                </select>

                <label>Product Dimensions:</label>
                <select value={selectedDimensions} onChange={(e) => setSelectedDimensions(e.target.value)}>
                    <option value="">Select Dimensions</option>
                    {product.dimensions.map((dimension, index) => (
                        <option key={index} value={dimension}>{dimension}</option>
                    ))}
                </select>

                <label>Age:</label>
                <select value={selectedAge} onChange={(e) => setSelectedAge(e.target.value)}>
                    <option value="">Select Age</option>
                    {product.ages.map((age, index) => (
                        <option key={index} value={age}>{age}</option>
                    ))}
                </select>
            </div>

            <div className="about-section">
                <h3>About the Product</h3>
                <ul>
                    {product.about.map((point, index) => (
                        <li key={index}>{point}</li>
                    ))}
                </ul>
            </div>

            <Button
                className='color71'
                variant="outlined"
                href={product.amazonLink}
                target="_blank"
            >
                Purchase Now
            </Button>
        </div>

        
        </div>
    );
};

const ProductList = () => {
    const products = [
        {
            title: "Smart LED TV",
            description: "Experience vivid colors and exceptional clarity with our latest LED TV.",
            image:  process.env.PUBLIC_URL + "/assets/tv.jpg",
            link: "/IntroAI",
            amazonLink: "https://amzn.to/3YO0gr2",
            brands: ["Samsung", "LG", "Sony"],
            colors: ["Black", "Silver"],
            displayTypes: ["LED", "OLED"],
            dimensions: ["48x28x3", "55x30x4"],
            ages: ["All Ages"],
            about: ["4K Ultra HD", "Smart TV features", "Multiple HDMI ports"],
        },
        {
            title: "Wireless Earbuds",
            description: "Enjoy crystal clear sound with our ergonomic wireless earbuds.",
            image: process.env.PUBLIC_URL + "/assets/airpord.jpg",
            link: "/IntroAI",
            amazonLink: "https://amzn.to/3yHPJTz",
            brands: ["Apple", "Samsung", "Sony"],
            colors: ["White", "Black"],
            displayTypes: ["N/A"],
            dimensions: ["2x2x1", "3x2x1"],
            ages: ["Teens and Adults"],
            about: ["Noise-cancelling", "Long battery life", "Comfortable fit"],
        },
        {
            title: "Portable Bluetooth Speaker",
            description: "Take your music anywhere with our durable portable speaker.",
            image: process.env.PUBLIC_URL + "/assets/speaker.jpg",
            link: "/IntroAI",
            amazonLink: "https://amzn.to/3MbzwJC",
            brands: ["JBL", "Bose", "Sony"],
            colors: ["Red", "Blue", "Black"],
            displayTypes: ["N/A"],
            dimensions: ["6x4x3", "8x5x4"],
            ages: ["All Ages"],
            about: ["Waterproof", "12 hours playtime", "360-degree sound"],
        },
        {
            title: "Smartwatch",
            description: "Stay connected with our advanced smartwatch featuring fitness tracking.",
            image: process.env.PUBLIC_URL + "/assets/watch.jpg",
            link: "/IntroAI",
            amazonLink: "https://amzn.to/3WMPUVH",
            brands: ["Apple", "Samsung", "Fitbit"],
            colors: ["Black", "Silver", "Rose Gold"],
            displayTypes: ["AMOLED", "LCD"],
            dimensions: ["1.5x1.5x0.4", "1.8x1.8x0.5"],
            ages: ["Teens and Adults"],
            about: ["Heart rate monitoring", "GPS tracking", "Water-resistant"],
        },
        {
            title: "Gaming Laptop",
            description: "High-performance gaming laptop with cutting-edge graphics.",
            image: process.env.PUBLIC_URL + "/assets/game.jpg",
            link: "/IntroAI",
            amazonLink: "https://amzn.to/4dNpMkw",
            brands: ["Asus", "Acer", "Dell"],
            colors: ["Black", "Gray"],
            displayTypes: ["LED", "OLED"],
            dimensions: ["15x10x1", "16x11x1"],
            ages: ["Teens and Adults"],
            about: ["High refresh rate", "Backlit keyboard", "VR-ready"],
        },
        {
            title: "Digital Camera",
            description: "Capture every moment in stunning detail with our high-resolution camera.",
            image: process.env.PUBLIC_URL + "/assets/camera1.jpg",
            link: "/IntroAI",
            amazonLink: "https://amzn.to/4cxokBI",
            brands: ["Canon", "Nikon", "Sony"],
            colors: ["Black", "Silver"],
            displayTypes: ["LCD"],
            dimensions: ["5x4x3", "6x5x4"],
            ages: ["Teens and Adults"],
            about: ["4K video recording", "Interchangeable lenses", "Wi-Fi connectivity"],
        },
        {
            title: "Robot Vacuum Cleaner",
            description: "Keep your floors spotless with our smart robot vacuum.",
            image: process.env.PUBLIC_URL + "/assets/Robots.jpg",
            link: "/IntroAI",
            amazonLink: "https://amzn.to/4cpeYb2",
            brands: ["iRobot", "Eufy", "Shark"],
            colors: ["Black", "White"],
            displayTypes: ["N/A"],
            dimensions: ["13x13x3", "12x12x2.5"],
            ages: ["All Ages"],
            about: ["Self-charging", "Smart mapping", "Works with Alexa"],
        },
        {
            title: "Noise-Cancelling Headphones",
            description: "Experience immersive sound with our premium noise-cancelling headphones.",
            image: "path-to-image8.jpg",
            link: "/IntroAI",
            amazonLink: "https://amzn.to/3YO0gr2",
            brands: ["Bose", "Sony", "Sennheiser"],
            colors: ["Black", "Silver"],
            displayTypes: ["N/A"],
            dimensions: ["7x6x3", "8x7x3"],
            ages: ["Teens and Adults"],
            about: ["Over-ear design", "Long battery life", "High-fidelity sound"],
        },
        {
            title: "Electric Toothbrush",
            description: "Achieve a deep clean with our advanced electric toothbrush.",
            image: "path-to-image9.jpg",
            link: "/IntroAI",
            amazonLink: "https://amzn.to/3YO0gr2",
            brands: ["Oral-B", "Philips", "Colgate"],
            colors: ["White", "Blue", "Black"],
            displayTypes: ["N/A"],
            dimensions: ["1x1x8", "1.5x1.5x9"],
            ages: ["Teens and Adults"],
            about: ["Multiple brushing modes", "Rechargeable", "Pressure sensor"],
        },
        {
            title: "Fitness Tracker",
            description: "Monitor your health and fitness with our sleek fitness tracker.",
            image: "path-to-image10.jpg",
            link: "/IntroAI",
            amazonLink: "https://amzn.to/3YO0gr2",
            brands: ["Fitbit", "Garmin", "Samsung"],
            colors: ["Black", "Blue", "Pink"],
            displayTypes: ["AMOLED", "LCD"],
            dimensions: ["1.5x0.5x8", "1.8x0.6x9"],
            ages: ["Teens and Adults"],
            about: ["Sleep tracking", "Heart rate monitor", "Waterproof"],
        },
        {
            title: 'Smartphone Pro Max',
            description: 'Latest smartphone with a 6.8-inch display, 108MP camera, and 5000mAh battery.',
            image: '/images/smartphone-pro-max.jpg',
            link: 'https://example.com/smartphone-pro-max',
            brands: ['Brand A', 'Brand B', 'Brand C'],
            colors: ['Black', 'White', 'Blue'],
            displayTypes: ['AMOLED', 'LCD'],
            dimensions: ['160 x 75 x 8 mm', '165 x 78 x 9 mm'],
            ages: ['Adult', 'Teen'],
            amazonLink: 'https://amazon.com/smartphone-pro-max',
            about: [
                'High-resolution display',
                'Advanced camera features',
                'Long-lasting battery life'
            ]
        },
        {
            title: 'Wireless Earbuds 2.0',
            description: 'Compact wireless earbuds with noise cancellation and 20-hour battery life.',
            image: '/images/wireless-earbuds-2.0.jpg',
            link: 'https://example.com/wireless-earbuds-2.0',
            brands: ['Brand X', 'Brand Y'],
            colors: ['Black', 'White', 'Gray'],
            displayTypes: [],
            dimensions: ['Small'],
            ages: ['Adult', 'Teen'],
            amazonLink: 'https://amazon.com/wireless-earbuds-2.0',
            about: [
                'Active noise cancellation',
                'Comfortable fit',
                'Long battery life'
            ]
        },
        
        {
            title: '4K Ultra HD TV',
            description: '65-inch 4K Ultra HD TV with smart features and built-in streaming apps.',
            image: '/images/4k-ultra-hd-tv.jpg',
            link: 'https://example.com/4k-ultra-hd-tv',
            brands: ['Brand M', 'Brand N'],
            colors: ['Black', 'Silver'],
            displayTypes: ['4K LED'],
            dimensions: ['65 inch'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/4k-ultra-hd-tv',
            about: [
                'Crisp 4K resolution',
                'Smart TV functionality',
                'Multiple streaming services'
            ]
        },
        {
            title: 'Smartwatch Series 5',
            description: 'Advanced smartwatch with health tracking, GPS, and customizable watch faces.',
            image: '/images/smartwatch-series-5.jpg',
            link: 'https://example.com/smartwatch-series-5',
            brands: ['Brand Q', 'Brand R'],
            colors: ['Black', 'Rose Gold', 'Silver'],
            displayTypes: ['OLED'],
            dimensions: ['42mm', '46mm'],
            ages: ['Adult'],
            amazonLink: 'https://amazon.com/smartwatch-series-5',
            about: [
                'Health monitoring features',
                'Customizable watch faces',
                'GPS tracking'
            ]
        },
        {
            title: 'Gaming Laptop Ultra',
            description: 'High-performance gaming laptop with an Intel i7 processor and RTX 3070 graphics card.',
            image: '/images/gaming-laptop-ultra.jpg',
            link: 'https://example.com/gaming-laptop-ultra',
            brands: ['Brand L', 'Brand K'],
            colors: ['Black', 'Gray'],
            displayTypes: ['Full HD', '4K'],
            dimensions: ['15.6 inch'],
            ages: ['Teen', 'Adult'],
            amazonLink: 'https://amazon.com/gaming-laptop-ultra',
            about: [
                'Powerful gaming performance',
                'High refresh rate display',
                'Advanced cooling system'
            ]
        },
        {
            title: 'Bluetooth Speaker Mini',
            description: 'Portable Bluetooth speaker with 10-hour battery life and 360-degree sound.',
            image: '/images/bluetooth-speaker-mini.jpg',
            link: 'https://example.com/bluetooth-speaker-mini',
            brands: ['Brand E', 'Brand F'],
            colors: ['Black', 'Blue', 'Red'],
            displayTypes: [],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/bluetooth-speaker-mini',
            about: [
                '360-degree sound',
                'Long battery life',
                'Compact and portable'
            ]
        },
        {
            title: 'Instant Pot Pro',
            description: 'Multi-function pressure cooker with 10 cooking programs and a 6-quart capacity.',
            image: '/images/instant-pot-pro.jpg',
            link: 'https://example.com/instant-pot-pro',
            brands: ['Brand G', 'Brand H'],
            colors: ['Stainless Steel', 'Black'],
            displayTypes: [],
            dimensions: ['6 quart'],
            ages: ['Adult'],
            amazonLink: 'https://amazon.com/instant-pot-pro',
            about: [
                'Versatile cooking options',
                'Easy to use',
                'Large capacity'
            ]
        },
        {
            title: 'Cordless Vacuum Cleaner',
            description: 'Lightweight cordless vacuum with powerful suction and HEPA filter.',
            image: '/images/cordless-vacuum-cleaner.jpg',
            link: 'https://example.com/cordless-vacuum-cleaner',
            brands: ['Brand I', 'Brand J'],
            colors: ['White', 'Gray'],
            displayTypes: [],
            dimensions: ['Standard'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/cordless-vacuum-cleaner',
            about: [
                'Powerful suction',
                'HEPA filter',
                'Cordless convenience'
            ]
        },
        {
            title: 'Digital Camera Pro',
            description: 'High-resolution digital camera with 24MP sensor and 4K video recording.',
            image: '/images/digital-camera-pro.jpg',
            link: 'https://example.com/digital-camera-pro',
            brands: ['Brand Z', 'Brand Y'],
            colors: ['Black', 'Silver'],
            displayTypes: ['LCD'],
            dimensions: ['Compact'],
            ages: ['Adult', 'Teen'],
            amazonLink: 'https://amazon.com/digital-camera-pro',
            about: [
                '24MP resolution',
                '4K video recording',
                'Compact design'
            ]
        },
        {
            title: 'Electric Toothbrush',
            description: 'Rechargeable electric toothbrush with 5 brushing modes and a two-week battery life.',
            image: '/images/electric-toothbrush.jpg',
            link: 'https://example.com/electric-toothbrush',
            brands: ['Brand A', 'Brand B'],
            colors: ['White', 'Black'],
            displayTypes: [],
            dimensions: ['Standard'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/electric-toothbrush',
            about: [
                'Multiple brushing modes',
                'Rechargeable battery',
                'Effective plaque removal'
            ]
        },
        {
            title: 'Air Fryer Deluxe',
            description: 'High-capacity air fryer with adjustable temperature and 7 cooking presets.',
            image: '/images/air-fryer-deluxe.jpg',
            link: 'https://example.com/air-fryer-deluxe',
            brands: ['Brand C', 'Brand D'],
            colors: ['Black', 'Red'],
            displayTypes: [],
            dimensions: ['Large'],
            ages: ['Adult'],
            amazonLink: 'https://amazon.com/air-fryer-deluxe',
            about: [
                'Crispy food with less oil',
                'Multiple cooking presets',
                'Large capacity'
            ]
        },

        {
            title: 'Electric Scooter X1',
            description: 'Compact electric scooter with a top speed of 25 mph and a range of 30 miles.',
            image: '/images/electric-scooter-x1.jpg',
            link: 'https://example.com/electric-scooter-x1',
            brands: ['Brand G', 'Brand H'],
            colors: ['Black', 'White', 'Red'],
            displayTypes: [],
            dimensions: ['Foldable'],
            ages: ['Adult', 'Teen'],
            amazonLink: 'https://amazon.com/electric-scooter-x1',
            about: [
                'High speed and long range',
                'Foldable for easy storage',
                'Built-in safety features'
            ]
        },
        {
            title: 'Portable Blender Pro',
            description: 'Rechargeable portable blender with 6 blades and a 500ml capacity.',
            image: '/images/portable-blender-pro.jpg',
            link: 'https://example.com/portable-blender-pro',
            brands: ['Brand I', 'Brand J'],
            colors: ['Blue', 'Green', 'Pink'],
            displayTypes: [],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/portable-blender-pro',
            about: [
                'Powerful blending capability',
                'Rechargeable and portable',
                'Easy to clean'
            ]
        },
        {
            title: 'Digital Food Scale',
            description: 'Precision digital food scale with a maximum weight of 5 kg and tare function.',
            image: '/images/digital-food-scale.jpg',
            link: 'https://example.com/digital-food-scale',
            brands: ['Brand K', 'Brand L'],
            colors: ['Silver', 'Black'],
            displayTypes: ['LCD'],
            dimensions: ['Standard'],
            ages: ['Adult'],
            amazonLink: 'https://amazon.com/digital-food-scale',
            about: [
                'High precision measurements',
                'Tare function for easy weighing',
                'Sleek and compact design'
            ]
        },
        {
            title: 'Home Security Camera',
            description: 'Wireless home security camera with 1080p resolution and night vision.',
            image: '/images/home-security-camera.jpg',
            link: 'https://example.com/home-security-camera',
            brands: ['Brand M', 'Brand N'],
            colors: ['White', 'Black'],
            displayTypes: ['Camera'],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/home-security-camera',
            about: [
                'High-definition video quality',
                'Night vision capability',
                'Easy installation'
            ]
        },
        {
            title: 'Smart LED Light Bulbs',
            description: 'Set of 4 smart LED bulbs with adjustable brightness and color temperature.',
            image: '/images/smart-led-light-bulbs.jpg',
            link: 'https://example.com/smart-led-light-bulbs',
            brands: ['Brand O', 'Brand P'],
            colors: ['White'],
            displayTypes: ['LED'],
            dimensions: ['Standard'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/smart-led-light-bulbs',
            about: [
                'Adjustable brightness and color',
                'Energy-efficient LED technology',
                'Compatible with smart home systems'
            ]
        },
        {
            title: 'Yoga Mat Ultra',
            description: 'Non-slip yoga mat with extra cushioning and a thickness of 6mm.',
            image: '/images/yoga-mat-ultra.jpg',
            link: 'https://example.com/yoga-mat-ultra',
            brands: ['Brand Q', 'Brand R'],
            colors: ['Purple', 'Black', 'Gray'],
            displayTypes: [],
            dimensions: ['Standard'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/yoga-mat-ultra',
            about: [
                'Extra cushioning for comfort',
                'Non-slip surface',
                'Durable and easy to clean'
            ]
        },
        {
            title: 'Fitness Tracker Band',
            description: 'Wearable fitness tracker with heart rate monitoring, sleep tracking, and activity goals.',
            image: '/images/fitness-tracker-band.jpg',
            link: 'https://example.com/fitness-tracker-band',
            brands: ['Brand S', 'Brand T'],
            colors: ['Black', 'Blue', 'Green'],
            displayTypes: ['OLED'],
            dimensions: ['Adjustable'],
            ages: ['Adult', 'Teen'],
            amazonLink: 'https://amazon.com/fitness-tracker-band',
            about: [
                'Heart rate monitoring',
                'Sleep tracking',
                'Goal setting features'
            ]
        },
        {
            title: 'Coffee Maker Deluxe',
            description: '12-cup coffee maker with programmable settings and a built-in grinder.',
            image: '/images/coffee-maker-deluxe.jpg',
            link: 'https://example.com/coffee-maker-deluxe',
            brands: ['Brand U', 'Brand V'],
            colors: ['Black', 'Stainless Steel'],
            displayTypes: [],
            dimensions: ['Standard'],
            ages: ['Adult'],
            amazonLink: 'https://amazon.com/coffee-maker-deluxe',
            about: [
                'Programmable settings',
                'Built-in grinder',
                'Large capacity'
            ]
        },
        {
            title: 'Pet Camera with Treat Dispenser',
            description: 'Pet camera with HD video and a treat dispenser to interact with pets remotely.',
            image: '/images/pet-camera-treat-dispenser.jpg',
            link: 'https://example.com/pet-camera-treat-dispenser',
            brands: ['Brand W', 'Brand X'],
            colors: ['White', 'Black'],
            displayTypes: ['Camera'],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/pet-camera-treat-dispenser',
            about: [
                'HD video quality',
                'Interactive treat dispenser',
                'Remote access via app'
            ]
        },
        {
            title: 'Electric Wine Opener',
            description: 'Rechargeable electric wine opener with a foil cutter and storage base.',
            image: '/images/electric-wine-opener.jpg',
            link: 'https://example.com/electric-wine-opener',
            brands: ['Brand Y', 'Brand Z'],
            colors: ['Silver', 'Black'],
            displayTypes: [],
            dimensions: ['Standard'],
            ages: ['Adult'],
            amazonLink: 'https://amazon.com/electric-wine-opener',
            about: [
                'Effortless wine opening',
                'Rechargeable and easy to use',
                'Includes foil cutter and base'
            ]
        },
        {
            title: 'Air Purifier Premium',
            description: 'High-efficiency air purifier with HEPA filter and air quality monitoring.',
            image: '/images/air-purifier-premium.jpg',
            link: 'https://example.com/air-purifier-premium',
            brands: ['Brand A', 'Brand B'],
            colors: ['White', 'Black'],
            displayTypes: ['LCD'],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/air-purifier-premium',
            about: [
                'HEPA filtration',
                'Real-time air quality monitoring',
                'Quiet operation'
            ]
        },
        {
            title: 'Smart Doorbell Pro',
            description: 'Smart doorbell with 2-way audio, HD video, and motion detection.',
            image: '/images/smart-doorbell-pro.jpg',
            link: 'https://example.com/smart-doorbell-pro',
            brands: ['Brand C', 'Brand D'],
            colors: ['Black', 'White'],
            displayTypes: ['Camera'],
            dimensions: ['Standard'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/smart-doorbell-pro',
            about: [
                'High-definition video',
                'Two-way audio',
                'Motion detection alerts'
            ]
        },
        {
            title: 'Smart Thermostat',
            description: 'Wi-Fi enabled smart thermostat with remote control and energy-saving features.',
            image: '/images/smart-thermostat.jpg',
            link: 'https://example.com/smart-thermostat',
            brands: ['Brand E', 'Brand F'],
            colors: ['White', 'Black'],
            displayTypes: ['Touchscreen'],
            dimensions: ['Standard'],
            ages: ['Adult'],
            amazonLink: 'https://amazon.com/smart-thermostat',
            about: [
                'Remote control via app',
                'Energy-saving features',
                'Easy installation'
            ]
        },
        {
            title: 'Smart Thermostat Pro',
            description: 'Wi-Fi enabled thermostat with voice control and energy-saving features.',
            image: '/images/smart-thermostat-pro.jpg',
            link: 'https://example.com/smart-thermostat-pro',
            brands: ['Brand E', 'Brand F'],
            colors: ['White', 'Silver'],
            displayTypes: ['Digital'],
            dimensions: ['Standard'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/smart-thermostat-pro',
            about: [
                'Voice control compatible',
                'Energy-saving settings',
                'Remote access via app'
            ]
        },
        {
            title: 'Wireless Earbuds Elite',
            description: 'High-quality wireless earbuds with noise cancellation and long battery life.',
            image: '/images/wireless-earbuds-elite.jpg',
            link: 'https://example.com/wireless-earbuds-elite',
            brands: ['Brand G', 'Brand H'],
            colors: ['Black', 'White', 'Gray'],
            displayTypes: [],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/wireless-earbuds-elite',
            about: [
                'Noise-cancellation technology',
                'Long-lasting battery',
                'Comfortable fit'
            ]
        },
        {
            title: 'Electric Air Fryer',
            description: 'Versatile air fryer with digital controls and adjustable temperature settings.',
            image: '/images/electric-air-fryer.jpg',
            link: 'https://example.com/electric-air-fryer',
            brands: ['Brand I', 'Brand J'],
            colors: ['Black', 'Silver'],
            displayTypes: ['Digital'],
            dimensions: ['Medium'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/electric-air-fryer',
            about: [
                'Adjustable temperature settings',
                'Digital controls for easy use',
                'Healthy cooking option'
            ]
        },
        {
            title: 'High-Speed Blender',
            description: 'Powerful blender with multiple speed settings and a durable glass jar.',
            image: '/images/high-speed-blender.jpg',
            link: 'https://example.com/high-speed-blender',
            brands: ['Brand K', 'Brand L'],
            colors: ['Black', 'Red'],
            displayTypes: [],
            dimensions: ['Standard'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/high-speed-blender',
            about: [
                'Powerful motor with multiple speeds',
                'Durable glass jar',
                'Ideal for smoothies and soups'
            ]
        },
        {
            title: 'Smart Home Hub',
            description: 'Central hub for controlling smart home devices with voice and app integration.',
            image: '/images/smart-home-hub.jpg',
            link: 'https://example.com/smart-home-hub',
            brands: ['Brand M', 'Brand N'],
            colors: ['White', 'Black'],
            displayTypes: [],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/smart-home-hub',
            about: [
                'Voice and app control',
                'Compatible with various smart devices',
                'Easy setup'
            ]
        },
        {
            title: 'Electric Kettle Fast',
            description: 'Fast-boiling electric kettle with an auto shut-off and stainless steel finish.',
            image: '/images/electric-kettle-fast.jpg',
            link: 'https://example.com/electric-kettle-fast',
            brands: ['Brand O', 'Brand P'],
            colors: ['Stainless Steel', 'Black'],
            displayTypes: [],
            dimensions: ['Standard'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/electric-kettle-fast',
            about: [
                'Quick boiling time',
                'Auto shut-off for safety',
                'Sleek stainless steel design'
            ]
        },
        {
            title: 'Smart Lock System',
            description: 'Electronic smart lock with remote access and keyless entry features.',
            image: '/images/smart-lock-system.jpg',
            link: 'https://example.com/smart-lock-system',
            brands: ['Brand Q', 'Brand R'],
            colors: ['Black', 'Silver'],
            displayTypes: [],
            dimensions: ['Standard'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/smart-lock-system',
            about: [
                'Remote access via app',
                'Keyless entry',
                'Enhanced security features'
            ]
        },
        {
            title: 'Robotic Vacuum Cleaner',
            description: 'Autonomous vacuum cleaner with smart navigation and a high-efficiency filter.',
            image: '/images/robotic-vacuum-cleaner.jpg',
            link: 'https://example.com/robotic-vacuum-cleaner',
            brands: ['Brand S', 'Brand T'],
            colors: ['Black', 'Gray'],
            displayTypes: [],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/robotic-vacuum-cleaner',
            about: [
                'Smart navigation system',
                'High-efficiency filter',
                'Convenient automated cleaning'
            ]
        },
        {
            title: 'Bluetooth Speaker Portable',
            description: 'Portable Bluetooth speaker with waterproof design and up to 12 hours of playtime.',
            image: '/images/bluetooth-speaker-portable.jpg',
            link: 'https://example.com/bluetooth-speaker-portable',
            brands: ['Brand U', 'Brand V'],
            colors: ['Blue', 'Black', 'Red'],
            displayTypes: [],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/bluetooth-speaker-portable',
            about: [
                'Waterproof design',
                'Up to 12 hours of playtime',
                'High-quality sound'
            ]
        },
        {
            title: 'Cordless Drill Set',
            description: 'Rechargeable cordless drill with multiple speed settings and accessories included.',
            image: '/images/cordless-drill-set.jpg',
            link: 'https://example.com/cordless-drill-set',
            brands: ['Brand W', 'Brand X'],
            colors: ['Red', 'Black'],
            displayTypes: [],
            dimensions: ['Standard'],
            ages: ['Adult'],
            amazonLink: 'https://amazon.com/cordless-drill-set',
            about: [
                'Rechargeable battery',
                'Multiple speed settings',
                'Includes various accessories'
            ]
        },
        {
            title: 'Digital Camera Compact',
            description: 'Compact digital camera with high-resolution sensor and built-in Wi-Fi.',
            image: '/images/digital-camera-compact.jpg',
            link: 'https://example.com/digital-camera-compact',
            brands: ['Brand Y', 'Brand Z'],
            colors: ['Black', 'Silver'],
            displayTypes: ['LCD'],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/digital-camera-compact',
            about: [
                'High-resolution sensor',
                'Built-in Wi-Fi',
                'Compact and portable'
            ]
        },
        {
            title: 'Kitchen Mixer Stand',
            description: 'Stand mixer with multiple attachments and variable speed control for baking and cooking.',
            image: '/images/kitchen-mixer-stand.jpg',
            link: 'https://example.com/kitchen-mixer-stand',
            brands: ['Brand A', 'Brand B'],
            colors: ['White', 'Red'],
            displayTypes: [],
            dimensions: ['Standard'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/kitchen-mixer-stand',
            about: [
                'Variable speed control',
                'Includes various attachments',
                'Ideal for baking and cooking'
            ]
        },
        {
            title: 'Electric Grill Compact',
            description: 'Compact electric grill with adjustable temperature and non-stick surface.',
            image: '/images/electric-grill-compact.jpg',
            link: 'https://example.com/electric-grill-compact',
            brands: ['Brand C', 'Brand D'],
            colors: ['Black', 'Red'],
            displayTypes: [],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/electric-grill-compact',
            about: [
                'Adjustable temperature control',
                'Non-stick surface',
                'Compact design for easy storage'
            ]
        },
        {
            title: 'Outdoor Lantern LED',
            description: 'Durable outdoor lantern with LED lighting and solar charging capabilities.',
            image: '/images/outdoor-lantern-led.jpg',
            link: 'https://example.com/outdoor-lantern-led',
            brands: ['Brand E', 'Brand F'],
            colors: ['Green', 'Black'],
            displayTypes: ['LED'],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/outdoor-lantern-led',
            about: [
                'LED lighting',
                'Solar charging',
                'Durable and weather-resistant'
            ]
        },
        {
            title: 'Camping Stove Portable',
            description: 'Portable camping stove with adjustable flame and compact design for outdoor cooking.',
            image: '/images/camping-stove-portable.jpg',
            link: 'https://example.com/camping-stove-portable',
            brands: ['Brand G', 'Brand H'],
            colors: ['Black', 'Silver'],
            displayTypes: [],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/camping-stove-portable',
            about: [
                'Adjustable flame control',
                'Compact and lightweight',
                'Ideal for outdoor cooking'
            ]
        },
        {
            title: 'Fitness Tracker Smart',
            description: 'Smart fitness tracker with heart rate monitoring and activity tracking features.',
            image: '/images/fitness-tracker-smart.jpg',
            link: 'https://example.com/fitness-tracker-smart',
            brands: ['Brand I', 'Brand J'],
            colors: ['Black', 'Blue', 'Pink'],
            displayTypes: ['Digital'],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/fitness-tracker-smart',
            about: [
                'Heart rate monitoring',
                'Activity tracking',
                'Water-resistant'
            ]
        },
         {
        title: 'Smart Thermostat Pro',
        description: 'Wi-Fi enabled thermostat with voice control and energy-saving features.',
        image: '/images/smart-thermostat-pro.jpg',
        link: 'https://example.com/smart-thermostat-pro',
        brands: ['Brand E', 'Brand F'],
        colors: ['White', 'Silver'],
        displayTypes: ['Digital'],
        dimensions: ['Standard'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/smart-thermostat-pro',
        about: [
            'Voice control compatible',
            'Energy-saving settings',
            'Remote access via app'
        ]
    },
    {
        title: 'Wireless Earbuds Elite',
        description: 'High-quality wireless earbuds with noise cancellation and long battery life.',
        image: '/images/wireless-earbuds-elite.jpg',
        link: 'https://example.com/wireless-earbuds-elite',
        brands: ['Brand G', 'Brand H'],
        colors: ['Black', 'White', 'Gray'],
        displayTypes: [],
        dimensions: ['Compact'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/wireless-earbuds-elite',
        about: [
            'Noise-cancellation technology',
            'Long-lasting battery',
            'Comfortable fit'
        ]
    },
    {
        title: 'Electric Air Fryer',
        description: 'Versatile air fryer with digital controls and adjustable temperature settings.',
        image: '/images/electric-air-fryer.jpg',
        link: 'https://example.com/electric-air-fryer',
        brands: ['Brand I', 'Brand J'],
        colors: ['Black', 'Silver'],
        displayTypes: ['Digital'],
        dimensions: ['Medium'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/electric-air-fryer',
        about: [
            'Adjustable temperature settings',
            'Digital controls for easy use',
            'Healthy cooking option'
        ]
    },
    {
        title: 'High-Speed Blender',
        description: 'Powerful blender with multiple speed settings and a durable glass jar.',
        image: '/images/high-speed-blender.jpg',
        link: 'https://example.com/high-speed-blender',
        brands: ['Brand K', 'Brand L'],
        colors: ['Black', 'Red'],
        displayTypes: [],
        dimensions: ['Standard'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/high-speed-blender',
        about: [
            'Powerful motor with multiple speeds',
            'Durable glass jar',
            'Ideal for smoothies and soups'
        ]
    },
    {
        title: 'Smart Home Hub',
        description: 'Central hub for controlling smart home devices with voice and app integration.',
        image: '/images/smart-home-hub.jpg',
        link: 'https://example.com/smart-home-hub',
        brands: ['Brand M', 'Brand N'],
        colors: ['White', 'Black'],
        displayTypes: [],
        dimensions: ['Compact'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/smart-home-hub',
        about: [
            'Voice and app control',
            'Compatible with various smart devices',
            'Easy setup'
        ]
    },
    {
        title: 'Electric Kettle Fast',
        description: 'Fast-boiling electric kettle with an auto shut-off and stainless steel finish.',
        image: '/images/electric-kettle-fast.jpg',
        link: 'https://example.com/electric-kettle-fast',
        brands: ['Brand O', 'Brand P'],
        colors: ['Stainless Steel', 'Black'],
        displayTypes: [],
        dimensions: ['Standard'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/electric-kettle-fast',
        about: [
            'Quick boiling time',
            'Auto shut-off for safety',
            'Sleek stainless steel design'
        ]
    },
    {
        title: 'Smart Lock System',
        description: 'Electronic smart lock with remote access and keyless entry features.',
        image: '/images/smart-lock-system.jpg',
        link: 'https://example.com/smart-lock-system',
        brands: ['Brand Q', 'Brand R'],
        colors: ['Black', 'Silver'],
        displayTypes: [],
        dimensions: ['Standard'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/smart-lock-system',
        about: [
            'Remote access via app',
            'Keyless entry',
            'Enhanced security features'
        ]
    },
    {
        title: 'Robotic Vacuum Cleaner',
        description: 'Autonomous vacuum cleaner with smart navigation and a high-efficiency filter.',
        image: '/images/robotic-vacuum-cleaner.jpg',
        link: 'https://example.com/robotic-vacuum-cleaner',
        brands: ['Brand S', 'Brand T'],
        colors: ['Black', 'Gray'],
        displayTypes: [],
        dimensions: ['Compact'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/robotic-vacuum-cleaner',
        about: [
            'Smart navigation system',
            'High-efficiency filter',
            'Convenient automated cleaning'
        ]
    },
    {
        title: 'Bluetooth Speaker Portable',
        description: 'Portable Bluetooth speaker with waterproof design and up to 12 hours of playtime.',
        image: '/images/bluetooth-speaker-portable.jpg',
        link: 'https://example.com/bluetooth-speaker-portable',
        brands: ['Brand U', 'Brand V'],
        colors: ['Blue', 'Black', 'Red'],
        displayTypes: [],
        dimensions: ['Compact'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/bluetooth-speaker-portable',
        about: [
            'Waterproof design',
            'Up to 12 hours of playtime',
            'High-quality sound'
        ]
    },
    {
        title: 'Cordless Drill Set',
        description: 'Rechargeable cordless drill with multiple speed settings and accessories included.',
        image: '/images/cordless-drill-set.jpg',
        link: 'https://example.com/cordless-drill-set',
        brands: ['Brand W', 'Brand X'],
        colors: ['Red', 'Black'],
        displayTypes: [],
        dimensions: ['Standard'],
        ages: ['Adult'],
        amazonLink: 'https://amazon.com/cordless-drill-set',
        about: [
            'Rechargeable battery',
            'Multiple speed settings',
            'Includes various accessories'
        ]
    },
    {
        title: 'Digital Camera Compact',
        description: 'Compact digital camera with high-resolution sensor and built-in Wi-Fi.',
        image: '/images/digital-camera-compact.jpg',
        link: 'https://example.com/digital-camera-compact',
        brands: ['Brand Y', 'Brand Z'],
        colors: ['Black', 'Silver'],
        displayTypes: ['LCD'],
        dimensions: ['Compact'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/digital-camera-compact',
        about: [
            'High-resolution sensor',
            'Built-in Wi-Fi',
            'Compact and portable'
        ]
    },
    {
        title: 'Kitchen Mixer Stand',
        description: 'Stand mixer with multiple attachments and variable speed control for baking and cooking.',
        image: '/images/kitchen-mixer-stand.jpg',
        link: 'https://example.com/kitchen-mixer-stand',
        brands: ['Brand A', 'Brand B'],
        colors: ['White', 'Red'],
        displayTypes: [],
        dimensions: ['Standard'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/kitchen-mixer-stand',
        about: [
            'Variable speed control',
            'Includes various attachments',
            'Ideal for baking and cooking'
        ]
    },
    {
        title: 'Electric Grill Compact',
        description: 'Compact electric grill with adjustable temperature and non-stick surface.',
        image: '/images/electric-grill-compact.jpg',
        link: 'https://example.com/electric-grill-compact',
        brands: ['Brand C', 'Brand D'],
        colors: ['Black', 'Red'],
        displayTypes: [],
        dimensions: ['Compact'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/electric-grill-compact',
        about: [
            'Adjustable temperature control',
            'Non-stick surface',
            'Compact design for easy storage'
        ]
    },
    {
        title: 'Outdoor Lantern LED',
        description: 'Durable outdoor lantern with LED lighting and solar charging capabilities.',
        image: '/images/outdoor-lantern-led.jpg',
        link: 'https://example.com/outdoor-lantern-led',
        brands: ['Brand E', 'Brand F'],
        colors: ['Green', 'Black'],
        displayTypes: ['LED'],
        dimensions: ['Compact'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/outdoor-lantern-led',
        about: [
            'LED lighting',
            'Solar charging',
            'Durable and weather-resistant'
        ]
    },
    {
        title: 'Camping Stove Portable',
        description: 'Portable camping stove with adjustable flame and compact design for outdoor cooking.',
        image: '/images/camping-stove-portable.jpg',
        link: 'https://example.com/camping-stove-portable',
        brands: ['Brand G', 'Brand H'],
        colors: ['Black', 'Silver'],
        displayTypes: [],
        dimensions: ['Compact'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/camping-stove-portable',
        about: [
            'Adjustable flame control',
            'Compact and lightweight',
            'Ideal for outdoor cooking'
        ]
    },
    {
        title: 'Fitness Tracker Smart',
        description: 'Smart fitness tracker with heart rate monitoring and activity tracking features.',
        image: '/images/fitness-tracker-smart.jpg',
        link: 'https://example.com/fitness-tracker-smart',
        brands: ['Brand I', 'Brand J'],
        colors: ['Black', 'Blue', 'Pink'],
        displayTypes: ['Digital'],
        dimensions: ['Compact'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/fitness-tracker-smart',
        about: [
            'Heart rate monitoring',
            'Activity tracking',
            'Water-resistant'
        ]
    },
    {
        title: 'Home Security Camera',
        description: 'High-definition security camera with night vision and motion detection features.',
        image: '/images/home-security-camera.jpg',
        link: 'https://example.com/home-security-camera',
        brands: ['Brand K', 'Brand L'],
        colors: ['White', 'Black'],
        displayTypes: ['HD'],
        dimensions: ['Compact'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/home-security-camera',
        about: [
            'Night vision',
            'Motion detection',
            'HD video quality'
        ]
    },

    {

        title: 'Smart Light Bulb',
        description: 'LED smart light bulb with adjustable brightness and color temperature.',
        image: '/images/smart-light-bulb.jpg',
        link: 'https://example.com/smart-light-bulb',
        brands: ['Brand M', 'Brand N'],
        colors: ['White', 'Color-Changing'],
        displayTypes: [],
        dimensions: ['Standard'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/smart-light-bulb',
        about: [
            'Adjustable brightness and color temperature',
            'Smart home integration',
            'Energy-efficient LED'
        ]
    },
    {
        title: 'Portable Power Bank',
        description: 'High-capacity power bank with fast charging capabilities and multiple ports.',
        image: '/images/portable-power-bank.jpg',
        link: 'https://example.com/portable-power-bank',
        brands: ['Brand O', 'Brand P'],
        colors: ['Black', 'White', 'Blue'],
        displayTypes: [],
        dimensions: ['Compact'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/portable-power-bank',
        about: [
            'High-capacity battery',
            'Fast charging technology',
            'Multiple ports for devices'
        ]
    },
    {
        title: 'Home Espresso Machine',
        description: 'Automatic espresso machine with built-in grinder and milk frother.',
        image: '/images/home-espresso-machine.jpg',
        link: 'https://example.com/home-espresso-machine',
        brands: ['Brand Q', 'Brand R'],
        colors: ['Stainless Steel', 'Black'],
        displayTypes: ['LCD'],
        dimensions: ['Standard'],
        ages: ['Adult'],
        amazonLink: 'https://amazon.com/home-espresso-machine',
        about: [
            'Built-in grinder and milk frother',
            'Automatic brewing',
            'Customizable settings'
        ]
    },
    {
        title: 'Smart TV 4K Ultra HD',
        description: '4K Ultra HD smart TV with built-in streaming apps and voice control.',
        image: '/images/smart-tv-4k-ultra-hd.jpg',
        link: 'https://example.com/smart-tv-4k-ultra-hd',
        brands: ['Brand S', 'Brand T'],
        colors: ['Black'],
        displayTypes: ['4K Ultra HD'],
        dimensions: ['Large'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/smart-tv-4k-ultra-hd',
        about: [
            '4K Ultra HD resolution',
            'Built-in streaming apps',
            'Voice control'
        ]
    },
    {
        title: 'Wireless Charger Pad',
        description: 'Sleek wireless charging pad with fast charge support and anti-slip design.',
        image: '/images/wireless-charger-pad.jpg',
        link: 'https://example.com/wireless-charger-pad',
        brands: ['Brand U', 'Brand V'],
        colors: ['Black', 'White'],
        displayTypes: [],
        dimensions: ['Compact'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/wireless-charger-pad',
        about: [
            'Fast charging support',
            'Anti-slip design',
            'Compatible with most smartphones'
        ]
    },
    {
        title: 'Digital Photo Frame',
        description: 'High-resolution digital photo frame with slideshow and Wi-Fi connectivity.',
        image: '/images/digital-photo-frame.jpg',
        link: 'https://example.com/digital-photo-frame',
        brands: ['Brand W', 'Brand X'],
        colors: ['Black', 'Wood'],
        displayTypes: ['Digital'],
        dimensions: ['Standard'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/digital-photo-frame',
        about: [
            'High-resolution display',
            'Slideshow mode',
            'Wi-Fi connectivity for remote updates'
        ]
    },
    {
        title: 'Electric Toothbrush Sonic',
        description: 'Sonic electric toothbrush with multiple cleaning modes and a long-lasting battery.',
        image: '/images/electric-toothbrush-sonic.jpg',
        link: 'https://example.com/electric-toothbrush-sonic',
        brands: ['Brand Y', 'Brand Z'],
        colors: ['White', 'Black'],
        displayTypes: [],
        dimensions: ['Compact'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/electric-toothbrush-sonic',
        about: [
            'Sonic cleaning technology',
            'Multiple modes for different needs',
            'Long-lasting battery'
        ]
    },
    {
        title: 'Portable Air Conditioner',
        description: 'Compact portable air conditioner with remote control and adjustable cooling settings.',
        image: '/images/portable-air-conditioner.jpg',
        link: 'https://example.com/portable-air-conditioner',
        brands: ['Brand A', 'Brand B'],
        colors: ['White', 'Gray'],
        displayTypes: ['LCD'],
        dimensions: ['Compact'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/portable-air-conditioner',
        about: [
            'Adjustable cooling settings',
            'Remote control',
            'Compact and portable'
        ]
    },
    {
        title: 'Fitness Smart Scale',
        description: 'Smart scale with body composition analysis and app connectivity for tracking health metrics.',
        image: '/images/fitness-smart-scale.jpg',
        link: 'https://example.com/fitness-smart-scale',
        brands: ['Brand C', 'Brand D'],
        colors: ['Black', 'White'],
        displayTypes: ['Digital'],
        dimensions: ['Standard'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/fitness-smart-scale',
        about: [
            'Body composition analysis',
            'App connectivity',
            'Accurate measurements'
        ]
    },
    {
        title: 'Portable Blender Smoothie',
        description: 'Compact blender designed for smoothies and shakes, with rechargeable battery and travel lid.',
        image: '/images/portable-blender-smoothie.jpg',
        link: 'https://example.com/portable-blender-smoothie',
        brands: ['Brand E', 'Brand F'],
        colors: ['Pink', 'Blue', 'Green'],
        displayTypes: [],
        dimensions: ['Compact'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/portable-blender-smoothie',
        about: [
            'Rechargeable battery',
            'Travel lid for on-the-go use',
            'Ideal for smoothies and shakes'
        ]
    },
    {
        title: 'Indoor Plant Grow Light',
        description: 'LED grow light for indoor plants with adjustable brightness and spectrum settings.',
        image: '/images/indoor-plant-grow-light.jpg',
        link: 'https://example.com/indoor-plant-grow-light',
        brands: ['Brand G', 'Brand H'],
        colors: ['White'],
        displayTypes: ['LED'],
        dimensions: ['Standard'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/indoor-plant-grow-light',
        about: [
            'Adjustable brightness and spectrum',
            'Promotes healthy plant growth',
            'Ideal for indoor gardening'
        ]
    },
    {
        title: 'Travel Coffee Maker',
        description: 'Compact coffee maker with travel mug, designed for brewing coffee on-the-go.',
        image: '/images/travel-coffee-maker.jpg',
        link: 'https://example.com/travel-coffee-maker',
        brands: ['Brand I', 'Brand J'],
        colors: ['Black', 'Stainless Steel'],
        displayTypes: [],
        dimensions: ['Compact'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/travel-coffee-maker',
        about: [
            'Brews coffee directly into travel mug',
            'Compact and portable design',
            'Easy to use and clean'
        ]
    },
    {
        title: 'Mini Projector HD',
        description: 'Portable HD projector with built-in speakers and easy connectivity options.',
        image: '/images/mini-projector-hd.jpg',
        link: 'https://example.com/mini-projector-hd',
        brands: ['Brand K', 'Brand L'],
        colors: ['Black', 'White'],
        displayTypes: ['HD'],
        dimensions: ['Compact'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/mini-projector-hd',
        about: [
            'HD projection quality',
            'Built-in speakers',
            'Easy connectivity with multiple devices'
        ]
    },
    {
        title: 'Electric Wine Opener',
        description: 'Automatic wine opener with rechargeable battery and foil cutter included.',
        image: '/images/electric-wine-opener.jpg',
        link: 'https://example.com/electric-wine-opener',
        brands: ['Brand M', 'Brand N'],
        colors: ['Silver', 'Black'],
        displayTypes: [],
        dimensions: ['Compact'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/electric-wine-opener',
        about: [
            'Automatic cork removal',
            'Rechargeable battery',
            'Includes foil cutter'
        ]
    },
    {
        title: 'LED Desk Lamp Adjustable',
        description: 'Adjustable LED desk lamp with touch control and multiple brightness settings.',
        image: '/images/led-desk-lamp-adjustable.jpg',
        link: 'https://example.com/led-desk-lamp-adjustable',
        brands: ['Brand O', 'Brand P'],
        colors: ['White', 'Black'],
        displayTypes: ['LED'],
        dimensions: ['Compact'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/led-desk-lamp-adjustable',
        about: [
            'Touch control brightness settings',
            'Adjustable lamp head',
            'Energy-efficient LED'
        ]
    },
    {
        title: 'Electric Kettle Boil',
        description: 'Electric kettle with quick boil technology and automatic shut-off feature.',
        image: '/images/electric-kettle-boil.jpg',
        link: 'https://example.com/electric-kettle-boil',
        brands: ['Brand Q', 'Brand R'],
        colors: ['Stainless Steel', 'Black'],
        displayTypes: [],
        dimensions: ['Standard'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/electric-kettle-boil',
        about: [
            'Quick boil technology',
            'Automatic shut-off feature',
            'Easy to clean'
        ]
    },
    {
        title: 'Bluetooth Earbuds Wireless',
        description: 'Wireless Bluetooth earbuds with noise-cancellation and long battery life.',
        image: '/images/bluetooth-earbuds-wireless.jpg',
        link: 'https://example.com/bluetooth-earbuds-wireless',
        brands: ['Brand S', 'Brand T'],
        colors: ['Black', 'White', 'Gray'],
        displayTypes: [],
        dimensions: ['Compact'],
        ages: ['All'],
        amazonLink: 'https://amazon.com/bluetooth-earbuds-wireless',
        about: [
            'Noise-cancellation feature',
            'Long battery life',
            'Comfortable fit'
        ]
    },
        {
            title: 'Home Security Camera',
            description: 'High-definition security camera with night vision and motion detection features.',
            image: '/images/home-security-camera.jpg',
            link: 'https://example.com/home-security-camera',
            brands: ['Brand K', 'Brand L'],
            colors: ['White', 'Black'],
            displayTypes: ['HD'],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/home-security-camera',
            about: [
                'Night vision',
                'Motion detection',
                'HD video quality'
            ]
        },
        {
            title: 'Air Fryer',
            description: 'High-performance air fryer with adjustable temperature and multiple cooking presets.',
            image: '/images/air-fryer.jpg',
            link: 'https://example.com/air-fryer',
            brands: ['Brand W', 'Brand X'],
            colors: ['Black', 'Silver'],
            displayTypes: ['Digital'],
            dimensions: ['Standard'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/air-fryer',
            about: [
                'Adjustable temperature',
                'Multiple cooking presets',
                'Healthy cooking'
            ]
        },
        {
            title: 'Fitness Tracker Smartwatch',
            description: 'Smartwatch with fitness tracking, heart rate monitoring, and GPS features.',
            image: '/images/fitness-tracker-smartwatch.jpg',
            link: 'https://example.com/fitness-tracker-smartwatch',
            brands: ['Brand Y', 'Brand Z'],
            colors: ['Black', 'Blue', 'Red'],
            displayTypes: ['LCD'],
            dimensions: ['Standard'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/fitness-tracker-smartwatch',
            about: [
                'Heart rate monitoring',
                'GPS tracking',
                'Fitness tracking features'
            ]
        },
        {
            title: 'Cordless Vacuum Cleaner',
            description: 'Lightweight cordless vacuum cleaner with powerful suction and extended battery life.',
            image: '/images/cordless-vacuum-cleaner.jpg',
            link: 'https://example.com/cordless-vacuum-cleaner',
            brands: ['Brand A', 'Brand B'],
            colors: ['Black', 'Gray'],
            displayTypes: [],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/cordless-vacuum-cleaner',
            about: [
                'Powerful suction',
                'Extended battery life',
                'Lightweight design'
            ]
        },
        {
            title: 'Robot Vacuum Cleaner',
            description: 'Automated robot vacuum cleaner with smart navigation and scheduling features.',
            image: '/images/robot-vacuum-cleaner.jpg',
            link: 'https://example.com/robot-vacuum-cleaner',
            brands: ['Brand C', 'Brand D'],
            colors: ['Black', 'White'],
            displayTypes: ['Digital'],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/robot-vacuum-cleaner',
            about: [
                'Smart navigation',
                'Scheduling features',
                'Automated cleaning'
            ]
        },
        {
            title: 'Smart Doorbell',
            description: 'Video doorbell with two-way audio and motion detection for home security.',
            image: '/images/smart-doorbell.jpg',
            link: 'https://example.com/smart-doorbell',
            brands: ['Brand E', 'Brand F'],
            colors: ['Black', 'Silver'],
            displayTypes: ['HD'],
            dimensions: ['Standard'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/smart-doorbell',
            about: [
                'Two-way audio',
                'Motion detection',
                'Video recording'
            ]
        },
        {
            title: 'Smart Thermostat',
            description: 'Programmable smart thermostat with energy-saving features and remote control.',
            image: '/images/smart-thermostat.jpg',
            link: 'https://example.com/smart-thermostat',
            brands: ['Brand G', 'Brand H'],
            colors: ['White', 'Black'],
            displayTypes: ['Digital'],
            dimensions: ['Standard'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/smart-thermostat',
            about: [
                'Energy-saving features',
                'Remote control',
                'Programmable settings'
            ]
        },
        {
            title: 'Bluetooth Speaker',
            description: 'Portable Bluetooth speaker with high-quality sound and waterproof design.',
            image: '/images/bluetooth-speaker.jpg',
            link: 'https://example.com/bluetooth-speaker',
            brands: ['Brand I', 'Brand J'],
            colors: ['Black', 'Blue', 'Red'],
            displayTypes: [],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/bluetooth-speaker',
            about: [
                'High-quality sound',
                'Waterproof design',
                'Portable and durable'
            ]
        },
        {
            title: 'Electric Pressure Cooker',
            description: 'Multi-functional electric pressure cooker with various cooking modes and easy-to-use controls.',
            image: '/images/electric-pressure-cooker.jpg',
            link: 'https://example.com/electric-pressure-cooker',
            brands: ['Brand K', 'Brand L'],
            colors: ['Silver', 'Black'],
            displayTypes: ['Digital'],
            dimensions: ['Standard'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/electric-pressure-cooker',
            about: [
                'Various cooking modes',
                'Easy-to-use controls',
                'Time-saving technology'
            ]
        },
        {
            title: 'Portable Air Purifier',
            description: 'Compact air purifier with HEPA filter and multiple fan speeds for clean air.',
            image: '/images/portable-air-purifier.jpg',
            link: 'https://example.com/portable-air-purifier',
            brands: ['Brand M', 'Brand N'],
            colors: ['White', 'Black'],
            displayTypes: ['Digital'],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/portable-air-purifier',
            about: [
                'HEPA filter',
                'Multiple fan speeds',
                'Compact design'
            ]
        },
        {
            title: 'Digital Meat Thermometer',
            description: 'Instant-read digital meat thermometer with backlight and quick temperature readings.',
            image: '/images/digital-meat-thermometer.jpg',
            link: 'https://example.com/digital-meat-thermometer',
            brands: ['Brand O', 'Brand P'],
            colors: ['Black', 'Silver'],
            displayTypes: ['Digital'],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/digital-meat-thermometer',
            about: [
                'Instant-read feature',
                'Backlight display',
                'Quick temperature readings'
            ]
        },
        {
            title: 'Wireless Earbuds Noise-Canceling',
            description: 'Wireless earbuds with noise-canceling technology and comfortable fit.',
            image: '/images/wireless-earbuds-noise-canceling.jpg',
            link: 'https://example.com/wireless-earbuds-noise-canceling',
            brands: ['Brand Q', 'Brand R'],
            colors: ['Black', 'White', 'Gray'],
            displayTypes: [],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/wireless-earbuds-noise-canceling',
            about: [
                'Noise-canceling technology',
                'Comfortable fit',
                'Long battery life'
            ]
        },
        {
            title: 'Smart Plug',
            description: 'Wi-Fi smart plug with voice control and scheduling capabilities.',
            image: '/images/smart-plug.jpg',
            link: 'https://example.com/smart-plug',
            brands: ['Brand S', 'Brand T'],
            colors: ['White'],
            displayTypes: [],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/smart-plug',
            about: [
                'Voice control compatibility',
                'Scheduling capabilities',
                'Easy setup'
            ]
        },
        {
            title: 'Electric Toothbrush with UV Sanitizer',
            description: 'Electric toothbrush with built-in UV sanitizer for maintaining brush hygiene.',
            image: '/images/electric-toothbrush-uv-sanitizer.jpg',
            link: 'https://example.com/electric-toothbrush-uv-sanitizer',
            brands: ['Brand U', 'Brand V'],
            colors: ['White', 'Black'],
            displayTypes: [],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/electric-toothbrush-uv-sanitizer',
            about: [
                'Built-in UV sanitizer',
                'Multiple brushing modes',
                'Rechargeable battery'
            ]
        },
        {
            title: 'Electric Griddle',
            description: 'Large electric griddle with non-stick surface and adjustable temperature control.',
            image: '/images/electric-griddle.jpg',
            link: 'https://example.com/electric-griddle',
            brands: ['Brand W', 'Brand X'],
            colors: ['Black', 'Silver'],
            displayTypes: ['Digital'],
            dimensions: ['Large'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/electric-griddle',
            about: [
                'Non-stick surface',
                'Adjustable temperature control',
                'Large cooking area'
            ]
        },
        {
            title: 'Mini Projector',
            description: 'Portable mini projector with high-resolution display and built-in speaker.',
            image: '/images/mini-projector.jpg',
            link: 'https://example.com/mini-projector',
            brands: ['Brand Y', 'Brand Z'],
            colors: ['Black', 'White'],
            displayTypes: ['HD'],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/mini-projector',
            about: [
                'High-resolution display',
                'Built-in speaker',
                'Portable design'
            ]
        },
        {
            title: 'Electric Blanket',
            description: 'Soft electric blanket with adjustable heat settings and automatic shut-off.',
            image: '/images/electric-blanket.jpg',
            link: 'https://example.com/electric-blanket',
            brands: ['Brand A', 'Brand B'],
            colors: ['Gray', 'Beige'],
            displayTypes: ['Digital'],
            dimensions: ['Standard'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/electric-blanket',
            about: [
                'Adjustable heat settings',
                'Automatic shut-off',
                'Soft and cozy'
            ]
        },
        {
            title: 'Portable Blender',
            description: 'Rechargeable portable blender with USB charging and powerful blending capability.',
            image: '/images/portable-blender.jpg',
            link: 'https://example.com/portable-blender',
            brands: ['Brand C', 'Brand D'],
            colors: ['Green', 'Purple'],
            displayTypes: [],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/portable-blender',
            about: [
                'USB rechargeable',
                'Powerful blending',
                'Compact design'
            ]
        },
        {
            title: 'Home Coffee Maker',
            description: 'Programmable coffee maker with built-in grinder and multiple brew settings.',
            image: '/images/home-coffee-maker.jpg',
            link: 'https://example.com/home-coffee-maker',
            brands: ['Brand E', 'Brand F'],
            colors: ['Black', 'Stainless Steel'],
            displayTypes: ['Digital'],
            dimensions: ['Standard'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/home-coffee-maker',
            about: [
                'Built-in grinder',
                'Multiple brew settings',
                'Programmable timer'
            ]
        },
        {
            title: 'USB Desk Fan',
            description: 'Compact USB desk fan with adjustable speed settings and quiet operation.',
            image: '/images/usb-desk-fan.jpg',
            link: 'https://example.com/usb-desk-fan',
            brands: ['Brand G', 'Brand H'],
            colors: ['Black', 'White'],
            displayTypes: [],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/usb-desk-fan',
            about: [
                'Adjustable speed settings',
                'Quiet operation',
                'USB powered'
            ]
        },
        {
            title: 'Kitchen Scale',
            description: 'Digital kitchen scale with precise measurements and easy-to-read display.',
            image: '/images/kitchen-scale.jpg',
            link: 'https://example.com/kitchen-scale',
            brands: ['Brand I', 'Brand J'],
            colors: ['Black', 'Silver'],
            displayTypes: ['Digital'],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/kitchen-scale',
            about: [
                'Precise measurements',
                'Easy-to-read display',
                'Compact design'
            ]
        },
        {
            title: 'Digital Camera',
            description: 'High-resolution digital camera with optical zoom and various shooting modes.',
            image: '/images/digital-camera.jpg',
            link: 'https://example.com/digital-camera',
            brands: ['Brand K', 'Brand L'],
            colors: ['Black', 'Silver'],
            displayTypes: ['LCD'],
            dimensions: ['Standard'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/digital-camera',
            about: [
                'High-resolution images',
                'Optical zoom',
                'Various shooting modes'
            ]
        },
        {
            title: 'Electric Screwdriver',
            description: 'Cordless electric screwdriver with adjustable torque settings and rechargeable battery.',
            image: '/images/electric-screwdriver.jpg',
            link: 'https://example.com/electric-screwdriver',
            brands: ['Brand M', 'Brand N'],
            colors: ['Black', 'Red'],
            displayTypes: [],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/electric-screwdriver',
            about: [
                'Adjustable torque settings',
                'Rechargeable battery',
                'Cordless convenience'
            ]
        },
        {
            title: 'Electric Shaver',
            description: 'Men’s electric shaver with multi-directional heads and precision trimmer.',
            image: '/images/electric-shaver.jpg',
            link: 'https://example.com/electric-shaver',
            brands: ['Brand O', 'Brand P'],
            colors: ['Black', 'Silver'],
            displayTypes: [],
            dimensions: ['Compact'],
            ages: ['Adult'],
            amazonLink: 'https://amazon.com/electric-shaver',
            about: [
                'Multi-directional heads',
                'Precision trimmer',
                'Rechargeable battery'
            ]
        },
        {
            title: 'Streaming Media Player',
            description: 'Streaming media player with 4K resolution and access to popular streaming services.',
            image: '/images/streaming-media-player.jpg',
            link: 'https://example.com/streaming-media-player',
            brands: ['Brand Q', 'Brand R'],
            colors: ['Black', 'Gray'],
            displayTypes: ['4K'],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/streaming-media-player',
            about: [
                '4K resolution',
                'Access to popular streaming services',
                'Compact design'
            ]
        },
        {
            title: 'Waterproof Bluetooth Speaker',
            description: 'Rugged waterproof Bluetooth speaker with excellent sound quality and long battery life.',
            image: '/images/waterproof-bluetooth-speaker.jpg',
            link: 'https://example.com/waterproof-bluetooth-speaker',
            brands: ['Brand S', 'Brand T'],
            colors: ['Black', 'Blue'],
            displayTypes: [],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/waterproof-bluetooth-speaker',
            about: [
                'Waterproof design',
                'Excellent sound quality',
                'Long battery life'
            ]
        },
        {
            title: 'Portable Power Bank',
            description: 'High-capacity portable power bank with fast charging capabilities and multiple ports.',
            image: '/images/portable-power-bank.jpg',
            link: 'https://example.com/portable-power-bank',
            brands: ['Brand U', 'Brand V'],
            colors: ['Black', 'Silver'],
            displayTypes: [],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/portable-power-bank',
            about: [
                'High-capacity battery',
                'Fast charging capabilities',
                'Multiple ports'
            ]
        },
        {
            title: 'Cordless Electric Lawn Mower',
            description: 'Battery-powered cordless lawn mower with adjustable cutting height and quiet operation.',
            image: '/images/cordless-electric-lawn-mower.jpg',
            link: 'https://example.com/cordless-electric-lawn-mower',
            brands: ['Brand W', 'Brand X'],
            colors: ['Green', 'Black'],
            displayTypes: [],
            dimensions: ['Standard'],
            ages: ['Adult'],
            amazonLink: 'https://amazon.com/cordless-electric-lawn-mower',
            about: [
                'Adjustable cutting height',
                'Battery-powered',
                'Quiet operation'
            ]
        },
        {
            title: 'Digital Air Fryer',
            description: 'Advanced digital air fryer with rapid air technology and multiple cooking presets.',
            image: '/images/digital-air-fryer.jpg',
            link: 'https://example.com/digital-air-fryer',
            brands: ['Brand Y', 'Brand Z'],
            colors: ['Black', 'Gray'],
            displayTypes: ['Digital'],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/digital-air-fryer',
            about: [
                'Rapid air technology',
                'Multiple cooking presets',
                'Digital controls'
            ]
        },
        {
            title: 'Smart Wi-Fi Light Bulbs',
            description: 'Color-changing smart Wi-Fi light bulbs with voice control and scheduling features.',
            image: '/images/smart-wifi-light-bulbs.jpg',
            link: 'https://example.com/smart-wifi-light-bulbs',
            brands: ['Brand A', 'Brand B'],
            colors: ['White', 'Multicolor'],
            displayTypes: [],
            dimensions: ['Standard'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/smart-wifi-light-bulbs',
            about: [
                'Color-changing',
                'Voice control',
                'Scheduling features'
            ]
        },
        {
            title: 'Electric Scooter',
            description: 'Foldable electric scooter with long-range battery and adjustable handlebars.',
            image: '/images/electric-scooter.jpg',
            link: 'https://example.com/electric-scooter',
            brands: ['Brand C', 'Brand D'],
            colors: ['Black', 'Red'],
            displayTypes: [],
            dimensions: ['Compact'],
            ages: ['Adult'],
            amazonLink: 'https://amazon.com/electric-scooter',
            about: [
                'Foldable design',
                'Long-range battery',
                'Adjustable handlebars'
            ]
        },
        {
            title: 'Wireless Earbuds',
            description: 'True wireless earbuds with noise-cancelling technology and charging case.',
            image: '/images/wireless-earbuds.jpg',
            link: 'https://example.com/wireless-earbuds',
            brands: ['Brand E', 'Brand F'],
            colors: ['Black', 'White'],
            displayTypes: [],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/wireless-earbuds',
            about: [
                'Noise-cancelling technology',
                'Charging case',
                'True wireless'
            ]
        },
        {
            title: 'Portable Air Conditioner',
            description: 'Compact portable air conditioner with adjustable settings and remote control.',
            image: '/images/portable-air-conditioner.jpg',
            link: 'https://example.com/portable-air-conditioner',
            brands: ['Brand G', 'Brand H'],
            colors: ['White', 'Black'],
            displayTypes: ['Digital'],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/portable-air-conditioner',
            about: [
                'Adjustable settings',
                'Remote control',
                'Compact design'
            ]
        },
        {
            title: 'Digital Weather Station',
            description: 'Advanced digital weather station with indoor and outdoor temperature monitoring.',
            image: '/images/digital-weather-station.jpg',
            link: 'https://example.com/digital-weather-station',
            brands: ['Brand I', 'Brand J'],
            colors: ['Black', 'White'],
            displayTypes: ['Digital'],
            dimensions: ['Compact'],
            ages: ['All'],
            amazonLink: 'https://amazon.com/digital-weather-station',
            about: [
                'Indoor and outdoor temperature monitoring',
                'Digital display',
                'Weather forecasts'
            ]
        },

        
    ];

    return (
        <div className="product-list">
            {products.map((product, index) => (
                <ProductDetails key={index} product={product} />
            ))}
       
        </div>
    );
};

export default ProductList;
