import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const BubbleChart = () => {
  const chartRef = useRef();

  useEffect(() => {
    const margin = { top: 20, right: 20, bottom: 30, left: 40 },
          width = 600 - margin.left - margin.right,
          height = 500 - margin.top - margin.bottom;

    const svg = d3.select(chartRef.current)
      .html("") // Clear previous content
      .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
      .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

    d3.csv("/bubble_chart_data.csv").then(data => {
      data.forEach(d => {
        d.Year = d.Year;
        d.CO2 = +d.CO2;
        d.Temperature = +d.Temperature;
        d.Population = +d.Population;
      });

      const x = d3.scaleLinear()
        .domain(d3.extent(data, d => d.CO2))
        .range([0, width]);

      const y = d3.scaleLinear()
        .domain(d3.extent(data, d => d.Temperature))
        .range([height, 0]);

      const z = d3.scaleSqrt()
        .domain([0, d3.max(data, d => d.Population)])
        .range([0, 40]);

      svg.append("g")
        .attr("class", "x-axis")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(x));

      svg.append("g")
        .attr("class", "y-axis")
        .call(d3.axisLeft(y));

      svg.selectAll(".bubble")
        .data(data)
        .enter().append("circle")
        .attr("class", "bubble")
        .attr("cx", d => x(d.CO2))
        .attr("cy", d => y(d.Temperature))
        .attr("r", d => z(d.Population))
        .style("fill", "#69b3a2")
        .style("opacity", 0.6)
        .on("mouseover", function(event, d) {
          d3.select(this).style("fill", "#a4c8a6");
          d3.select(".tooltip")
            .style("opacity", 1)
            .html(`Year: ${d.Year}<br/>CO2: ${d.CO2}<br/>Temp: ${d.Temperature}<br/>Population: ${d.Population}`)
            .style("left", `${event.pageX + 10}px`)
            .style("top", `${event.pageY - 28}px`);
        })
        .on("mouseout", function() {
          d3.select(this).style("fill", "#69b3a2");
          d3.select(".tooltip").style("opacity", 0);
        });

      d3.select("body").append("div")
        .attr("class", "tooltip")
        .style("position", "absolute")
        .style("background-color", "white")
        .style("border", "1px solid #ccc")
        .style("border-radius", "4px")
        .style("padding", "5px")
        .style("opacity", 0);
    });
  }, []);

  return (
    <div className='container'>
      <h2>Bubble Chart: CO2 vs Temperature</h2>
      <div ref={chartRef}></div>
    </div>
  );
};

export default BubbleChart;
