// src/SentimentAnalysis.js
import React, { useState } from 'react';
import * as tf from '@tensorflow/tfjs';
import * as use from '@tensorflow-models/universal-sentence-encoder';

const SentimentAnalysis = () => {
  const [text, setText] = useState('');
  const [sentiment, setSentiment] = useState('');

  const handleInputChange = event => {
    setText(event.target.value);
  };

  const analyzeSentiment = async () => {
    try {
      // Load the Universal Sentence Encoder model
      const model = await use.load();
      // Embed the input text
      const embeddings = await model.embed([text]);
      // Load the sentiment analysis model
      const sentimentModel = await tf.loadLayersModel('https://storage.googleapis.com/tfjs-models/tfjs/sentiment_cnn_v1/model.json');
      // Predict the sentiment
      const sentimentScore = sentimentModel.predict(embeddings).dataSync();
      // Set the sentiment result
      setSentiment(sentimentScore[0] > 0.5 ? 'Positive' : 'Negative');
    } catch (error) {
      console.error('Error analyzing sentiment:', error);
    } finally {
      // Dispose of TensorFlow.js tensors to free up memory
      tf.disposeVariables();
    }
  };

  return (
    <div>
    <div className='intro2'>
      <div className='port-1'>
        <h1>TensorFlow.js Sentiment Analysis</h1>
        <p>
          This example demonstrates how to perform sentiment analysis using TensorFlow.js. The Universal Sentence Encoder is used to embed the input text, and a pre-trained Convolutional Neural Network (CNN) model is used to predict the sentiment.
        </p>

      <h2>Sentiment Analysis</h2>
      <textarea value={text} onChange={handleInputChange} rows={5} cols={35} />
      <button onClick={analyzeSentiment}>Analyze</button>
      <div>
        {sentiment && (
          <div>
            <h3>Sentiment: {sentiment}</h3>
          </div>
        )}
      </div>
      </div>
    </div>
      
      <br />
    
    </div>
  );
};

export default SentimentAnalysis;
