import React from 'react';
import Button from '@mui/material/Button';
const Resume = () => {
  return (
    <div className=''>
      <h1>Certificate of Completion</h1>
      
<br />

      {/* <Button variant="contained" href="/React_Tutorial" target="_blank">
      <a href="/resume.pdf" download>Download Resume📚</a>
</Button> */}
{/* <br /> */}
      <div className="resume-container">
      <embed src="/resume.pdf" type="application/pdf" width="800px" height="1000px" />
     
      </div>
      <div className="resume-container">
      <embed src="/resume3.pdf" type="application/pdf" width="800px" height="1000px" />
     
      </div>
     
    </div>
  );
};

export default Resume;