import React, { useState } from 'react';
import * as d3 from 'd3';
// import './DSA.css';

const Binary = () => {
  const [nodes, setNodes] = useState([
    { value: 50, left: 30, right: 70 },
    { value: 30, left: 10, right: 40 },
    { value: 70, left: 60, right: 80 },
    { value: 10, left: null, right: null },
    { value: 40, left: null, right: null },
    { value: 60, left: null, right: null },
    { value: 80, left: null, right: null }
  ]);
  const [showExplanation, setShowExplanation] = useState(false);

  const drawTree = () => {
    const width = 600, height = 400;
    const svg = d3.select('#tree-svg');
    svg.html('');

    const tree = d3.tree().size([width, height - 100]);
    const root = d3.hierarchy({
      value: 50,
      children: [
        {
          value: 30,
          children: [
            { value: 10 },
            { value: 40 }
          ]
        },
        {
          value: 70,
          children: [
            { value: 60 },
            { value: 80 }
          ]
        }
      ]
    });
    
    tree(root);

    const links = svg.selectAll('.link')
      .data(root.links())
      .enter().append('line')
      .attr('class', 'link')
      .attr('x1', d => d.source.x)
      .attr('y1', d => d.source.y)
      .attr('x2', d => d.target.x)
      .attr('y2', d => d.target.y)
      .attr('stroke', '#69b3a2');

    const nodesGroup = svg.selectAll('.node')
      .data(root.descendants())
      .enter().append('g')
      .attr('class', 'node')
      .attr('transform', d => `translate(${d.x},${d.y})`);

    nodesGroup.append('circle')
      .attr('r', 20)
      .attr('fill', '#69b3a2');

    nodesGroup.append('text')
      .attr('dy', 5)
      .attr('text-anchor', 'middle')
      .text(d => d.data.value);
  };

  return (
    <div>
      <h2>Binary Tree Visualization</h2>
      <button onClick={drawTree}>Draw Binary Tree</button>
      <svg id="tree-svg" width="600" height="400"></svg>
      <button onClick={() => setShowExplanation(!showExplanation)}>
        {showExplanation ? 'Hide Explanation' : 'Show Explanation'}
      </button>
      {showExplanation && (
        <div>
          <h3>Binary Tree Explanation</h3>
          <p>Binary trees are hierarchical structures where each node has at most two children. This visualization shows a binary tree with nodes connected by links. Each node has a value, and the tree is arranged to illustrate parent-child relationships.</p>
          <pre>
            {`const drawTree = () => {
              const width = 600, height = 400;
              const svg = d3.select('#tree-svg');
              svg.html('');

              const tree = d3.tree().size([width, height - 100]);
              const root = d3.hierarchy({
                value: 50,
                children: [
                  {
                    value: 30,
                    children: [
                      { value: 10 },
                      { value: 40 }
                    ]
                  },
                  {
                    value: 70,
                    children: [
                      { value: 60 },
                      { value: 80 }
                    ]
                  }
                ]
              });
              
              tree(root);

              const links = svg.selectAll('.link')
                .data(root.links())
                .enter().append('line')
                .attr('class', 'link')
                .attr('x1', d => d.source.x)
                .attr('y1', d => d.source.y)
                .attr('x2', d => d.target.x)
                .attr('y2', d => d.target.y)
                .attr('stroke', '#69b3a2');

              const nodesGroup = svg.selectAll('.node')
                .data(root.descendants())
                .enter().append('g')
                .attr('class', 'node')
                .attr('transform', d => \`translate(\${d.x},\${d.y})\`);

              nodesGroup.append('circle')
                .attr('r', 20)
                .attr('fill', '#69b3a2');

              nodesGroup.append('text')
                .attr('dy', 5)
                .attr('text-anchor', 'middle')
                .text(d => d.data.value);
            };`}
          </pre>
        </div>
      )}
    </div>
  );
};

export default Binary;
