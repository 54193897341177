import React from 'react';
import ai from "../assets/ai.webp";
import rise2 from "../assets/rise2.png";
import rise5 from "../assets/rise5.webp";
import Button from '@mui/material/Button';
import './Blog.css'; // Import CSS file for styling

const Blog = () => {
  return (
    <div className="container">
     <h1 className="heading">OlaFolio BlogPost</h1>
    {/* <iframe src="https://podcasters.spotify.com/pod/show/olayinka-fakanbi/embed" height="102px" width="400px" frameborder="0" scrolling="no"></iframe> */}
     
      <form className="form">
      <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7222828569847078912" height="753" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
      <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7134080959120855041" height="645" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
      <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7131010830946463744" height="655" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
      <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7118235695336304640" height="817" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
      <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7213812281887862784" height="917" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
      <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7213816962550743040" height="817" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
      {/* <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7209140033142824961" height="1288" width="100" frameborder="0" allowfullscreen="" title="Embedded post"></iframe> */}
      {/* <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7102598545219670017" height="1813" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe> */}
      {/* <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7101215382891286528" height="1606" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe> */}
      {/* <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7098425546186874880" height="767" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe> */}
      <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7095066374154145792" height="842" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
      {/* <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7094593561001648128" height="288" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe> */}
      {/* <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7094592572018302976" height="600" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe> */}
      {/* <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7094592243671425026" height="760" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe> */}
      {/* <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7089260154507915265" height="1544" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe> */}

      {/* <img loading="lazy" src={ai} className="pfp1 " alt="logo" />
        <h2 className="paragraph">
          Software engineers have long been at the forefront of technological advancements, creating innovative solutions to complex problems. With the rise of artificial intelligence (AI), the role of software engineers is set to evolve even further in the future.
        </h2>
    
        <h2 className="paragraph">
          AI is already transforming various industries, from healthcare to finance, and it is expected to continue to revolutionize the way we live and work. As AI technology becomes more sophisticated, software engineers will play a critical role in developing and implementing AI systems.
        </h2>
        <img loading="lazy" src={rise2} className="pfp1 " alt="logo" />
        <h2 className="paragraph">
          One of the key areas where software engineers will be instrumental in the future is in the development of AI algorithms. These algorithms are the backbone of AI systems, allowing them to learn from data and make decisions on their own. Software engineers will need to have a deep understanding of machine learning and data science to create and optimize these algorithms.
        </h2>
        <h2 className="paragraph">
          Additionally, software engineers will also be responsible for integrating AI systems into existing software applications. This will require them to have a strong understanding of software architecture and design, as well as the ability to work collaboratively with other team members.
        </h2> */}
        {/* <img src={rise5} className="pfp1 animate__backInDown" alt="logo" /> */}
        {/* <h2 className="paragraph">
          In the future, software engineers may also need to develop new skills to keep pace with advances in AI technology. This could include learning how to work with new programming languages and tools, as well as gaining expertise in areas such as natural language processing and computer vision.
        </h2>
        <h2 className="paragraph">
          Overall, the future of software engineers and AI is an exciting one. As AI continues to expand its reach, software engineers will have the opportunity to leverage their skills and expertise to create innovative solutions that have the potential to transform industries and improve the way we live and work. By staying current with the latest trends and advancements in AI, software engineers will be well-positioned to thrive in this rapidly evolving field.
        </h2> */}
        <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7102598545219670017" height="1813" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
        <Button variant="contained" href="/" target="_blank">
 Back
</Button>
      </form>
    </div>
  );
};

export default Blog;
