// import React from 'react'
// import React, { useState } from 'react';
// import './Contact.css';

// function Contact() {
//     const [name, setName] = useState('');
//     const [email, setEmail] = useState('');
//     const [message, setMessage] = useState('');

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         handleSubmit()
//         // TODO: Handle form submission logic here
//     };
//     /* Contact.css */

   
//     return (
//         <div>
//             <h2>Contact Form</h2>
//             <form action="https://formspree.io/f/xrgnooap"
//   method="POST" onSubmit={handleSubmit}>
//                 <label htmlFor="name"><h2>Name:</h2></label>
//                 <input
//                     type="text"
//                     id="name"
//                     value={name}
//                     onChange={(e) => setName(e.target.value)}
//                 />

//                 <label htmlFor="email"><h2>Email:</h2></label>
//                 <input
//                     type="email"
//                     id="email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                 />

//                 <label htmlFor="message"><h2>Message:</h2></label>
//                 <textarea
//                     id="message"
//                     value={message}
//                     onChange={(e) => setMessage(e.target.value)}
//                 ></textarea>

//                 <button type="submit">Submit</button>
//             </form>
//         </div>
//     );
// }

// export default Contact;

// =============================================================== ?

// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help
// import React from 'react';
import React, { useState } from 'react';
import './Contact.css';
import { useForm, ValidationError } from '@formspree/react';
import Button from '@mui/material/Button';
function Contact() {
    const [name, setName] = useState(''); 

  const [state, handleSubmit] = useForm("xrgnooap");
  if (state.succeeded) {
      return <h2>Got your message! Excited to chat soon! 😊📩</h2>;
  }
  return (
      <form onSubmit={handleSubmit}>
      <label htmlFor="name"><h2>Name:</h2></label>
                <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
      <label htmlFor="email">
       <h2> Email Address: </h2> 
      </label>
      <input
        id="email"
        type="email" 
        name="email"
      />
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
       <label htmlFor="message"><h2>Message:</h2></label>
      <textarea
        id="message"
        name="message"
      />
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
      <button type="submit" disabled={state.submitting}>
        Submit
      </button>
    </form>
  );
}

export default Contact;