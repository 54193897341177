import React from 'react'

function Mic2Ex() {
  return (
    <div className="intro2"> 
    <h1>BlogPost About --- </h1>
        <div className='port-2'>

        </div>
        <form>
            
        </form>
    </div>
  )
}

export default Mic2Ex