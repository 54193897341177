// import React, { useEffect, useRef, useState } from 'react';
// import * as d3 from 'd3';
// import './D3.css'

// const D3Chart = () => {
//   const chartRef = useRef();
//   const [metric, setMetric] = useState('CO2'); // Default metric

//   useEffect(() => {
//     const margin = { top: 50, right: 30, bottom: 50, left: 60 };
//     let width = 700 - margin.left - margin.right;
//     let height = 550 - margin.top - margin.bottom;
//     let lineStrokeWidth = 1.5;
//     let circleRadius = 5;

//     // Adjust dimensions for smaller screens
//     if (window.innerWidth <= 768) {
//       width = 500 - margin.left - margin.right;
//       height = 300 - margin.top - margin.bottom;
//       lineStrokeWidth = 1;
//       circleRadius = 3;
//     } else if (window.innerWidth <= 480) {
//       width = 320 - margin.left - margin.right;
//       height = 200 - margin.top - margin.bottom;
//       lineStrokeWidth = 0.8;
//       circleRadius = 2;
//     }

//     const svg = d3.select(chartRef.current)
//       .html("") // Clear previous content
//       .append("svg")
//         .attr("width", width + margin.left + margin.right)
//         .attr("height", height + margin.top + margin.bottom)
//       .append("g")
//         .attr("transform", `translate(${margin.left},${margin.top})`);

//     d3.csv("/environmental_metrics.csv").then(data => {
//       const parseDate = d3.timeParse("%Y");

//       data.forEach(d => {
//         d.Year = parseDate(d.Year);
//         d.CO2 = parseFloat(d.CO2) || 0;
//         d.Temperature = parseFloat(d.Temperature) || 0;
//       });

//       const xScale = d3.scaleTime()
//         .domain(d3.extent(data, d => d.Year))
//         .range([0, width]);

//       const yScale = d3.scaleLinear()
//         .domain([0, d3.max(data, d => d[metric])])
//         .range([height, 0]);

//       svg.append("g")
//         .attr("transform", `translate(0, ${height})`)
//         .call(d3.axisBottom(xScale));

//       svg.append("g")
//         .call(d3.axisLeft(yScale));

//       const linePath = svg.append("path")
//         .datum(data)
//         .attr("fill", "none")
//         .attr("stroke", "#69b3a2")
//         .attr("stroke-width", lineStrokeWidth)
//         .attr("d", d3.line()
//           .x(d => xScale(d.Year))
//           .y(d => yScale(d[metric]))
//         );

//       const tooltip = d3.select(chartRef.current)
//         .append("div")
//         .style("opacity", 0)
//         .attr("class", "tooltip")
//         .style("background-color", "white")
//         .style("border", "solid")
//         .style("border-width", "2px")
//         .style("border-radius", "5px")
//         .style("padding", "5px");

//       svg.selectAll("circle")
//         .data(data)
//         .enter()
//         .append("circle")
//           .attr("cx", d => xScale(d.Year))
//           .attr("cy", d => yScale(d[metric]))
//           .attr("r", circleRadius)
//           .attr("fill", "#69b3a2")
//           .on("mouseover", (event, d) => {
//             tooltip
//               .transition()
//               .duration(200)
//               .style("opacity", 1);
//             tooltip
//               .html(`Year: ${d3.timeFormat("%Y")(d.Year)}<br/>${metric}: ${d[metric]}`)
//               .style("left", `${event.pageX + 10}px`)
//               .style("top", `${event.pageY - 28}px`);
//           })
//           .on("mouseout", () => {
//             tooltip
//               .transition()
//               .duration(500)
//               .style("opacity", 0);
//           });

//       svg.call(d3.zoom().on("zoom", (event) => {
//         const newXScale = event.transform.rescaleX(xScale);
//         const newYScale = event.transform.rescaleY(yScale);
//         svg.select("g.x.axis").call(d3.axisBottom(newXScale));
//         svg.select("g.y.axis").call(d3.axisLeft(newYScale));
//         linePath.attr("d", d3.line()
//           .x(d => newXScale(d.Year))
//           .y(d => newYScale(d[metric]))
//         );
//         svg.selectAll("circle")
//           .attr("cx", d => newXScale(d.Year))
//           .attr("cy", d => newYScale(d[metric]));
//       }));
//     });

//   }, [metric]);

//   return (
//     <div className='d3-cont'>
//       <h2>Environmental Metrics Over Time</h2>
//       <div>
//         <label>Select Metric: </label>
//         <select onChange={e => setMetric(e.target.value)} value={metric}>
//           <option value="Temperature">Temperature Anomalies</option>
//           <option value="CO2">CO2 Emissions</option>
//           {/* Add more options as needed */}
//         </select>
//       </div>
//       <div ref={chartRef}></div>
//     </div>
//   );
// };

// export default D3Chart;







import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import './D3.css'
import './Bubble.css';

const D3Chart = () => {
  const chartRef = useRef();
  const [metric, setMetric] = useState('CO2'); // Default metric
  const [showDetails, setShowDetails] = useState(false); // Show More toggle

  useEffect(() => {
    const margin = { top: 40, right: 30, bottom: 30, left: 40 };
    let width = 350 - margin.left - margin.right;
    let height = 450 - margin.top - margin.bottom;
    let lineStrokeWidth = 1.1;
    let circleRadius = 5;

    // Adjust dimensions for smaller screens
    // if (window.innerWidth <= 768) {
    //   width = 500 - margin.left - margin.right;
    //   height = 300 - margin.top - margin.bottom;
    //   lineStrokeWidth = 1;
    //   circleRadius = 3;
    // } else if (window.innerWidth <= 480) {
    //   width = 320 - margin.left - margin.right;
    //   height = 200 - margin.top - margin.bottom;
    //   lineStrokeWidth = 0.8;
    //   circleRadius = 2;
    // }

    const svg = d3.select(chartRef.current)
      .html("") // Clear previous content
      .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
      .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

    d3.csv("/environmental_metrics.csv").then(data => {
      const parseDate = d3.timeParse("%Y");

      data.forEach(d => {
        d.Year = parseDate(d.Year);
        d.CO2 = parseFloat(d.CO2) || 0;
        d.Temperature = parseFloat(d.Temperature) || 0;
      });

      const xScale = d3.scaleTime()
        .domain(d3.extent(data, d => d.Year))
        .range([0, width]);

      const yScale = d3.scaleLinear()
        .domain([0, d3.max(data, d => d[metric])])
        .range([height, 0]);

      svg.append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(xScale));

      svg.append("g")
        .call(d3.axisLeft(yScale));

      const linePath = svg.append("path")
        .datum(data)
        .attr("fill", "none")
        .attr("stroke", "#69b3a2")
        .attr("stroke-width", lineStrokeWidth)
        .attr("d", d3.line()
          .x(d => xScale(d.Year))
          .y(d => yScale(d[metric]))
        );

      const tooltip = d3.select(chartRef.current)
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip")
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("padding", "5px");

      svg.selectAll("circle")
        .data(data)
        .enter()
        .append("circle")
          .attr("cx", d => xScale(d.Year))
          .attr("cy", d => yScale(d[metric]))
          .attr("r", circleRadius)
          .attr("fill", "#69b3a2")
          .on("mouseover", (event, d) => {
            tooltip
              .transition()
              .duration(200)
              .style("opacity", 1);
            tooltip
              .html(`Year: ${d3.timeFormat("%Y")(d.Year)}<br/>${metric}: ${d[metric]}`)
              .style("left", `${event.pageX + 10}px`)
              .style("top", `${event.pageY - 28}px`);
          })
          .on("mouseout", () => {
            tooltip
              .transition()
              .duration(500)
              .style("opacity", 0);
          });

      svg.call(d3.zoom().on("zoom", (event) => {
        const newXScale = event.transform.rescaleX(xScale);
        const newYScale = event.transform.rescaleY(yScale);
        svg.select("g.x.axis").call(d3.axisBottom(newXScale));
        svg.select("g.y.axis").call(d3.axisLeft(newYScale));
        linePath.attr("d", d3.line()
          .x(d => newXScale(d.Year))
          .y(d => newYScale(d[metric]))
        );
        svg.selectAll("circle")
          .attr("cx", d => newXScale(d.Year))
          .attr("cy", d => newYScale(d[metric]));
      }));
    });

  }, [metric]);

  return (
    <div className='bubble-cont'>
      <h2>Environmental Metrics Over Time</h2>
      <div>
        <label>Select Metric: </label>
        <select onChange={e => setMetric(e.target.value)} value={metric}>
          <option value="Temperature">Temperature Anomalies</option>
          <option value="CO2">CO2 Emissions</option>
          {/* Add more options as needed */}
        </select>
      </div>
      <div ref={chartRef}></div>
      <button onClick={() => setShowDetails(!showDetails)}>
        {showDetails ? 'Hide Details' : 'Show More'}
      </button>
      {showDetails && (
        <div className="bubble-show">
          <h3>Details about Metrics</h3>
          <p><strong>CO2 Emissions:</strong> This metric tracks the amount of carbon dioxide emitted into the atmosphere. High levels of CO2 emissions contribute to global warming and climate change.</p>
          <p><strong>Temperature Anomalies:</strong> This metric shows deviations from the average temperature. Positive anomalies indicate warmer conditions, while negative anomalies suggest cooler conditions compared to historical averages.</p>
        </div>
      )}
    </div>
  );
};

export default D3Chart;
