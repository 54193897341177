import React from 'react'
import HTCSS from './HTCSS';
import React_Tutorial from './React_Tuorial';
import GenerativeAI from './GenerativeAI';
import IntroAI from './IntroAI';
import AzureAI from './AzureAI';
import VertexAI from './VertexAI';
import ArduinoP from './ArduinoP';
import Tensor from './Tensor';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
// import Strings from "./Components/Strings";

function Learn() {
  return (
    <div>
    <h2>Learning Path📚</h2>
    <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Introduction to Artificial Intelligence
        </AccordionSummary>
        <AccordionDetails>
        {/* <Strings /> */}
        </AccordionDetails>
      </Accordion>
    <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Introduction to Artificial Intelligence
        </AccordionSummary>
        <AccordionDetails>
        <IntroAI />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
         Introduction to Tensor Flow
        </AccordionSummary>
        <AccordionDetails>
        <Tensor />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Introduction to GenerativeAI
        </AccordionSummary>
        <AccordionDetails>
        <GenerativeAI />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Introduction to Microsoft Azure AI
        </AccordionSummary>
        <AccordionDetails>
        <AzureAI />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Introduction to CSS Flexbox
        </AccordionSummary>
        <AccordionDetails>
        <HTCSS />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Semiconductor and Microcontroller
        </AccordionSummary>
        <AccordionDetails>
        <HTCSS />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Mic2ExL Arduino Snake Project Presentation
        </AccordionSummary>
        <AccordionDetails>
        <ArduinoP />
        </AccordionDetails>
      </Accordion>
      {/* <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          Accordion Actions
        </AccordionSummary>
        <AccordionDetails>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </AccordionDetails>
        <AccordionActions>
          <Button>Cancel</Button>
          <Button>Agree</Button>
        </AccordionActions>
      </Accordion> */}
   
    
    
    </div>
  )
}

export default Learn