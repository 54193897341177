import React from 'react';
import intro from "../assets/intro.MP4";
import Button from '@mui/material/Button';

function IntroAI() {
  return (
    <div className="container">
    <div style={{ padding: '20px', color: '#333', fontFamily: 'Arial, sans-serif' }}>
      <h1 className="heading"> Artificial Intelligence Certifications on LinkedIn Learning</h1>
      <form className="form">
      <h3>
        As someone who's always been fascinated by technology and its endless possibilities, diving into the world of Artificial Intelligence (AI) felt like the natural next step in my professional development. Recently, I embarked on a learning journey with LinkedIn Learning, and I'm excited to share my experience and the practical knowledge I've gained through their AI certification courses.
      </h3>
      
      <h2>Discovering LinkedIn Learning</h2>
      <h3>
        When I first started looking for comprehensive and accessible resources to learn about AI, LinkedIn Learning stood out as a top choice. The platform offers a range of courses taught by industry experts, covering everything from the basics of AI to more advanced topics like machine learning algorithms and neural networks. The flexibility to learn at my own pace was a significant advantage, allowing me to balance my studies with work and other commitments.
      </h3>
      
      <h2>Course Highlights and Learning Outcomes</h2>
      <h3>
        Throughout the certification process, I had the opportunity to dive deep into several key areas of AI. Here are some highlights from my learning journey:
      </h3>
      <h3>1. Introduction to AI and Machine Learning</h3>
      <h3>
        This foundational course provided a solid understanding of what AI is, its history, and its potential applications. It also introduced me to basic machine learning concepts, which set the stage for more advanced topics.
      </h3>
      <h3>2. Deep Learning Fundamentals</h3>
      <h3>
        Delving into neural networks and deep learning, this course was particularly challenging yet rewarding. I learned about various neural network architectures, including convolutional and recurrent neural networks, and their applications in image and speech recognition.
      </h3>
      <h3>3. Practical AI Applications</h3>
      <h3>
        One of the most exciting parts of my learning experience was exploring practical AI applications. I got hands-on experience with tools and frameworks like TensorFlow and PyTorch, which are essential for building and deploying AI models.
      </h3>
      
      <h2>Applying What I've Learned</h2>
      <h3>
        To truly solidify my understanding of AI, I decided to create a short demo video showcasing a practical project I developed during the course. In the video, I demonstrate how to build and train a simple AI model using TensorFlow. The model is designed to classify images, a common task in the field of computer vision.
      </h3>
      <h3>
        Creating this demo was not only a great way to apply what I've learned but also an opportunity to share my newfound knowledge with others. By walking through the process step-by-step, I aim to make AI more accessible and inspire others to explore its possibilities.
      </h3>
      
      <h2>Why AI Certification Matters</h2>
      <h3>
        Earning these AI certifications has been a transformative experience for me. Not only have I gained valuable technical skills, but I've also developed a deeper appreciation for the potential of AI to drive innovation across various industries. The certifications from LinkedIn Learning have boosted my confidence and credibility in the field, opening up new career opportunities and paving the way for future growth.
      </h3>
      
      {/* <h2>Final Thoughts</h2>
      <h3>
        The journey through LinkedIn Learning's AI certification courses has been incredibly rewarding. The practical skills and knowledge I've acquired have equipped me to tackle real-world challenges and contribute to the exciting field of artificial intelligence. I encourage anyone interested in AI to take the plunge and explore the wealth of resources available on LinkedIn Learning. Whether you're a beginner or looking to deepen your expertise, there's something for everyone.
      </h3> */}
      <h3>
        Stay tuned for my demo video, where I'll showcase the practical aspects of AI and share more insights from my learning journey. Thank you for joining me on this exciting adventure into the world of artificial intelligence!
      </h3>
      <div style={{ marginTop: '20px' }}>
        <h3>Watch My Demo Video</h3>
        <div>
        <iframe className="pfp1" alt="pfp" src={intro} width="100%" height="350px"  autoplay="0" muted></iframe>
        <br/>
          <h2>Introduction to Artificial Intelligence</h2>
        </div>
      </div>
      <Button variant="outlined" href="/">Back</Button>
      </form>
      
</div>
    </div>
  )
}

export default IntroAI