import React from 'react'
import './Skills.css'
import Button from '@mui/material/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Lm from "../assets/Lm.png"
import last from "../assets/last.png"
import digital from "../assets/digital.png"
// import Button from '@mui/material/Button';
function Skills() {
  return (
    <div>
    
    <h1>Language Learning Models (LLM)</h1>
    <div className='intro2'>
      <div className='port-2'>
      <img className="pfp1" alt="pfp" src={Lm} width="200px"></img>
      <h2>Lmstudio.ai</h2>
      <h2>
      Lmstudio.ai is a language learning platform that provides a dynamic and visually appealing platform for users to learn languages.
      </h2>
      <Button variant="outlined" href="https://lmstudio.ai/" target="_blank">
          Learn more ☞
          <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>
      </div>
      <div className='port-2'>
      <img className="pfp1" alt="pfp" src={digital} width="200px"></img>
      <h2>DigitalOcean</h2>
      <h2>
      DigitalOcean is a cloud infrastructure provider that offers a variety of services to help deploy and scale applications that run simultaneously on multiple computers.
      </h2>
      <Button variant="outlined" href="https://www.digitalocean.com/resources/article/open-source-ai-platforms" target="_blank">
          Learn more ☞
          <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>

</div>
 <div className='port-2'>
 <img className="pfp1" alt="pfp" src={last} width="200px"></img>
      <h2>Lastmile.ai</h2>
      <h2>
      Lastmile.ai is a language learning platform that provides a dynamic and visually appealing platform for users to learn languages.
      </h2>
      <Button variant="outlined" href="https://lastmileai.dev/" target="_blank">
          Learn more ☞
          <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>

</div>
    </div>
        <Button variant="contained" href="/" target="_blank">
 Back
</Button>
    </div>
  )
}

export default Skills