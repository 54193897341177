import React from 'react'
import React_setup from '../assets/React_setup.png';
import ai_dev from "../assets/ai_dev.MP4";
import Button from '@mui/material/Button';

function React_Tutorial() {
  return (
    <div className="container">
    <h2 className="heading">React Setup Tutorial:</h2>
    <form className='form'>
    <h2>
    <ol>

<li>  Introduction to React and its benefits. </li>
<li> Installing Node.js and npm. </li>
<li> Creating a new React project using Create React App. </li>
<li>Exploring the project structure and understanding key files.  </li>
<li> Building a simple React component and rendering it in the browser. </li>
</ol>
    </h2>
    
    <img src={React_setup} alt="React_setup file" height="600" width="100%" className="pfp1 animate__backInDown" />
     <br/>
    <div >
        <iframe className="pfp1" alt="pfp" src={ai_dev} width="100%" height="350px"></iframe>
        <br/>
          <h2>Description: React Set up</h2>
        </div>
<Button variant="contained" href="/React_Tutorial" target="_blank">
 Learn more ☞
</Button>
 <Button variant="contained" href="/" target="_blank">
 Back
</Button>
    </form>
  


    
    {/* <iframe height="300" width= " 100%" scrolling="no" title="Interactive Code Example" src="https://codepen.io/Olayinka-Fakanbi/pen/MWRPqZa?editors=1100"></iframe> */}
    
    </div>
  )
}

export default React_Tutorial;