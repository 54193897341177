import React from 'react'

function ArduinoP() {
  return (
    <div>
    <h1>Arduino Snake C++ Final Project</h1>
    <div>
        <div className=''>
        <embed src="/SnakeGame.pdf" type="application/pdf" width="100%" height="2500px" />

        </div>
    </div>
    </div>
  )
}

export default ArduinoP