import React from 'react';
import folio from '../assets/folio.jpg';
import apple from '../assets/apple.jpg';

import Button from '@mui/material/Button';

function Jobs() {
  return (
    <div>
        <h1>Tech Jobs</h1>
        <div className="intro2">
            <div className="port-2">
               
            
                <img className='pfp1' alt="pfp" src={folio} width="200px"/>
                <h1>NYC Software Engineer Jobs</h1>
                <h3> 
                    Find the best software engineering jobs in NYC. 
                    Getro is the best way to find your next tech job in NYC. 
                    We have the best companies in NYC looking for software engineers. 
                    Find your next software engineering job in NYC with Getro.
                </h3>
                <Button variant="outlined" href="https://technyc.getro.com/companies" target="_blank">
          ☞Visit Website
</Button>
            </div>
        </div>
        <div className="port-2">
               
            
               <img className='pfp1' alt="pfp" src={apple} width="200px"/>
               <h1>Apple Software Engineer Jobs</h1>
               <h3> 
                    Find the best software engineering jobs at Apple. 
                    Getro is the best way to find your next tech job at Apple. 
                    We have the best companies in Apple looking for software engineers. 
                    Find your next software engineering job at Apple with Getro.
               </h3>
               <Button variant="outlined" href="https://jobs.apple.com/en-us/search?location=new-york-state985&sort=relevance" target="_blank">
         ☞Visit Website
</Button>
           </div>
    </div>
  )
}

export default Jobs