import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import './BarChart.css';

const BarChart = () => {
  const chartRef = useRef();
  const [selectedMetric, setSelectedMetric] = useState('CO2 Emissions');

  useEffect(() => {
    const margin = { top: 20, right: 20, bottom: 30, left: 40 },
          width = 380 - margin.left - margin.right,
          height = 400 - margin.top - margin.bottom;

    const svg = d3.select(chartRef.current)
      .html("") // Clear previous content
      .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
      .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

    d3.csv("/bar_chart_data.csv").then(data => {
      data.forEach(d => {
        d.Year = d.Year;
        d[selectedMetric] = +d[selectedMetric];
      });

      const x = d3.scaleBand()
        .domain(data.map(d => d.Year))
        .range([0, width])
        .padding(0.1);

      const y = d3.scaleLinear()
        .domain([0, d3.max(data, d => d[selectedMetric])])
        .nice()
        .range([height, 0]);

      svg.append("g")
        .attr("class", "x-axis")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(x));

      svg.append("g")
        .attr("class", "y-axis")
        .call(d3.axisLeft(y));

      svg.selectAll(".bar")
        .data(data)
        .enter().append("rect")
        .attr("class", "bar")
        .attr("x", d => x(d.Year))
        .attr("y", d => y(d[selectedMetric]))
        .attr("width", x.bandwidth())
        .attr("height", d => height - y(d[selectedMetric]))
        .attr("fill", "#69b3a2")
        .on("mouseover", function(event, d) {
          d3.select(this).attr("fill", "#a4c8a6");
          d3.select(".tooltip")
            .style("opacity", 1)
            .html(`Year: ${d.Year}<br/>Value: ${d[selectedMetric]}`)
            .style("left", `${event.pageX + 10}px`)
            .style("top", `${event.pageY - 28}px`);
        })
        .on("mouseout", function() {
          d3.select(this).attr("fill", "#69b3a2");
          d3.select(".tooltip").style("opacity", 0);
        });

      d3.select("body").append("div")
        .attr("class", "tooltip")
        .style("position", "absolute")
        .style("background-color", "white")
        .style("border", "1px solid #ccc")
        .style("border-radius", "4px")
        .style("padding", "5px")
        .style("opacity", 0);
    });
  }, [selectedMetric]);

  return (
    <div className='container'>
      <h2>Environmental Data with Annotations</h2>
      <label>Select Metric: </label>
      <select onChange={e => setSelectedMetric(e.target.value)} value={selectedMetric}>
        <option value="CO2 Emissions">CO2 Emissions</option>
        <option value="Temperature Anomalies">Temperature Anomalies</option>
        <option value="Sea Level Rise">Sea Level Rise</option>
        <option value="Air Quality Index">Air Quality Index</option>
      </select>
      <div ref={chartRef}></div>
    </div>
  );
};

export default BarChart;
