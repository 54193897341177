import React from 'react'
import AI_p from '../assets/AI_p.png'
import rise from "../assets/rise.png";
import Button from '@mui/material/Button';
// import AI_Programmer from '../assets/AI_Programmer.jpg'
// import qvid from '../assets/qvid.mp4'

function AI_Programmer() {
  return (
    <div className="container">
    <h2 className="heading">The Rise of AI Programmers</h2>
    <form className="form">
    <h2>As artificial intelligence (AI) continues to advance, there has been a growing discourse surrounding the role of human programmers in a world increasingly dominated by intelligent machines. Some speculate that the rise of AI will herald the end of human programmers, while others argue that it will lead to a new era of collaboration between humans and machines. In this article, we explore the implications of the rise of AI programmers and examine how humans can adapt and thrive in this evolving landscape of automation and innovation.</h2>
<img loading="lazy" src={AI_p} alt="Quantum Computing" className="pfp1 animate__backInDown" />
    <h2>Rather than replacing human programmers, AI serves as a tool for augmenting their capabilities, enabling them to work more efficiently and effectively. AI-powered tools and platforms assist programmers in various tasks, such as code generation, debugging, and optimization, speeding up development processes and reducing errors. By leveraging AI, human programmers can focus on higher-level problem-solving and creativity, driving innovation in software development.</h2>
    {/* <iframe className="pfp1" alt="pfp" src={qvid} width="auto" height="auto" autoplay="false"></iframe> */}
    <h2>AI democratizes programming by lowering the barriers to entry and empowering individuals with varying levels of technical expertise to create software. With the rise of low-code and no-code platforms infused with AI capabilities, non-programmers can develop applications using intuitive visual interfaces and pre-built components. This democratization expands the pool of programmers and fosters innovation across industries, leading to a more inclusive and diverse tech ecosystem.</h2>
    <img loading="lazy" src={rise} alt="Quantum Computing" className="pfp1 animate__backInDown" />
    <h2>The future of programming lies in collaboration between humans and machines, where each leverages their unique strengths to achieve better outcomes. While AI excels at repetitive tasks and data processing, human programmers bring creativity, intuition, and domain expertise to the table. By working together, humans and AI can tackle complex problems, explore new possibilities, and create innovative solutions that neither could achieve alone.</h2>
    <Button variant="contained" href="/" target="_blank">
 Back
</Button>
    </form>
    
    </div>
  )
}

export default AI_Programmer