import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import './BarChart.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import './Bubble.css';

const Selection = () => {
  const chartRef = useRef();
  const [data, setData] = useState([30, 50, 35, 65, 70, 75, 80]);
  const [inputValue, setInputValue] = useState('');
  const [sortingCompleted, setSortingCompleted] = useState(false);
  const [showExplanation, setShowExplanation] = useState(false);
  const [transitionDuration, setTransitionDuration] = useState(500); // default to 500ms

  useEffect(() => {
    const margin = { top: 20, right: 20, bottom: 30, left: 40 },
          width = 300 - margin.left - margin.right,
          height = 400 - margin.top - margin.bottom;

    const svg = d3.select(chartRef.current)
      .html("")
      .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
      .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

    const x = d3.scaleBand()
      .domain(data.map((_, i) => i))
      .range([0, width])
      .padding(0.1);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data)])
      .nice()
      .range([height, 0]);

    const bars = svg.selectAll(".bar")
      .data(data)
      .enter().append("rect")
      .attr("class", "bar")
      .attr("x", (d, i) => x(i))
      .attr("y", d => y(d))
      .attr("width", x.bandwidth())
      .attr("height", d => height - y(d))
      .attr("fill", "#69b3a2");

    const updateChart = (newData) => {
      bars.data(newData)
        .transition()
        .duration(transitionDuration) // use the transition duration from state
        .attr("x", (d, i) => x(i))
        .attr("y", d => y(d))
        .attr("height", d => height - y(d));
    };

    const selectionSort = async (arr) => {
      for (let i = 0; i < arr.length - 1; i++) {
        let minIndex = i;
        for (let j = i + 1; j < arr.length; j++) {
          if (arr[j] < arr[minIndex]) minIndex = j;
        }
        if (minIndex !== i) {
          [arr[i], arr[minIndex]] = [arr[minIndex], arr[i]];
          updateChart(arr);
          await new Promise(resolve => setTimeout(resolve, transitionDuration));
        }
      }
      setSortingCompleted(true);
    };

    if (data.length > 0) {
      selectionSort(data);
    }
  }, [data, transitionDuration]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSortClick = () => {
    const newData = inputValue.split(',').map(Number);
    setData(newData);
    setSortingCompleted(false);
  };

  const handleTransitionChange = (event, newValue) => {
    setTransitionDuration(newValue);
  };

  return (
    <div className='bubble-cont'>
      <h2>Selection Sort Visualization</h2>
      <input 
        type="text" 
        value={inputValue} 
        onChange={handleInputChange} 
        placeholder="Enter numbers separated by commas" 
      />
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      ></Box>
      <button onClick={handleSortClick}>Sort</button>
      <div ref={chartRef}></div>
      <div style={{ marginTop: '20px' }}>
        <Typography gutterBottom>Transition Speed (ms)</Typography>
        <Slider
          value={transitionDuration}
          onChange={handleTransitionChange}
          min={100}
          max={2000}
          step={100}
          valueLabelDisplay="auto"
          aria-labelledby="transition-speed-slider"
          sx={{
            width: 300,
            margin: '0 auto',
          }}
        />
        <Typography variant="caption" color="textSecondary" align="center">
          {transitionDuration} ms
        </Typography>
      </div>
      {sortingCompleted && (
        <div className='bubble-show'>
          <button onClick={() => setShowExplanation(!showExplanation)}>
            {showExplanation ? 'Hide Explanation' : 'Show Explanation'}
          </button>
          {showExplanation && (
            <div className="selection-box">
            <h3>Selection Sort Explanation</h3>
              <p>
                Selection Sort is a simple sorting algorithm that repeatedly selects the smallest element from the unsorted portion and moves it to the sorted portion. Here’s a detailed explanation of how it works:
              </p>
              <div>
                <strong>Initialization:</strong>
                <pre>{`for (let i = 0; i < n - 1; i++) {}`}</pre>
                <p>
                  <strong>Explanation:</strong> 
                  The outer loop iterates through the array, selecting each element in turn to place in its correct position. `n` is the length of the array, and the loop runs until the second-to-last element.
                </p>
              </div>
              <div>
                <strong>Find Minimum:</strong>
                <pre>{`let minIndex = i;
for (let j = i + 1; j < n; j++) {
  if (arr[j] < arr[minIndex]) {
    minIndex = j;
  }
}`}</pre>
                <p>
                  <strong>Explanation:</strong> 
                  The inner loop finds the smallest element in the unsorted portion of the array by comparing each element with the current minimum. `minIndex` keeps track of the index of the smallest element found.
                </p>
              </div>
              <div>
                <strong>Swap Elements:</strong>
                <pre>{`if (minIndex !== i) {
  [arr[i], arr[minIndex]] = [arr[minIndex], arr[i]];
}`}</pre>
                <p>
                  <strong>Explanation:</strong> 
                  After finding the smallest element in the unsorted portion, it is swapped with the element at the current position `i`. This places the smallest element in its correct position in the sorted portion.
                </p>
              </div>
              <p>
                This process repeats until the entire array is sorted, with each iteration placing the next smallest element in its correct position.
              </p>
              <h1>Geeks Solution</h1>
              <h3>Selection Sort Explanation</h3>
<p>
  Selection Sort is a simple comparison-based algorithm that works by repeatedly finding the minimum element from the unsorted portion of the array and moving it to the beginning. Below is the explanation using the provided Selection Sort code:
</p>

<div>
  <strong>Swap Function:</strong>
  <pre>{`function swap(arr, xp, yp) {
    var temp = arr[xp];
    arr[xp] = arr[yp];
    arr[yp] = temp;
}`}</pre>
  <p>
    <strong>Explanation:</strong> 
    The `swap` function exchanges the elements at indices `xp` and `yp` in the array. It uses a temporary variable `temp` to facilitate the swap.
  </p>
</div>

<div>
  <strong>SelectionSort Function:</strong>
  <pre>{`function selectionSort(arr, n) {
    var i, j, min_idx;
    // One by one move boundary of unsorted subarray
    for (i = 0; i < n - 1; i++) {
        // Find the minimum element in unsorted array
        min_idx = i;
        for (j = i + 1; j < n; j++)
            if (arr[j] < arr[min_idx])
                min_idx = j;
        // Swap the found minimum element with the first element
        swap(arr, min_idx, i);
    }
}`}</pre>
  <p>
    <strong>Explanation:</strong> 
    The `selectionSort` function iterates through the array, finding the minimum element in the unsorted portion and swapping it with the first unsorted element. This process is repeated until the entire array is sorted.
  </p>
</div>

<div>
  <strong>Print Array Function:</strong>
  <pre>{`function printArray(arr, size) {
    var i;
    for (i = 0; i < size; i++)
        document.write(arr[i] + " ");
    document.write(" <br>");
}`}</pre>
  <p>
    <strong>Explanation:</strong> 
    The `printArray` function prints each element of the array followed by a space. It is used to display the sorted array after applying Selection Sort.
  </p>
</div>

<div>
  <strong>Driver Code:</strong>
  <pre>{`var arr = [64, 25, 12, 22, 11];
var n = 5;
selectionSort(arr, n);
document.write("Sorted array: <br>");
printArray(arr, n);`}</pre>
  <p>
    <strong>Explanation:</strong> 
    This driver code initializes an unsorted array, sorts it using `selectionSort`, and prints the sorted array. It demonstrates the complete execution of the Selection Sort algorithm.
  </p>
</div>

<p>
  Selection Sort has a time complexity of O(n²) and is generally less efficient on large lists compared to algorithms like Quick Sort or Merge Sort. However, it is simple to understand and implement.
</p>

              {/* <h3>Selection Sort Explanation</h3>
              <p>
                Selection Sort is a simple comparison-based algorithm that repeatedly selects the minimum
                element from the unsorted portion of the array and moves it to the end of the sorted portion.
                This process is repeated until the entire array is sorted.
              </p>
              <p>
                This animation shows how the algorithm selects the minimum element and swaps it with the first
                unsorted element, gradually building the sorted portion of the array.
              </p> */}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Selection;
