import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import "./BellCurve.css";

const BellCurve = () => {
  const svgRef = useRef();

  useEffect(() => {
    // Data generation for Normal Distribution
    const data = d3.range(-4, 4, 0.1).map((x) => ({
      x,
      y: (1 / Math.sqrt(2 * Math.PI)) * Math.exp(-(x ** 2) / 2),
    }));

    // Dimensions and margins
    const margin = { top: 20, right: 20, bottom: 40, left: 50 };
    const width = 800 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // X Scale
    const xScale = d3
      .scaleLinear()
      .domain([-4, 4])
      .range([0, width]);

    // Y Scale
    const yScale = d3
      .scaleLinear()
      .domain([0, 0.5])
      .range([height, 0]);

    // X Axis
    svg
      .append("g")
      .attr("class", "axis axis-x")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(xScale));

    // Y Axis
    svg.append("g").attr("class", "axis axis-y").call(d3.axisLeft(yScale));

    // Line generator for the Bell Curve
    const line = d3
      .line()
      .x((d) => xScale(d.x))
      .y((d) => yScale(d.y))
      .curve(d3.curveBasis);

    // Append the line (Bell Curve)
    svg
      .append("path")
      .datum(data)
      .attr("class", "bell-curve")
      .attr("d", line);

    // Animation similar to bubble sort pattern (gradual appearance)
    svg
      .selectAll(".bell-curve")
      .attr("stroke-dasharray", function () {
        return this.getTotalLength();
      })
      .attr("stroke-dashoffset", function () {
        return this.getTotalLength();
      })
      .transition()
      .duration(3000)
      .attr("stroke-dashoffset", 0);
  }, []);

  return (
    <div>
      <svg ref={svgRef}></svg>
      <div className="info">
        <p>
          The Bell Curve (Normal Distribution) represents the spread of data.
          <strong> Standard deviation</strong> measures how spread out the
          values are from the mean.
        </p>
      </div>
    </div>
  );
};

export default BellCurve;
