import React from 'react'
import html from '../assets/html.png';
import Button from '@mui/material/Button';

function HTCSS() {
  return (
    <div className="container">
    <h2 className="heading">HTML and CSS Flex Grid Tutorial:</h2>
    <form className='form'>
    
    <h2>
    <ol>
    <li>Introduction to flexbox layout and its advantages.</li>
        <li>Basic concepts of flex containers and flex items.</li>
        <li>Implementing a simple flex grid layout for a webpage.</li>
        <li>Exploring common use cases and best practices for flexbox.</li>

    </ol>
        
    <img src={html} alt="html file" height="600" width="100%" className="pfp1 animate__backInDown" />
 </h2>
        <iframe height="600" width="100%" scrolling="no" title="HTML CSS Flex box" src="https://codepen.io/Olayinka-Fakanbi/embed/MWRPqZa?default-tab=html%2Cresult" frameborder="no" loading="lazy" allowtransparency="true" allowfullscreen="true">
  See the Pen <a href="https://codepen.io/Olayinka-Fakanbi/pen/MWRPqZa">
  HTML CSS Flex box</a> by Olayinka Fakanbi (<a href="https://codepen.io/Olayinka-Fakanbi">@Olayinka-Fakanbi</a>)
  on <a href="https://codepen.io">CodePen</a>.
</iframe>
<Button variant="contained" href="https://www.loom.com/share/3920da0ecc6d4ce0a5334ea523e24efa" target="_blank">
 Learn more ☞
</Button>
 
    </form>
  


    
    {/* <iframe height="300" width= " 100%" scrolling="no" title="Interactive Code Example" src="https://codepen.io/Olayinka-Fakanbi/pen/MWRPqZa?editors=1100"></iframe> */}
    
    </div>
  )
}

export default HTCSS