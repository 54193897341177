import React, { useState } from 'react';
import * as d3 from 'd3';
// import './DSA.css';

const LinkedListVisualization = () => {
  const [nodes, setNodes] = useState([{ value: 10 }, { value: 20 }, { value: 30 }]);
  const [showExplanation, setShowExplanation] = useState(false);

  const drawLinkedList = () => {
    const svg = d3.select('#linked-list-svg');
    svg.html('');
    const nodeElements = svg.selectAll('.node')
      .data(nodes)
      .enter().append('g')
      .attr('class', 'node')
      .attr('transform', (d, i) => `translate(100, ${i * 50 + 50})`);

    nodeElements.append('circle')
      .attr('r', 20)
      .attr('fill', '#69b3a2');

    nodeElements.append('text')
      .attr('dy', 5)
      .attr('text-anchor', 'middle')
      .text(d => d.value);

    // Add links
    nodeElements.append('line')
      .attr('x1', 20)
      .attr('y1', 0)
      .attr('x2', 80)
      .attr('y2', 0)
      .attr('stroke', '#69b3a2');
  };

  const handleInputChange = (e) => {
    const values = e.target.value.split(',').map(v => ({ value: Number(v.trim()) }));
    setNodes(values);
  };

  return (
    <div>
      <h2>Linked List Visualization</h2>
      <input type="text" placeholder="Enter values (comma separated)" onChange={handleInputChange} />
      <button onClick={drawLinkedList}>Draw Linked List</button>
      <svg id="linked-list-svg" width="500" height="300"></svg>
      <button onClick={() => setShowExplanation(!showExplanation)}>
        {showExplanation ? 'Hide Explanation' : 'Show Explanation'}
      </button>
      {showExplanation && (
        <div>
          <h3>Linked List Explanation</h3>
          <p>Linked lists are data structures where each element points to the next. This visualization shows how nodes are connected in a linked list.</p>
          <pre>
            {`const drawLinkedList = () => {
              const svg = d3.select('#linked-list-svg');
              svg.html('');
              const nodeElements = svg.selectAll('.node')
                .data(nodes)
                .enter().append('g')
                .attr('class', 'node')
                .attr('transform', (d, i) => \`translate(100, \${i * 50 + 50})\`);

              nodeElements.append('circle')
                .attr('r', 20)
                .attr('fill', '#69b3a2');

              nodeElements.append('text')
                .attr('dy', 5)
                .attr('text-anchor', 'middle')
                .text(d => d.value);

              // Add links
              nodeElements.append('line')
                .attr('x1', 20)
                .attr('y1', 0)
                .attr('x2', 80)
                .attr('y2', 0)
                .attr('stroke', '#69b3a2');
            };`}
          </pre>
        </div>
      )}
    </div>
  );
};

export default LinkedListVisualization;
