import React from 'react'

function FourOFour() {
  return (
    <div>
        <img width="100%" src="https://images.pexels.com/photos/3760790/pexels-photo-3760790.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
    </div>
  )
}

export default FourOFour