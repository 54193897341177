// import React from 'react'

// function DeepRacer() {
//   return (
//     <div>
//       <div className="intro2">
//       <h1>AWS DeepRacer League 2024.</h1>
//       <div className="port-2">
//           {/* <iframe className="pfp1" alt="pfp" src={soccer} width="100%" height="350px"  autoplay="0" muted></iframe> */}
//         <br/>
//           <h2>Twitch Streaming Highlight 1</h2>
//         </div>
//         <div className="port-2">
//           {/* <iframe className="pfp1" alt="pfp" src={soccer2} width="100%" height="350px"  autoplay="0" muted></iframe> */}
//         <br/>
//           <h2>Twitch Streaming Highlight 2</h2>
//         </div>
//         <div className="port-2">
//           {/* <iframe className="pfp1" alt="pfp" src={soccer2} width="100%" height="350px"  autoplay="0" muted></iframe> */}
//         <br/>
//           <h2>Twitch Streaming Highlight 2</h2>
//         </div>
//         {/* <div className="port-2">
//           <iframe className="pfp1" alt="pfp" src={soccer2} width="100%" height="350px"  autoplay="0" muted></iframe>
//         <br/>
//           <h2>Twitch Streaming Highlight 2</h2>
//         </div> */}
//         {/* <div className="port-2">
//           <iframe className="pfp1" alt="pfp" src={soccer2} width="100%" height="350px"  autoplay="0" muted></iframe>
//         <br/>
//           <h2>Twitch Streaming Highlight 2</h2>
//         </div> */}
//         {/* <div className="port-2">
//           <iframe className="pfp1" alt="pfp" src={soccer2} width="100%" height="350px"  autoplay="0" muted></iframe>
//         <br/>
//           <h2>Twitch Streaming Highlight 2</h2>
//         </div> */}
//         {/* <div className="port-2">
//           <iframe className="pfp1" alt="pfp" src={soccer2} width="100%" height="350px" muted></iframe>
//         <br/>
//           <h2>Twitch Streaming Highlight 2</h2>
//         </div> */}
//         {/* <div className="port-2">
//           <iframe className="pfp1" alt="pfp" src={soccer2} width="100%" height="350px"  autoplay="0" muted></iframe>
//         <br/>
//           <h2>Twitch Streaming Highlight 2</h2>
//         </div> */}
//         {/* <div className="port-2">
//           <iframe className="pfp1" alt="pfp" src={soccer2} width="100%" height="350px"  autoplay="0" muted></iframe>
//         <br/>
//           <h2>Twitch Streaming Highlight 2</h2>
//         </div> */}
//         </div>
        
//     </div>
//   )
// }

// export default DeepRacer



// import React, { useEffect, useRef, useState } from 'react';
// import * as d3 from 'd3';
// import Button from '@mui/material/Button';
// import './CarAnimation.css'; // Optional CSS for styling

// const CarAnimation = () => {
//   const canvasRef = useRef();
//   const [animationRunning, setAnimationRunning] = useState(false);
//   const [carPosition, setCarPosition] = useState(0);

//   useEffect(() => {
//     const width = 600;
//     const height = 200;

//     // Select the canvas and set dimensions
//     const canvas = d3.select(canvasRef.current)
//       .attr("width", width)
//       .attr("height", height);

//     const context = canvas.node().getContext('2d');

//     // Function to draw the car
//     const drawCar = (x) => {
//       context.clearRect(0, 0, width, height); // Clear the canvas
//       context.fillStyle = 'blue';
//       context.fillRect(x, height - 50, 50, 20); // Draw the car body

//       // Draw wheels
//       context.fillStyle = 'black';
//       context.beginPath();
//       context.arc(x + 10, height - 20, 5, 0, 2 * Math.PI);
//       context.arc(x + 40, height - 20, 5, 0, 2 * Math.PI);
//       context.fill();
//     };

//     let animationFrame;
//     const animateCar = () => {
//       setCarPosition((prev) => {
//         const newPosition = (prev + 2) % width; // Move the car across the screen
//         drawCar(newPosition);
//         return newPosition;
//       });
//       animationFrame = requestAnimationFrame(animateCar);
//     };

//     if (animationRunning) {
//       animationFrame = requestAnimationFrame(animateCar);
//     } else {
//       cancelAnimationFrame(animationFrame);
//     }

//     return () => cancelAnimationFrame(animationFrame);
//   }, [animationRunning]);

//   return (
//     <div className="car-animation-container">
//       <h2>Car Animation</h2>
//       <canvas ref={canvasRef}></canvas>
//       <Button 
//         variant="contained" 
//         onClick={() => setAnimationRunning(!animationRunning)}
//         style={{ marginTop: '20px' }}
//       >
//         {animationRunning ? 'Stop' : 'Start'} Animation
//       </Button>
//     </div>
//   );
// };

// export default CarAnimation;


// import React, { useEffect, useRef, useState } from 'react';
// import * as d3 from 'd3';
// import Button from '@mui/material/Button';
// import './CarAnimation.css'; // Optional CSS for styling

// const CarAnimation = () => {
//   const canvasRef = useRef();
//   const [animationRunning, setAnimationRunning] = useState(false);
//   const [carPosition, setCarPosition] = useState(0);

//   useEffect(() => {
//     const updateCanvasSize = () => {
//       const canvas = canvasRef.current;
//       const width = canvas.parentElement.offsetWidth;  // Responsive width
//       const height = 200;

//       // Set the canvas dimensions
//       d3.select(canvas)
//         .attr("width", width)
//         .attr("height", height);

//       const context = canvas.getContext('2d');
      
//       // Function to draw the car with dynamic colors
//       const drawCar = (x) => {
//         context.clearRect(0, 0, width, height); // Clear the canvas

//         // Draw car body
//         const carColor = d3.interpolateRainbow((x % width) / width); // Dynamic car color
//         context.fillStyle = carColor;
//         context.fillRect(x, height - 50, 50, 20); // Car body

//         // Draw wheels
//         context.fillStyle = 'black';
//         context.beginPath();
//         context.arc(x + 10, height - 20, 5, 0, 2 * Math.PI);
//         context.arc(x + 40, height - 20, 5, 0, 2 * Math.PI);
//         context.fill();
//       };

//       let animationFrame;
//       const animateCar = () => {
//         setCarPosition((prev) => {
//           const newPosition = (prev + 2) % width; // Move the car across the screen
//           drawCar(newPosition);
//           return newPosition;
//         });
//         animationFrame = requestAnimationFrame(animateCar);
//       };

//       if (animationRunning) {
//         animationFrame = requestAnimationFrame(animateCar);
//       } else {
//         cancelAnimationFrame(animationFrame);
//       }

//       return () => cancelAnimationFrame(animationFrame);
//     };

//     // Trigger canvas size update on window resize
//     window.addEventListener('resize', updateCanvasSize);

//     // Initialize canvas on component mount
//     updateCanvasSize();

//     return () => window.removeEventListener('resize', updateCanvasSize);
//   }, [animationRunning]);

//   return (
//     <div className="car-animation-container">
//       <h2>Responsive Car Animation</h2>
//       <canvas ref={canvasRef}></canvas>
//       <Button
//         variant="contained"
//         onClick={() => setAnimationRunning(!animationRunning)}
//         style={{ marginTop: '20px' }}
//       >
//         {animationRunning ? 'Stop' : 'Start'} Animation
//       </Button>
//       <Button
//         variant="outlined"
//         onClick={() => setCarPosition(0)} // Reset the car position
//         style={{ marginTop: '10px', marginLeft: '10px' }}
//       >
//         Restart Animation
//       </Button>
//     </div>
//   );
// };

// export default CarAnimation;


// import React, { useEffect, useRef, useState } from 'react';
// import * as d3 from 'd3';
// import Button from '@mui/material/Button';
// import './CarAnimation.css'; // Optional CSS for styling

// const CarAnimation = () => {
//   const canvasRef = useRef();
//   const [animationRunning, setAnimationRunning] = useState(false);
//   const [carPosition, setCarPosition] = useState(0);
//   const [carYOffset, setCarYOffset] = useState(0); // Introduce Y offset for swerving
//   const [reward, setReward] = useState(0);
//   const [explanationOpen, setExplanationOpen] = useState(false);

//   useEffect(() => {
//     const updateCanvasSize = () => {
//       const canvas = canvasRef.current;
//       const width = canvas.parentElement.offsetWidth;
//       const height = 200;

//       // Set the canvas dimensions
//       d3.select(canvas)
//         .attr("width", width)
//         .attr("height", height);

//       const context = canvas.getContext('2d');

//       // Draw the track
//       const drawTrack = () => {
//         context.clearRect(0, 0, width, height);

//         // Draw the lane
//         context.strokeStyle = 'gray';
//         context.lineWidth = 2;
//         context.beginPath();
//         context.moveTo(0, height - 70);
//         context.lineTo(width, height - 70);
//         context.stroke();
//       };

//       // Function to draw the car with dynamic colors
//       const drawCar = (x, yOffset) => {
//         drawTrack(); // Always draw the track before drawing the car

//         // Draw car body
//         const carColor = d3.interpolateRainbow((x % width) / width);
//         context.fillStyle = carColor;
//         context.fillRect(x, height - 70 + yOffset, 50, 20);

//         // Draw wheels
//         context.fillStyle = 'black';
//         context.beginPath();
//         context.arc(x + 10, height - 50 + yOffset, 5, 0, 2 * Math.PI);
//         context.arc(x + 40, height - 50 + yOffset, 5, 0, 2 * Math.PI);
//         context.fill();
//       };

//       let animationFrame;
//       const animateCar = () => {
//         setCarPosition((prev) => {
//           const newPosition = (prev + 2) % width;

//           // Introduce random swerving
//           let newYOffset = carYOffset;
//           if (Math.random() < 0.1) { // 10% chance to swerve
//             newYOffset = Math.random() * 40 - 20; // Swerving range (-20 to +20)
//             setCarYOffset(newYOffset);
//           }

//           // Reward system
//           if (Math.abs(newYOffset) < 5) {
//             setReward((prevReward) => prevReward + 1); // Positive reward for staying on track
//           } else {
//             setReward((prevReward) => prevReward - 1); // Negative reward for swerving
//           }

//           drawCar(newPosition, newYOffset);
//           return newPosition;
//         });

//         animationFrame = requestAnimationFrame(animateCar);
//       };

//       if (animationRunning) {
//         animationFrame = requestAnimationFrame(animateCar);
//       } else {
//         cancelAnimationFrame(animationFrame);
//       }

//       return () => cancelAnimationFrame(animationFrame);
//     };

//     window.addEventListener('resize', updateCanvasSize);
//     updateCanvasSize();

//     return () => window.removeEventListener('resize', updateCanvasSize);
//   }, [animationRunning, carYOffset]);

//   return (
//     <div className="car-animation-container">
//       <h2>Car Animation with Reward System</h2>
//       <canvas ref={canvasRef}></canvas>

//       <Button
//         variant="contained"
//         onClick={() => setAnimationRunning(!animationRunning)}
//         style={{ marginTop: '20px' }}
//       >
//         {animationRunning ? 'Stop' : 'Start'} Animation
//       </Button>

//       <Button
//         variant="outlined"
//         onClick={() => {
//           setCarPosition(0);
//           setCarYOffset(0);
//           setReward(0);
//         }}
//         style={{ marginTop: '10px', marginLeft: '10px' }}
//       >
//         Restart Animation
//       </Button>

//       <div style={{ marginTop: '20px' }}>
//         <h3>Current Reward: {reward}</h3>
//         <div>
//           <Button
//             variant="outlined"
//             onClick={() => setExplanationOpen(!explanationOpen)}
//           >
//             {explanationOpen ? 'Hide' : 'Show'} Explanation
//           </Button>
//           {explanationOpen && (
//             <div className="reward-explanation" style={{ marginTop: '10px' }}>
//               <p>
//                 This car is being trained to stay on the lane. Every time the
//                 car stays on the lane, it receives a positive reward. When the
//                 car swerves off the lane, it receives a negative reward (punishment).
//               </p>
//               <p>
//                 The goal is for the agent to learn to stay on track as much as
//                 possible by accumulating positive rewards.
//               </p>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CarAnimation;

// import React, { useEffect, useRef, useState } from 'react';
// import * as d3 from 'd3';
// import Button from '@mui/material/Button';
// import './CarAnimation.css';

// const CarAnimation = () => {
//   const canvasRef = useRef();
//   const [animationRunning, setAnimationRunning] = useState(false);
//   const [carPosition, setCarPosition] = useState(0);
//   const [carYOffset, setCarYOffset] = useState(0); // Introduce Y offset for swerving
//   const [reward, setReward] = useState(0);
//   const [explanationOpen, setExplanationOpen] = useState(false);

//   useEffect(() => {
//     const updateCanvasSize = () => {
//       const canvas = canvasRef.current;
//       const width = canvas.parentElement.offsetWidth;
//       const height = 300; // Increased the canvas height for larger car

//       // Set the canvas dimensions
//       d3.select(canvas)
//         .attr("width", width)
//         .attr("height", height);

//       const context = canvas.getContext('2d');

//       // Draw the track
//       const drawTrack = () => {
//         context.clearRect(0, 0, width, height);

//         // Draw the lane
//         context.strokeStyle = 'gray';
//         context.lineWidth = 4; // Thicker line for visibility
//         context.beginPath();
//         context.moveTo(0, height - 100); // Adjusted to fit larger car
//         context.lineTo(width, height - 100);
//         context.stroke();
//       };

//       // Function to draw the car with dynamic colors
//       const drawCar = (x, yOffset) => {
//         drawTrack(); // Always draw the track before drawing the car

//         // Draw car body
//         const carColor = d3.interpolateRainbow((x % width) / width);
//         context.fillStyle = carColor;
//         context.fillRect(x, height - 100 + yOffset, 70, 40); // Larger car

//         // Draw wheels
//         context.fillStyle = 'black';
//         context.beginPath();
//         context.arc(x + 15, height - 60 + yOffset, 8, 0, 2 * Math.PI); // Larger wheels
//         context.arc(x + 55, height - 60 + yOffset, 8, 0, 2 * Math.PI);
//         context.fill();
//       };

//       let animationFrame;
//       const animateCar = () => {
//         setCarPosition((prev) => {
//           const newPosition = (prev + 1) % width; // Slower speed

//           // Introduce random swerving
//           let newYOffset = carYOffset;
//           if (Math.random() < 0.1) { // 10% chance to swerve
//             newYOffset = Math.random() * 40 - 20; // Swerving range (-20 to +20)
//             setCarYOffset(newYOffset);
//           }

//           // Reward system
//           if (Math.abs(newYOffset) < 5) {
//             setReward((prevReward) => prevReward + 1); // Positive reward for staying on track
//           } else {
//             setReward((prevReward) => prevReward - 1); // Negative reward for swerving
//           }

//           drawCar(newPosition, newYOffset);
//           return newPosition;
//         });

//         animationFrame = requestAnimationFrame(animateCar);
//       };

//       if (animationRunning) {
//         animationFrame = requestAnimationFrame(animateCar);
//       } else {
//         cancelAnimationFrame(animationFrame);
//       }

//       return () => cancelAnimationFrame(animationFrame);
//     };

//     window.addEventListener('resize', updateCanvasSize);
//     updateCanvasSize();

//     return () => window.removeEventListener('resize', updateCanvasSize);
//   }, [animationRunning, carYOffset]);

//   return (
//     <div className="car-animation-container">
//       <h2>Car Animation with Reward System</h2>
//       <canvas ref={canvasRef}></canvas>

//       <Button
//         variant="contained"
//         onClick={() => setAnimationRunning(!animationRunning)}
//         style={{ marginTop: '20px' }}
//       >
//         {animationRunning ? 'Stop' : 'Start'} Animation
//       </Button>

//       <Button
//         variant="outlined"
//         onClick={() => {
//           setCarPosition(0);
//           setCarYOffset(0);
//           setReward(0);
//         }}
//         style={{ marginTop: '10px', marginLeft: '10px' }}
//       >
//         Restart Animation
//       </Button>

//       <div style={{ marginTop: '20px' }}>
//         <h3>Current Reward: {reward}</h3>
//         <div>
//           <Button
//             variant="outlined"
//             onClick={() => setExplanationOpen(!explanationOpen)}
//           >
//             {explanationOpen ? 'Hide' : 'Show'} Explanation
//           </Button>
//           {explanationOpen && (
//             <div className="reward-explanation" style={{ marginTop: '10px' }}>
//               <p>
//                 This car is being trained to stay on the lane. Every time the
//                 car stays on the lane, it receives a positive reward. When the
//                 car swerves off the lane, it receives a negative reward (punishment).
//               </p>
//               <p>
//                 The goal is for the agent to learn to stay on track as much as
//                 possible by accumulating positive rewards.
//               </p>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CarAnimation;
// import React, { useEffect, useRef, useState } from 'react';
// import * as d3 from 'd3';
// import Button from '@mui/material/Button';
// import './CarAnimation.css';

// const CarAnimation = () => {
//   const canvasRef = useRef();
//   const [animationRunning, setAnimationRunning] = useState(false);
//   const [carPosition, setCarPosition] = useState(0);
//   const [carYOffset, setCarYOffset] = useState(0);
//   const [reward, setReward] = useState(0);
//   const [explanationOpen, setExplanationOpen] = useState(false);
//   const [obstacles, setObstacles] = useState([]);

//   useEffect(() => {
//     const updateCanvasSize = () => {
//       const canvas = canvasRef.current;
//       const width = canvas.parentElement.offsetWidth;
//       const height = 300;

//       d3.select(canvas).attr('width', width).attr('height', height);

//       const context = canvas.getContext('2d');

//       const drawTrack = () => {
//         context.fillStyle = '#88c066'; // Grass color
//         context.fillRect(0, 0, width, height);

//         // Draw the road (track)
//         context.fillStyle = '#A9A9A9'; // Road color
//         const roadWidth = 200;
//         const roadHeight = 100;
//         const roadX = (width - roadWidth) / 2;
//         context.fillRect(roadX, height - roadHeight, roadWidth, roadHeight);

//         // Road lanes
//         context.strokeStyle = '#FFFFFF'; // Lane markings
//         context.lineWidth = 2;
//         context.beginPath();
//         context.moveTo(roadX + 50, height - roadHeight);
//         context.lineTo(roadX + 50, height);
//         context.stroke();
//         context.beginPath();
//         context.moveTo(roadX + 150, height - roadHeight);
//         context.lineTo(roadX + 150, height);
//         context.stroke();
//       };

//       const drawObstacles = () => {
//         context.fillStyle = 'brown'; // Color for obstacles (e.g., rocks)
//         obstacles.forEach((obstacle) => {
//           context.fillRect(obstacle.x, height - 120, 20, 20); // Draw square obstacles
//         });
//       };

//       const drawCar = (x, yOffset) => {
//         drawTrack();
//         drawObstacles();

//         // Car body
//         const carColor = 'blue';
//         context.fillStyle = carColor;
//         context.fillRect(x, height - 80 + yOffset, 60, 30);

//         // Wheels
//         context.fillStyle = 'black';
//         context.beginPath();
//         context.arc(x + 15, height - 50 + yOffset, 8, 0, 2 * Math.PI);
//         context.arc(x + 45, height - 50 + yOffset, 8, 0, 2 * Math.PI);
//         context.fill();
//       };

//       let animationFrame;
//       const animateCar = () => {
//         setCarPosition((prev) => {
//           const newPosition = (prev + 1) % width;
//           const newYOffset = Math.random() < 0.05 ? Math.random() * 40 - 20 : carYOffset;
//           setCarYOffset(newYOffset);

//           const isColliding = obstacles.some((obstacle) => {
//             return Math.abs(newPosition - obstacle.x) < 25 && Math.abs(newYOffset) < 10;
//           });

//           if (isColliding) {
//             setReward((prevReward) => prevReward - 10);
//           } else if (Math.abs(newYOffset) < 5) {
//             setReward((prevReward) => prevReward + 1);
//           } else {
//             setReward((prevReward) => prevReward - 1);
//           }

//           drawCar(newPosition, newYOffset);
//           return newPosition;
//         });

//         animationFrame = requestAnimationFrame(animateCar);
//       };

//       if (animationRunning) {
//         animationFrame = requestAnimationFrame(animateCar);
//       } else {
//         cancelAnimationFrame(animationFrame);
//       }

//       return () => cancelAnimationFrame(animationFrame);
//     };

//     window.addEventListener('resize', updateCanvasSize);
//     updateCanvasSize();

//     return () => window.removeEventListener('resize', updateCanvasSize);
//   }, [animationRunning, carYOffset, obstacles]);

//   useEffect(() => {
//     if (animationRunning) {
//       const generateObstacles = () => {
//         const newObstacles = [];
//         for (let i = 0; i < 5; i++) {
//           newObstacles.push({ x: Math.random() * 500 + 100 });
//         }
//         setObstacles(newObstacles);
//       };

//       generateObstacles();
//     }
//   }, [animationRunning]);

//   return (
//     <div className="car-animation-container">
//       <h2>Car Animation with Grass and Obstacles</h2>
//       <canvas ref={canvasRef}></canvas>

//       <Button
//         variant="contained"
//         onClick={() => setAnimationRunning(!animationRunning)}
//         style={{ marginTop: '20px' }}
//       >
//         {animationRunning ? 'Stop' : 'Start'} Animation
//       </Button>

//       <Button
//         variant="outlined"
//         onClick={() => {
//           setCarPosition(0);
//           setCarYOffset(0);
//           setReward(0);
//         }}
//         style={{ marginTop: '10px', marginLeft: '10px' }}
//       >
//         Restart Animation
//       </Button>

//       <div style={{ marginTop: '20px' }}>
//         <h3>Current Reward: {reward}</h3>
//         <div>
//           <Button
//             variant="outlined"
//             onClick={() => setExplanationOpen(!explanationOpen)}
//           >
//             {explanationOpen ? 'Hide' : 'Show'} Explanation
//           </Button>
//           {explanationOpen && (
//             <div className="reward-explanation" style={{ marginTop: '10px' }}>
//               <p>
//                 The car must stay on the road and avoid obstacles (brown boxes). Positive rewards are gained for staying on track, while negative rewards (penalties) are given for swerving or hitting obstacles.
//               </p>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CarAnimation;



// import React, { useEffect, useRef, useState } from 'react';
// import Button from '@mui/material/Button';
// import './CarAnimation.css';

// const CarAnimation = () => {
//   const canvasRef = useRef();
//   const [animationRunning, setAnimationRunning] = useState(false);
//   const [carPosition, setCarPosition] = useState(0);
//   const [carYOffset, setCarYOffset] = useState(0);
//   const [reward, setReward] = useState(0);
//   const [explanationOpen, setExplanationOpen] = useState(false);
//   const [obstacles, setObstacles] = useState([]);

//   useEffect(() => {
//     const canvas = canvasRef.current;
//     const width = canvas.parentElement.offsetWidth;
//     const height = 300;
//     canvas.width = width;
//     canvas.height = height;

//     const context = canvas.getContext('2d');

//     const drawTrack = () => {
//       // Clear the canvas
//       context.clearRect(0, 0, width, height);

//       // Draw the grass background
//       const grassPattern = context.createPattern(document.getElementById('grassPattern'), 'repeat');
//       context.fillStyle = grassPattern;
//       context.fillRect(0, 0, width, height);

//       // Draw the road (track)
//       context.fillStyle = '#A9A9A9'; // Road color
//       const roadWidth = 200;
//       const roadHeight = 100;
//       const roadX = (width - roadWidth) / 2;
//       context.fillRect(roadX, height - roadHeight, roadWidth, roadHeight);

//       // Draw the lane markings
//       context.strokeStyle = '#FFFFFF'; // Lane markings
//       context.lineWidth = 2;
//       context.beginPath();
//       context.moveTo(roadX + 50, height - roadHeight);
//       context.lineTo(roadX + 50, height);
//       context.stroke();
//       context.beginPath();
//       context.moveTo(roadX + 150, height - roadHeight);
//       context.lineTo(roadX + 150, height);
//       context.stroke();
//     };

//     const drawObstacles = () => {
//       context.fillStyle = 'brown'; // Color for obstacles (e.g., rocks)
//       obstacles.forEach((obstacle) => {
//         context.fillRect(obstacle.x, height - 120, 20, 20); // Draw square obstacles
//       });
//     };

//     const drawCar = (x, yOffset) => {
//       drawTrack();
//       drawObstacles();

//       // Car body
//       const carColor = 'blue';
//       context.fillStyle = carColor;
//       context.fillRect(x, height - 80 + yOffset, 60, 30);

//       // Wheels
//       context.fillStyle = 'black';
//       context.beginPath();
//       context.arc(x + 15, height - 50 + yOffset, 8, 0, 2 * Math.PI);
//       context.arc(x + 45, height - 50 + yOffset, 8, 0, 2 * Math.PI);
//       context.fill();
//     };

//     let animationFrame;
//     const animateCar = () => {
//       setCarPosition((prev) => {
//         const newPosition = (prev + 1) % width;
//         const newYOffset = Math.random() < 0.05 ? Math.random() * 40 - 20 : carYOffset;
//         setCarYOffset(newYOffset);

//         const isColliding = obstacles.some((obstacle) => {
//           return Math.abs(newPosition - obstacle.x) < 25 && Math.abs(newYOffset) < 10;
//         });

//         if (isColliding) {
//           setReward((prevReward) => prevReward - 10);
//         } else if (Math.abs(newYOffset) < 5) {
//           setReward((prevReward) => prevReward + 1);
//         } else {
//           setReward((prevReward) => prevReward - 1);
//         }

//         drawCar(newPosition, newYOffset);
//         return newPosition;
//       });

//       animationFrame = requestAnimationFrame(animateCar);
//     };

//     if (animationRunning) {
//       animationFrame = requestAnimationFrame(animateCar);
//     } else {
//       cancelAnimationFrame(animationFrame);
//     }

//     return () => cancelAnimationFrame(animationFrame);
//   }, [animationRunning, carYOffset, obstacles]);

//   useEffect(() => {
//     if (animationRunning) {
//       const generateObstacles = () => {
//         const newObstacles = [];
//         for (let i = 0; i < 5; i++) {
//           newObstacles.push({ x: Math.random() * 500 + 100 });
//         }
//         setObstacles(newObstacles);
//       };

//       generateObstacles();
//     }
//   }, [animationRunning]);

//   return (
//     <div className="car-animation-container">
//       <h2>Car Animation with Grass and Obstacles</h2>
//       <canvas ref={canvasRef}></canvas>

//       <Button
//         variant="contained"
//         onClick={() => setAnimationRunning(!animationRunning)}
//         style={{ marginTop: '20px' }}
//       >
//         {animationRunning ? 'Stop' : 'Start'} Animation
//       </Button>

//       <Button
//         variant="outlined"
//         onClick={() => {
//           setCarPosition(0);
//           setCarYOffset(0);
//           setReward(0);
//         }}
//         style={{ marginTop: '10px', marginLeft: '10px' }}
//       >
//         Restart Animation
//       </Button>

//       <div style={{ marginTop: '20px' }}>
//         <h3>Current Reward: {reward}</h3>
//         <div>
//           <Button
//             variant="outlined"
//             onClick={() => setExplanationOpen(!explanationOpen)}
//           >
//             {explanationOpen ? 'Hide' : 'Show'} Explanation
//           </Button>
//           {explanationOpen && (
//             <div className="reward-explanation" style={{ marginTop: '10px' }}>
//               <p>
//                 The car must stay on the road and avoid obstacles (brown boxes). Positive rewards are gained for staying on track, while negative rewards (penalties) are given for swerving or hitting obstacles.
//               </p>
//             </div>
//           )}
//         </div>
//       </div>

//       <canvas id="grassPattern" style={{ display: 'none' }}></canvas>
//     </div>
//   );
// };

// export default CarAnimation;

//Text animation no 2

// import React, { useEffect, useRef, useState } from 'react';
// import Button from '@mui/material/Button';
// import './CarAnimation.css';

// const CarAnimation = () => {
//   const canvasRef = useRef();
//   const [animationRunning, setAnimationRunning] = useState(false);
//   const [carPosition, setCarPosition] = useState(0);
//   const [carYOffset, setCarYOffset] = useState(0);
//   const [reward, setReward] = useState(0);
//   const [explanationOpen, setExplanationOpen] = useState(false);
//   const [obstacles, setObstacles] = useState([]);

//   useEffect(() => {
//     const canvas = canvasRef.current;
//     const width = canvas.parentElement.offsetWidth;
//     const height = 300;
//     canvas.width = width;
//     canvas.height = height;

//     const context = canvas.getContext('2d');

//     const drawGrassBackground = () => {
//       const grassPattern = context.createPattern(document.getElementById('grassPattern'), 'repeat');
//       context.fillStyle = grassPattern;
//       context.fillRect(0, 0, width, height);
//     };

//     const drawTrack = () => {
//       context.fillStyle = '#A9A9A9'; // Road color
//       const roadWidth = 200;
//       const roadHeight = 100;
//       const roadX = (width - roadWidth) / 2;
//       context.fillRect(roadX, height - roadHeight, roadWidth, roadHeight);

//       // Draw lane markings
//       context.strokeStyle = '#FFFFFF'; // Lane markings
//       context.lineWidth = 2;
//       context.beginPath();
//       context.moveTo(roadX + 50, height - roadHeight);
//       context.lineTo(roadX + 50, height);
//       context.stroke();
//       context.beginPath();
//       context.moveTo(roadX + 150, height - roadHeight);
//       context.lineTo(roadX + 150, height);
//       context.stroke();
//     };

//     const drawObstacles = () => {
//       context.fillStyle = 'brown'; // Color for obstacles (e.g., rocks)
//       obstacles.forEach((obstacle) => {
//         context.fillRect(obstacle.x, height - 120, 20, 20); // Draw square obstacles
//       });
//     };

//     const drawCar = (x, yOffset) => {
//       // Clear only the car and track area
//       context.clearRect(0, height - 80, width, 80);

//       // Draw the car
//       context.fillStyle = 'blue';
//       context.fillRect(x, height - 80 + yOffset, 60, 30);

//       // Draw wheels
//       context.fillStyle = 'black';
//       context.beginPath();
//       context.arc(x + 15, height - 50 + yOffset, 8, 0, 2 * Math.PI);
//       context.arc(x + 45, height - 50 + yOffset, 8, 0, 2 * Math.PI);
//       context.fill();
//     };

//     let animationFrame;
//     const animateCar = () => {
//       setCarPosition((prev) => {
//         const newPosition = (prev + 1) % width;
//         const newYOffset = Math.random() < 0.05 ? Math.random() * 40 - 20 : carYOffset;
//         setCarYOffset(newYOffset);

//         const isColliding = obstacles.some((obstacle) => {
//           return Math.abs(newPosition - obstacle.x) < 25 && Math.abs(newYOffset) < 10;
//         });

//         if (isColliding) {
//           setReward((prevReward) => prevReward - 10);
//         } else if (Math.abs(newYOffset) < 5) {
//           setReward((prevReward) => prevReward + 1);
//         } else {
//           setReward((prevReward) => prevReward - 1);
//         }

//         drawCar(newPosition, newYOffset);
//         return newPosition;
//       });

//       animationFrame = requestAnimationFrame(animateCar);
//     };

//     if (animationRunning) {
//       drawGrassBackground();
//       drawTrack();
//       drawObstacles();
//       animationFrame = requestAnimationFrame(animateCar);
//     } else {
//       cancelAnimationFrame(animationFrame);
//     }

//     return () => cancelAnimationFrame(animationFrame);
//   }, [animationRunning, carYOffset, obstacles]);

//   useEffect(() => {
//     if (animationRunning) {
//       const generateObstacles = () => {
//         const newObstacles = [];
//         for (let i = 0; i < 5; i++) {
//           newObstacles.push({ x: Math.random() * 500 + 100 });
//         }
//         setObstacles(newObstacles);
//       };

//       generateObstacles();
//     }
//   }, [animationRunning]);

//   return (
//     <div className="car-animation-container">
//       <h2>Car Animation with Grass and Obstacles</h2>
//       <canvas ref={canvasRef}></canvas>

//       <Button
//         variant="contained"
//         onClick={() => setAnimationRunning(!animationRunning)}
//         style={{ marginTop: '20px' }}
//       >
//         {animationRunning ? 'Stop' : 'Start'} Animation
//       </Button>

//       <Button
//         variant="outlined"
//         onClick={() => {
//           setCarPosition(0);
//           setCarYOffset(0);
//           setReward(0);
//         }}
//         style={{ marginTop: '10px', marginLeft: '10px' }}
//       >
//         Restart Animation
//       </Button>

//       <div style={{ marginTop: '20px' }}>
//         <h3>Current Reward: {reward}</h3>
//         <div>
//           <Button
//             variant="outlined"
//             onClick={() => setExplanationOpen(!explanationOpen)}
//           >
//             {explanationOpen ? 'Hide' : 'Show'} Explanation
//           </Button>
//           {explanationOpen && (
//             <div className="reward-explanation" style={{ marginTop: '10px' }}>
//               <p>
//                 The car must stay on the road and avoid obstacles (brown boxes). Positive rewards are gained for staying on track, while negative rewards (penalties) are given for swerving or hitting obstacles.
//               </p>
//             </div>
//           )}
//         </div>
//       </div>

//       <canvas id="grassPattern" style={{ display: 'none' }}></canvas>
//     </div>
//   );
// };

// export default CarAnimation;


// import React, { useEffect, useRef, useState } from 'react';
// import Button from '@mui/material/Button';
// import './CarAnimation.css';

// const CarAnimation = () => {
//   const backgroundCanvasRef = useRef();
//   const animationCanvasRef = useRef();
//   const [animationRunning, setAnimationRunning] = useState(false);
//   const [carPosition, setCarPosition] = useState(0);
//   const [carYOffset, setCarYOffset] = useState(0);
//   const [reward, setReward] = useState(0);
//   const [explanationOpen, setExplanationOpen] = useState(false);
//   const [obstacles, setObstacles] = useState([]);

//   useEffect(() => {
//     const backgroundCanvas = backgroundCanvasRef.current;
//     const animationCanvas = animationCanvasRef.current;
//     const width = animationCanvas.parentElement.offsetWidth;
//     const height = 300;
//     backgroundCanvas.width = width;
//     backgroundCanvas.height = height;
//     animationCanvas.width = width;
//     animationCanvas.height = height;

//     const backgroundContext = backgroundCanvas.getContext('2d');
//     const animationContext = animationCanvas.getContext('2d');

//     // Draw grass background
//     const drawGrassBackground = () => {
//       const grassPattern = backgroundContext.createPattern(document.getElementById('grassPattern'), 'repeat');
//       backgroundContext.fillStyle = grassPattern;
//       backgroundContext.fillRect(0, 0, width, height);
//     };

//     // Draw track on the background canvas
//     const drawTrack = () => {
//       backgroundContext.fillStyle = '#A9A9A9'; // Road color
//       const roadWidth = 200;
//       const roadHeight = 100;
//       const roadX = (width - roadWidth) / 2;
//       backgroundContext.fillRect(roadX, height - roadHeight, roadWidth, roadHeight);

//       // Draw lane markings
//       backgroundContext.strokeStyle = '#FFFFFF'; // Lane markings
//       backgroundContext.lineWidth = 2;
//       backgroundContext.setLineDash([10, 15]); // Dashed lane markings
//       backgroundContext.beginPath();
//       backgroundContext.moveTo(roadX + 50, height - roadHeight);
//       backgroundContext.lineTo(roadX + 50, height);
//       backgroundContext.stroke();
//       backgroundContext.beginPath();
//       backgroundContext.moveTo(roadX + 150, height - roadHeight);
//       backgroundContext.lineTo(roadX + 150, height);
//       backgroundContext.stroke();
//       backgroundContext.setLineDash([]); // Reset to solid lines
//     };

//     const drawObstacles = () => {
//       animationContext.fillStyle = 'brown'; // Color for obstacles (e.g., rocks)
//       obstacles.forEach((obstacle) => {
//         animationContext.fillRect(obstacle.x, height - 120, 20, 20); // Draw square obstacles
//       });
//     };

//     const drawCar = (x, yOffset) => {
//       // Clear only the car area on the animation canvas
//       animationContext.clearRect(0, height - 80, width, 80);

//       // Draw the car
//       animationContext.fillStyle = 'blue';
//       animationContext.fillRect(x, height - 80 + yOffset, 60, 30);

//       // Draw wheels
//       animationContext.fillStyle = 'black';
//       animationContext.beginPath();
//       animationContext.arc(x + 15, height - 50 + yOffset, 8, 0, 2 * Math.PI);
//       animationContext.arc(x + 45, height - 50 + yOffset, 8, 0, 2 * Math.PI);
//       animationContext.fill();
//     };

//     let animationFrame;
//     const animateCar = () => {
//       setCarPosition((prev) => {
//         const newPosition = (prev + 1) % width;
//         const newYOffset = Math.random() < 0.05 ? Math.random() * 40 - 20 : carYOffset;
//         setCarYOffset(newYOffset);

//         const isColliding = obstacles.some((obstacle) => {
//           return Math.abs(newPosition - obstacle.x) < 25 && Math.abs(newYOffset) < 10;
//         });

//         if (isColliding) {
//           setReward((prevReward) => prevReward - 10);
//         } else if (Math.abs(newYOffset) < 5) {
//           setReward((prevReward) => prevReward + 1);
//         } else {
//           setReward((prevReward) => prevReward - 1);
//         }

//         drawCar(newPosition, newYOffset);
//         return newPosition;
//       });

//       animationFrame = requestAnimationFrame(animateCar);
//     };

//     if (animationRunning) {
//       drawGrassBackground();
//       drawTrack();
//       drawObstacles();
//       animationFrame = requestAnimationFrame(animateCar);
//     } else {
//       cancelAnimationFrame(animationFrame);
//     }

//     return () => cancelAnimationFrame(animationFrame);
//   }, [animationRunning, carYOffset, obstacles]);

//   useEffect(() => {
//     if (animationRunning) {
//       const generateObstacles = () => {
//         const newObstacles = [];
//         for (let i = 0; i < 5; i++) {
//           newObstacles.push({ x: Math.random() * (animationCanvasRef.current.width - 20) });
//         }
//         setObstacles(newObstacles);
//       };

//       generateObstacles();
//     }
//   }, [animationRunning]);

//   return (
//     <div className="car-animation-container">
//       <h2>Car Animation with Grass and Obstacles</h2>
//       <canvas ref={backgroundCanvasRef} style={{ position: 'absolute', top: 0, left: 0 }}></canvas>
//       <canvas ref={animationCanvasRef} style={{ position: 'absolute', top: 0, left: 0 }}></canvas>

//       <Button
//         variant="contained"
//         onClick={() => setAnimationRunning(!animationRunning)}
//         style={{ marginTop: '20px' }}
//       >
//         {animationRunning ? 'Stop' : 'Start'} Animation
//       </Button>

//       <Button
//         variant="outlined"
//         onClick={() => {
//           setCarPosition(0);
//           setCarYOffset(0);
//           setReward(0);
//         }}
//         style={{ marginTop: '10px', marginLeft: '10px' }}
//       >
//         Restart Animation
//       </Button>

//       <div style={{ marginTop: '20px' }}>
//         <h3>Current Reward: {reward}</h3>
//         <div>
//           <Button
//             variant="outlined"
//             onClick={() => setExplanationOpen(!explanationOpen)}
//           >
//             {explanationOpen ? 'Hide' : 'Show'} Explanation
//           </Button>
//           {explanationOpen && (
//             <div className="reward-explanation" style={{ marginTop: '10px' }}>
//               <p>
//                 The car must stay on the road and avoid obstacles (brown boxes). Positive rewards are gained for staying on track, while negative rewards (penalties) are given for swerving or hitting obstacles.
//               </p>
//             </div>
//           )}
//         </div>
//       </div>

//       <canvas id="grassPattern" style={{ display: 'none' }}></canvas>
//     </div>
//   );
// };

// export default CarAnimation;

// import React, { useEffect, useRef, useState } from 'react';
// import Button from '@mui/material/Button';
// import './CarAnimation.css'; // Optional CSS for styling

// const CarAnimation = () => {
//   const canvasRef = useRef();
//   const [animationRunning, setAnimationRunning] = useState(false);
//   const [carPosition, setCarPosition] = useState(0);
//   const [carDirection, setCarDirection] = useState(0); // New state for direction

//   useEffect(() => {
//     const width = 600;
//     const height = 300; // Increased height for better visibility

//     const canvas = canvasRef.current;
//     canvas.width = width;
//     canvas.height = height;

//     const context = canvas.getContext('2d');

//     // Draw the background (grass)
//     const drawBackground = () => {
//       const img = new Image();
//       img.src = 'grass-texture.jpg'; // Path to your grass texture image
//       img.onload = () => {
//         context.drawImage(img, 0, 0, width, height);
//       };
//     };

//     // Function to draw the car
//     const drawCar = (x, y) => {
//       context.clearRect(0, 0, width, height); // Clear the canvas
//       drawBackground(); // Redraw the background

//       context.fillStyle = 'blue';
//       context.fillRect(x, y, 60, 30); // Draw the car body

//       // Draw wheels
//       context.fillStyle = 'black';
//       context.beginPath();
//       context.arc(x + 15, y + 25, 8, 0, 2 * Math.PI); // Larger wheels
//       context.arc(x + 45, y + 25, 8, 0, 2 * Math.PI);
//       context.fill();
//     };

//     let animationFrame;
//     const animateCar = () => {
//       setCarPosition((prev) => {
//         const newPosition = (prev + 2) % (width - 60); // Move the car across the screen
//         drawCar(newPosition, height / 2); // Position car vertically in the middle
//         return newPosition;
//       });
//       animationFrame = requestAnimationFrame(animateCar);
//     };

//     if (animationRunning) {
//       animationFrame = requestAnimationFrame(animateCar);
//     } else {
//       cancelAnimationFrame(animationFrame);
//     }

//     return () => cancelAnimationFrame(animationFrame);
//   }, [animationRunning]);

//   return (
//     <div className="car-animation-container">
//       {/* <h2>Car Animation with Grass Background</h2> */}
//       <canvas ref={canvasRef}></canvas>
//       <Button 
//         variant="contained" 
//         onClick={() => setAnimationRunning(!animationRunning)}
//         style={{ marginTop: '20px' }}
//       >
//         {animationRunning ? 'Stop' : 'Start'} Animation
//       </Button>
//     </div>
//   );
// };

// export default CarAnimation;


import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import './CarAnimation.css'; // Ensure this file includes styling for the car and canvas

const CarAnimation = () => {
  const canvasRef = useRef();
  const [animationRunning, setAnimationRunning] = useState(false);
  const [carPosition, setCarPosition] = useState(0);

  useEffect(() => {
    const width = 800;
    const height = 400;

    const canvas = canvasRef.current;
    canvas.width = width;
    canvas.height = height;

    const context = canvas.getContext('2d');

    // Draw the background (grass)
    const drawBackground = () => {
      const img = new Image();
      img.src = 'grass-texture.jpg'; // Path to your grass texture image
      img.onload = () => {
        context.drawImage(img, 0, 0, width, height);
      };
    };

    // Function to draw the futuristic car
    const drawCar = (x) => {
      context.clearRect(0, 0, width, height); // Clear the canvas
      drawBackground(); // Redraw the background

      // Draw the car body
      context.fillStyle = '#00bfff'; // Bright futuristic blue
      context.beginPath();
      context.moveTo(x, height / 2 + 20); // Bottom left
      context.lineTo(x + 100, height / 2 + 20); // Bottom right
      context.lineTo(x + 80, height / 2 - 30); // Top right
      context.lineTo(x + 20, height / 2 - 30); // Top left
      context.closePath();
      context.fill();
      
      // Draw car windows
      context.fillStyle = '#1e90ff'; // Lighter blue for windows
      context.beginPath();
      context.moveTo(x + 20, height / 2 - 30);
      context.lineTo(x + 40, height / 2 - 60); // Windows
      context.lineTo(x + 60, height / 2 - 30);
      context.closePath();
      context.fill();
      
      // Draw car wheels
      context.fillStyle = 'black';
      context.beginPath();
      context.arc(x + 30, height / 2 + 30, 15, 0, 2 * Math.PI); // Bigger wheels
      context.arc(x + 70, height / 2 + 30, 15, 0, 2 * Math.PI);
      context.fill();
      
      // Draw the portfolio text being dragged
      context.font = 'bold 24px Arial';
      context.fillStyle = 'white';
      context.fillText('OlaFolio Portfolio', x + 40, height / 2 + 60); // Adjust position as needed
    };

    let animationFrame;
    const animateCar = () => {
      setCarPosition((prev) => {
        const newPosition = (prev + 4) % (width - 100); // Move the car across the screen
        drawCar(newPosition);
        return newPosition;
      });
      animationFrame = requestAnimationFrame(animateCar);
    };

    if (animationRunning) {
      animationFrame = requestAnimationFrame(animateCar);
    } else {
      cancelAnimationFrame(animationFrame);
    }

    return () => cancelAnimationFrame(animationFrame);
  }, [animationRunning]);

  return (
    <div className="car-animation-container">
      <h2>Futuristic Car Animation with Portfolio</h2>
      <canvas ref={canvasRef}></canvas>
      <Button 
        variant="contained" 
        onClick={() => setAnimationRunning(!animationRunning)}
        style={{ marginTop: '20px' }}
      >
        {animationRunning ? 'Stop' : 'Start'} Animation
      </Button>
    </div>
  );
};

export default CarAnimation;

