import React from 'react';
import OlaF1 from "../assets/OlaF1.png";
import Button from '@mui/material/Button';

function OlaFruits() {
  return (
    <div className='container'>
      <h2 className="heading">OlaFolio Fruits</h2>
        <form className='form'>
       
            <h2>Project details</h2>
            <h3>Project Name: OlaFolio Fruits</h3>
            <h3>Project Type: Web Development</h3>
            <h3>Project Duration: 4 weeks</h3>
            <h3>Tools: HTML, CSS, JavaScript, React, Material-UI</h3>
            <h3>Project Description: A simple web application that displays a list of fruits and their health benefits.</h3>
            <h3>Project Features: The application allows users to add fruits to a cart and view the total price of the fruits in the cart.</h3>
            <div>
            <h2>Project Screenshots</h2>
            <img src={OlaF1} alt="pfp" width="200px"></img>
            </div>
            
            <h3>Project Link: </h3>
            <Button variant="outlined" href="https://olafolio-fruits.netlify.app/" target="_blank">OlaFolio Fruits</Button>

        </form>
    </div>
  )
}

export default OlaFruits