import React from 'react'
import Button from '@mui/material/Button';
import ahead3 from "../assets/ahead3.png";
import netlify from "../assets/netlify.mp4";
import last from "../assets/last.mp4";
import ui from "../assets/ui.mp4";
import Azure from "../assets/Azure.mp4";
// import aws from "../assets/aws.MP4";
import ahead from "../assets/ahead.MP4";
import ai_confg from "../assets/ai_confg.MP4";
import python from "../assets/python.MP4";
import ahead77 from "../assets/ahead77.MP4";
import game from "../assets/game.MP4";
import ahead33 from "../assets/ahead33.MP4";
import lastmile from "../assets/lastmile.MP4";
import ai_dev from "../assets/ai_dev.MP4";
import portfolio from "../assets/portfolio.MP4";
import pawster2 from "../assets/pawster2.png";
import rise from "../assets/rise.png";
import HTCSS from './HTCSS';
import React_Tutorial from './React_Tuorial';
function Tutorials() {
  return (
    <div>
    <h2>My Tutorial videos</h2>
    
    <div className="intro2">
   
    <div className="port-2">
          <iframe className="pfp1" alt="pfp" src={portfolio} width="100%" height="350px"  autoplay="0" muted></iframe>
        <br/>
          <h2>Description: Olafolio</h2>
        </div>
        <div className="port-2">
        <iframe className="pfp1" alt="pfp" src={ahead33} width="100%" height="350px"  autoplay="0" muted></iframe>
        <br/>
          <h2>Description: Ahead-Store</h2>
        </div>
        <div className="port-3">
        <iframe className="pfp1" alt="pfp" src={ai_dev} width="100%" height="350px"  autoplay="0" muted></iframe>
        <br/>
          <h2>Description: React Set up</h2>
        </div>
        <div className="port-2">
        <iframe className="pfp1" alt="pfp" src={netlify} width="100%" height="350px"  autoplay="0" muted></iframe>
        <br/>
          <h2>Description: Netlify Tutorial</h2>
        </div>
        {/* <div className="port-3">
        <iframe className="pfp1" alt="pfp" src={game} width="100%" height="350px"  autoplay="0" muted></iframe>
        <br/>
          <h2>Description: Flexbox Game</h2>
        </div> */}
        <div className="port-2">
        <iframe className="pfp1" alt="pfp" src={lastmile} width="100%" height="350px"  autoplay="0" muted></iframe>
        <br/>
          <h2>Description: Lastmile_ai</h2>
        </div>
        <div className="port-2">
        <iframe className="pfp1" alt="pfp" src={ahead77} width="100%" height="350px"  autoplay="0" muted></iframe>
        <br/>
          <h2>Description: E-commerce Website</h2>
        </div>
        <div className="port-2">
        <iframe className="pfp1" alt="pfp" src={ai_confg} width="100%" height="350px"  autoplay="0" muted></iframe>
        <br/>
          <h2>Description: Ai_Config Extension</h2>
        </div>
        <div className="port-2">
        <iframe className="pfp1" alt="pfp" src={python} width="100%" height="350px"  autoplay="0" muted></iframe>
        <br/>
          <h2>Description:Python Sketch</h2>
        </div>
       
          </div>
   {/* <HTCSS /> */}
   {/* <React_Tutorial /> */}
    </div>
  )
}

export default Tutorials