import React from 'react'

function Podcast() {
  return (
    <div>
    <h2>OlaFolio The AI Explorer
    <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  </h2>
    <form >
    <div className='intro2'>
    <div className='port-2'>
    <p>Listen to the latest episode of The AI Explorer on Spotify. </p>

    <iframe className="pfp1" src="https://podcasters.spotify.com/pod/show/olayinka-fakanbi/embed" height="750px" width="100%" frameborder="0" scrolling="no"></iframe>
    </div>
 
    </div>
    
    </form>
    </div>
  )
}

export default Podcast;