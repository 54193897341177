import React from 'react'
import quantum from '../assets/quantum.jpg'
// import qvid from '../assets/qvid.mp4'
import Button from '@mui/material/Button';

function Quantum() {
  return (
    <div className="container">
    <h2 className="heading">Quantum Computing</h2>
    <form className="form">
    <h2>As we embark on a journey into the fascinating world of quantum computing-a frontier that holds immense promise for reshaping the landscape of technology as we know it. Let's delve into the intricacies of quantum mechanics, explore the practical applications of quantum computing, and examine the challenges and opportunities that lie ahead in this groundbreaking field.</h2>
<img src={quantum} alt="Quantum Computing" className="pfp1 animate__backInDown" />
    <h2>Quantum computing is a revolutionary paradigm that leverages the principles of quantum mechanics to perform complex computations at speeds that far surpass those of classical computers. Unlike classical computers, which rely on bits to store and process information, quantum computers use quantum bits, or qubits, to encode and manipulate data. This enables quantum computers to perform calculations in parallel, leading to exponential speedups in solving certain problems.</h2>
    {/* <iframe className="pfp1" alt="pfp" src={qvid} autoplay="false" width="100%" height="400px"></iframe> */}
    <h2>One of the most promising applications of quantum computing is in the field of cryptography. Quantum computers have the potential to break many of the cryptographic algorithms that currently secure our digital communications and transactions. At the same time, quantum computing offers new cryptographic techniques that can enhance the security of our data and communications in the quantum era.</h2>
    <h2>Quantum computing also holds great promise for accelerating scientific research and discovery. Quantum computers can simulate complex quantum systems, such as chemical reactions and materials properties, with unprecedented accuracy and efficiency. This opens up new possibilities for designing novel materials, drugs, and technologies that could revolutionize industries ranging from healthcare to energy.</h2>
    <h2>Despite its immense potential, quantum computing faces several challenges that must be overcome to realize its full impact. One of the key challenges is the development of error-correcting codes that can protect quantum information from errors caused by noise and decoherence. Another challenge is the scalability of quantum computers, which requires increasing the number of qubits and improving their coherence and connectivity.</h2>
    <Button variant="outlined" href="/" target="_blank">
 Back
</Button>
    </form>
    
    </div>
  )
}

export default Quantum