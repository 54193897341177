// 

//With transition

import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import './Bubble.css';

const Insertion = () => {
  const chartRef = useRef();
  const [data, setData] = useState([30, 50, 35, 65, 70, 75, 80]);
  const [inputValue, setInputValue] = useState('');
  const [sortingCompleted, setSortingCompleted] = useState(false);
  const [showExplanation, setShowExplanation] = useState(false);
  const [transitionDuration, setTransitionDuration] = useState(500); // default to 500ms

  useEffect(() => {
    const margin = { top: 20, right: 20, bottom: 30, left: 40 },
          width = 300 - margin.left - margin.right,
          height = 400 - margin.top - margin.bottom;

    const svg = d3.select(chartRef.current)
      .html("")
      .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
      .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

    const x = d3.scaleBand()
      .domain(data.map((_, i) => i))
      .range([0, width])
      .padding(0.1);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data)])
      .nice()
      .range([height, 0]);

    const bars = svg.selectAll(".bar")
      .data(data)
      .enter().append("rect")
      .attr("class", "bar")
      .attr("x", (d, i) => x(i))
      .attr("y", d => y(d))
      .attr("width", x.bandwidth())
      .attr("height", d => height - y(d))
      .attr("fill", "#69b3a2");

    const updateChart = (newData) => {
      bars.data(newData)
        .transition()
        .duration(transitionDuration)
        .attr("x", (d, i) => x(i))
        .attr("y", d => y(d))
        .attr("height", d => height - y(d));
    };

    const insertionSort = async (arr) => {
      for (let i = 1; i < arr.length; i++) {
        let key = arr[i];
        let j = i - 1;
        while (j >= 0 && arr[j] > key) {
          arr[j + 1] = arr[j];
          j--;
          updateChart(arr);
          await new Promise(resolve => setTimeout(resolve, transitionDuration));
        }
        arr[j + 1] = key;
        updateChart(arr);
        await new Promise(resolve => setTimeout(resolve, transitionDuration));
      }
      setSortingCompleted(true);
    };

    if (data.length > 0) {
      insertionSort(data);
    }
  }, [data, transitionDuration]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSortClick = () => {
    const newData = inputValue.split(',').map(Number);
    setData(newData);
    setSortingCompleted(false);
  };

  const handleTransitionChange = (event, newValue) => {
    setTransitionDuration(newValue);
  };

  return (
    <div className='bubble-cont'>
      <h2>Insertion Sort Visualization</h2>
      <input 
        type="text" 
        value={inputValue} 
        onChange={handleInputChange} 
        placeholder="Enter numbers separated by commas" 
      />
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      ></Box>
      <button onClick={handleSortClick}>Sort</button>
      <div ref={chartRef}></div>
      <div style={{ marginTop: '20px' }}>
        <Typography gutterBottom>Transition Speed (ms)</Typography>
        <Slider
          value={transitionDuration}
          onChange={handleTransitionChange}
          min={100}
          max={2000}
          step={100}
          valueLabelDisplay="auto"
          aria-labelledby="transition-speed-slider"
          sx={{
            width: 300,
            margin: '0 auto',
          }}
        />
        <Typography variant="caption" color="textSecondary" align="center">
          {transitionDuration} ms
        </Typography>
      </div>
      {sortingCompleted && (
        <div>
          <button onClick={() => setShowExplanation(!showExplanation)}>
            {showExplanation ? 'Hide Explanation' : 'Show Explanation'}
          </button>
          {showExplanation && (
            <div className='bubble-show'>
            <h3>Insertion Sort Explanation</h3>
              <p>
                The Insertion Sort function sorts an array by building a sorted portion one element at a time.
                Here’s a detailed explanation of how it works:
              </p>
              <div>
                <strong>Initialization:</strong>
                <pre>{`for (let i = 1; i < arr.length; i++) {}`}</pre>
                <p>
                  <strong>Explanation:</strong> 
                  The loop starts at the second element (`i = 1`) because the first element is already considered sorted. 
                  The variable `i` represents the current position in the array that we are trying to place in the correct position.
                </p>
              </div>
              <div>
                <strong>Select Key:</strong>
                <pre>{`let key = arr[i];`}</pre>
                <p>
                  <strong>Explanation:</strong> 
                  The `key` is the element at the current position `i` that we are trying to insert into the correct position in the sorted portion of the array.
                </p>
              </div>
              <div>
                <strong>Find the Correct Position:</strong>
                <pre>{`let j = i - 1;
while (j >= 0 && arr[j] > key) {
  arr[j + 1] = arr[j];
  j--;
}`}</pre>
                <p>
                  <strong>Explanation:</strong> 
                  This loop shifts elements in the sorted portion to the right to make room for the `key`. It continues moving elements until it finds the correct position for the `key` where all elements before it are less than or equal to it.
                </p>
              </div>
              <div>
                <strong>Insert the Key:</strong>
                <pre>{`arr[j + 1] = key;`}</pre>
                <p>
                  <strong>Explanation:</strong> 
                  Once the correct position is found, the `key` is placed at `arr[j + 1]`, inserting it into the sorted portion of the array.
                </p>
              </div>
              <p>
                This process repeats for each element in the array until the entire array is sorted.
              </p>
              <h1>Geeks Solution</h1>
              <h3>Insertion Sort Explanation</h3>
<p>
  Insertion Sort is a simple comparison-based sorting algorithm that builds the final sorted array one item at a time. It is much like sorting playing cards in your hands. Below is the explanation using the provided Insertion Sort code:
</p>

<div>
  <strong>InsertionSort Function:</strong>
  <pre>{`function insertionSort(arr) {
    for (let i = 1; i < arr.length; i++) {
        let key = arr[i];
        let j = i - 1;

        /* Move elements of arr[0..i-1], that are
           greater than key, to one position ahead
           of their current position */
        while (j >= 0 && arr[j] > key) {
            arr[j + 1] = arr[j];
            j = j - 1;
        }
        arr[j + 1] = key;
    }
}`}</pre>
  <p>
    <strong>Explanation:</strong> 
    The `insertionSort` function iterates through the array starting from the second element. For each element, it compares it with the elements in the sorted portion of the array (to its left) and inserts it into its correct position. Elements greater than the current key are shifted one position to the right.
  </p>
</div>

<div>
  <strong>Print Array Function:</strong>
  <pre>{`function printArray(arr) {
    console.log(arr.join(" "));
}`}</pre>
  <p>
    <strong>Explanation:</strong> 
    The `printArray` function prints each element of the array separated by a space. It is used to display the sorted array after applying Insertion Sort.
  </p>
</div>

<div>
  <strong>Driver Code:</strong>
  <pre>{`let arr = [12, 11, 13, 5, 6];
insertionSort(arr);
printArray(arr);`}</pre>
  <p>
    <strong>Explanation:</strong> 
    This driver code initializes an unsorted array, sorts it using `insertionSort`, and prints the sorted array. It demonstrates the complete execution of the Insertion Sort algorithm.
  </p>
</div>

<p>
  Insertion Sort has a time complexity of O(n²) in the average and worst cases but performs well for small or nearly sorted arrays. It is simple to understand and implement, making it useful for educational purposes and small datasets.
</p>
<h1>Geeks Solution</h1>
<h3>Insertion Sort Explanation</h3>
<p>
  Insertion Sort is a simple comparison-based sorting algorithm that builds the final sorted array one item at a time. It is much like sorting playing cards in your hands. Below is the explanation using the provided Insertion Sort code:
</p>

<div>
  <strong>InsertionSort Function:</strong>
  <pre>{`function insertionSort(arr) {
    for (let i = 1; i < arr.length; i++) {
        let key = arr[i];
        let j = i - 1;

        /* Move elements of arr[0..i-1], that are
           greater than key, to one position ahead
           of their current position */
        while (j >= 0 && arr[j] > key) {
            arr[j + 1] = arr[j];
            j = j - 1;
        }
        arr[j + 1] = key;
    }
}`}</pre>
  <p>
    <strong>Explanation:</strong> 
    The `insertionSort` function iterates through the array starting from the second element. For each element, it compares it with the elements in the sorted portion of the array (to its left) and inserts it into its correct position. Elements greater than the current key are shifted one position to the right.
  </p>
</div>

<div>
  <strong>Print Array Function:</strong>
  <pre>{`function printArray(arr) {
    console.log(arr.join(" "));
}`}</pre>
  <p>
    <strong>Explanation:</strong> 
    The `printArray` function prints each element of the array separated by a space. It is used to display the sorted array after applying Insertion Sort.
  </p>
</div>

<div>
  <strong>Driver Code:</strong>
  <pre>{`let arr = [12, 11, 13, 5, 6];
insertionSort(arr);
printArray(arr);`}</pre>
  <p>
    <strong>Explanation:</strong> 
    This driver code initializes an unsorted array, sorts it using `insertionSort`, and prints the sorted array. It demonstrates the complete execution of the Insertion Sort algorithm.
  </p>
</div>

<p>
  Insertion Sort has a time complexity of O(n²) in the average and worst cases but performs well for small or nearly sorted arrays. It is simple to understand and implement, making it useful for educational purposes and small datasets.
</p>

            {/* <h3>Insertion Sort Explanation</h3>
              <p>
                The Insertion Sort function sorts an array by building a sorted portion one element at a time.
                Here’s a detailed explanation of how it works:
              </p>
              <p>
                1. **Initialization**: The algorithm starts from the second element (index 1), as the first element is trivially sorted.
              </p>
              <p>
                2. **Select the Key**: The element at the current position `i` is selected as the **key** to be inserted into the sorted portion.
              </p>
              <p>
                3. **Find the Correct Position**: A second index `j` is used to compare the **key** with elements to its left (already sorted portion). The goal is to find the correct position where the **key** should be inserted.
              </p>
              <p>
                4. **Shift Elements**: If elements in the sorted portion are greater than the **key**, they are shifted to the right to make space for the **key**.
              </p>
              <p>
                5. **Insert the Key**: Once the correct position is found, the **key** is inserted into its appropriate place.
              </p>
              <p>
                6. **Repeat**: This process continues for each element in the array until the entire array is sorted.
              </p>
              <p>
                This algorithm sorts the array in-place, meaning it modifies the original array rather than using extra space.
              </p> */}
            {/* <h3>Insertion Sort Explanation</h3>
              <p>
                Insertion Sort is a simple sorting algorithm that builds the final sorted array one item at a time.
                It picks each item from the input data and finds the location it belongs in the already-sorted part
                of the array, and inserts it there. This process is repeated until the entire array is sorted.
              </p>
              <p>
                Here’s a step-by-step breakdown of the process:
              </p>
              <ol>
                <li><strong>Pick an Element:</strong> Take an element from the unsorted part of the array.</li>
                <li><strong>Find the Position:</strong> Compare it with elements in the sorted part and find its correct position.</li>
                <li><strong>Insert:</strong> Insert the element at the correct position, shifting the larger elements if necessary.</li>
              </ol>
              <p>
                This animation shows how Insertion Sort iterates through the array, inserting each element into its
                correct position relative to the already-sorted portion.
              </p> */}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Insertion;
