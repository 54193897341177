// import React, { useEffect, useRef, useState } from 'react';
// import * as d3 from 'd3';
// import './BarChart.css';
// import Box from '@mui/material/Box';
// import './Quick.css';

// const Quick = () => {
//   const chartRef = useRef();
//   const [data, setData] = useState([30, 50, 35, 65, 70, 75, 80]);
//   const [inputValue, setInputValue] = useState('');
//   const [sortingCompleted, setSortingCompleted] = useState(false);
//   const [showExplanation, setShowExplanation] = useState(false);

//   useEffect(() => {
//     const margin = { top: 20, right: 20, bottom: 30, left: 40 },
//           width = 300 - margin.left - margin.right,
//           height = 350 - margin.top - margin.bottom;

//     const svg = d3.select(chartRef.current)
//       .html("")
//       .append("svg")
//         .attr("width", width + margin.left + margin.right)
//         .attr("height", height + margin.top + margin.bottom)
//       .append("g")
//         .attr("transform", `translate(${margin.left},${margin.top})`);

//     const x = d3.scaleBand()
//       .domain(data.map((_, i) => i))
//       .range([0, width])
//       .padding(0.1);

//     const y = d3.scaleLinear()
//       .domain([0, d3.max(data)])
//       .nice()
//       .range([height, 0]);

//     const bars = svg.selectAll(".bar")
//       .data(data)
//       .enter().append("rect")
//       .attr("class", "bar")
//       .attr("x", (d, i) => x(i))
//       .attr("y", d => y(d))
//       .attr("width", x.bandwidth())
//       .attr("height", d => height - y(d))
//       .attr("fill", "#69b3a2");

//     const updateChart = (newData) => {
//       bars.data(newData)
//         .transition()
//         .duration(500)
//         .attr("x", (d, i) => x(i))
//         .attr("y", d => y(d))
//         .attr("height", d => height - y(d));
//     };

//     const quickSort = async (arr, left = 0, right = arr.length - 1) => {
//       const partition = async (arr, left, right) => {
//         const pivot = arr[Math.floor((left + right) / 2)];
//         let i = left;
//         let j = right;
//         while (i <= j) {
//           while (arr[i] < pivot) i++;
//           while (arr[j] > pivot) j--;
//           if (i <= j) {
//             [arr[i], arr[j]] = [arr[j], arr[i]];
//             i++;
//             j--;
//             updateChart(arr);
//             await new Promise(resolve => setTimeout(resolve, 500));
//           }
//         }
//         return i;
//       };

//       if (left < right) {
//         const index = await partition(arr, left, right);
//         await Promise.all([
//           quickSort(arr, left, index - 1),
//           quickSort(arr, index, right)
//         ]);
//       } else {
//         setSortingCompleted(true);
//       }
//     };

//     if (data.length > 0) {
//       quickSort(data);
//     }
//   }, [data]);

//   const handleInputChange = (e) => {
//     setInputValue(e.target.value);
//   };

//   const handleSortClick = () => {
//     const newData = inputValue.split(',').map(Number);
//     setData(newData);
//     setSortingCompleted(false);
//   };

//   return (
//     <div className='quick-cont'>
//       <h2>Quick Sort Visualization</h2>
//       <input 
//         type="text" 
//         value={inputValue} 
//         onChange={handleInputChange} 
//         placeholder="Enter numbers separated by commas" 
//       />
//         <Box
//       component="form"
//       sx={{
//         '& > :not(style)': { m: 1, width: '25ch' },
//       }}
//       noValidate
//       autoComplete="off"
//     ></Box>
//       <button onClick={handleSortClick}>Sort</button>
//       <div ref={chartRef}></div>
//       {sortingCompleted && (
//         <div>
//           <button onClick={() => setShowExplanation(!showExplanation)}>
//             {showExplanation ? 'Hide Explanation' : 'Show Explanation'}
//           </button>
//           {showExplanation && (
//             <div className="quick-show">
//               <h3>Quick Sort Explanation</h3>
//               <p>
//                 Quick Sort is a divide and conquer algorithm that selects a 'pivot' element and partitions
//                 the other elements into two sub-arrays according to whether they are less than or greater than
//                 the pivot. The sub-arrays are then sorted recursively.
//               </p>
//               <p>
//                 This animation illustrates how the array is partitioned around the pivot and how elements
//                 are swapped to achieve a sorted array.
//               </p>
//               <pre>
//                 <code>
//                 <p>
//                 {/* {`const quickSort = async (arr, left = 0, right = arr.length - 1) => {
//   const partition = async (arr, left, right) => {
//     const pivot = arr[Math.floor((left + right) / 2)];
//     let i = left;
//     let j = right;
//     while (i <= j) {
//       while (arr[i] < pivot) i++;
//       while (arr[j] > pivot) j--;
//       if (i <= j) {
//         [arr[i], arr[j]] = [arr[j], arr[i]];
//         i++;
//         j--;
//         updateChart(arr);
//         await new Promise(resolve => setTimeout(resolve, 500));
//       }
//     }
//     return i;
//   };

//   if (left < right) {
//     const index = await partition(arr, left, right);
//     await Promise.all([
//       quickSort(arr, left, index - 1),
//       quickSort(arr, index, right)
//     ]);
//   } else {
//     setSortingCompleted(true);
//   }
// };`} */}
//                 </p>
// {/* {`const quickSort = async (arr, left = 0, right = arr.length - 1) => {
//   const partition = async (arr, left, right) => {
//     const pivot = arr[Math.floor((left + right) / 2)];
//     let i = left;
//     let j = right;
//     while (i <= j) {
//       while (arr[i] < pivot) i++;
//       while (arr[j] > pivot) j--;
//       if (i <= j) {
//         [arr[i], arr[j]] = [arr[j], arr[i]];
//         i++;
//         j--;
//         updateChart(arr);
//         await new Promise(resolve => setTimeout(resolve, 500));
//       }
//     }
//     return i;
//   };

//   if (left < right) {
//     const index = await partition(arr, left, right);
//     await Promise.all([
//       quickSort(arr, left, index - 1),
//       quickSort(arr, index, right)
//     ]);
//   } else {
//     setSortingCompleted(true);
//   }
// };`} */}
//                 </code>
//               </pre>
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default Quick;


//With Transition 


import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import './BarChart.css';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import './Bubble.css';

const Quick = () => {
  const chartRef = useRef();
  const [data, setData] = useState([30, 50, 35, 65, 70, 75, 80]);
  const [inputValue, setInputValue] = useState('');
  const [sortingCompleted, setSortingCompleted] = useState(false);
  const [showExplanation, setShowExplanation] = useState(false);
  const [transitionDuration, setTransitionDuration] = useState(500); // default to 500ms

  useEffect(() => {
    const margin = { top: 20, right: 20, bottom: 30, left: 40 },
          width = 300 - margin.left - margin.right,
          height = 400 - margin.top - margin.bottom;

    const svg = d3.select(chartRef.current)
      .html("")
      .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
      .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

    const x = d3.scaleBand()
      .domain(data.map((_, i) => i))
      .range([0, width])
      .padding(0.1);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data)])
      .nice()
      .range([height, 0]);

    const bars = svg.selectAll(".bar")
      .data(data)
      .enter().append("rect")
      .attr("class", "bar")
      .attr("x", (d, i) => x(i))
      .attr("y", d => y(d))
      .attr("width", x.bandwidth())
      .attr("height", d => height - y(d))
      .attr("fill", "#69b3a2");

    const updateChart = (newData) => {
      bars.data(newData)
        .transition()
        .duration(transitionDuration) // use the transition duration from state
        .attr("x", (d, i) => x(i))
        .attr("y", d => y(d))
        .attr("height", d => height - y(d));
    };

    const quickSort = async (arr, left = 0, right = arr.length - 1) => {
      const partition = async (arr, left, right) => {
        const pivot = arr[Math.floor((left + right) / 2)];
        let i = left;
        let j = right;
        while (i <= j) {
          while (arr[i] < pivot) i++;
          while (arr[j] > pivot) j--;
          if (i <= j) {
            [arr[i], arr[j]] = [arr[j], arr[i]];
            i++;
            j--;
            updateChart(arr);
            await new Promise(resolve => setTimeout(resolve, transitionDuration)); // use the transition duration from state
          }
        }
        return i;
      };

      if (left < right) {
        const index = await partition(arr, left, right);
        await Promise.all([
          quickSort(arr, left, index - 1),
          quickSort(arr, index, right)
        ]);
      } else {
        setSortingCompleted(true);
      }
    };

    if (data.length > 0) {
      quickSort(data);
    }
  }, [data, transitionDuration]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSortClick = () => {
    const newData = inputValue.split(',').map(Number);
    setData(newData);
    setSortingCompleted(false);
  };

  const handleTransitionChange = (event, newValue) => {
    setTransitionDuration(newValue);
  };

  return (
    <div className='bubble-cont'>
      <h2>Quick Sort Visualization</h2>
      <input 
        type="text" 
        value={inputValue} 
        onChange={handleInputChange} 
        placeholder="Enter numbers separated by commas" 
      />
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      ></Box>
      <button onClick={handleSortClick}>Sort</button>
      <div ref={chartRef}></div>
      <div style={{ marginTop: '20px' }}>
        <Typography gutterBottom>Transition Speed (ms)</Typography>
        <Slider
          value={transitionDuration}
          onChange={handleTransitionChange}
          min={100}
          max={2000}
          step={100}
          valueLabelDisplay="auto"
          aria-labelledby="transition-speed-slider"
          sx={{
            width: 300,
            margin: '0 auto',
          }}
        />
        <Typography variant="caption" color="textSecondary" align="center">
          {transitionDuration} ms
        </Typography>
      </div>
      {sortingCompleted && (
        <div>
          <button onClick={() => setShowExplanation(!showExplanation)}>
            {showExplanation ? 'Hide Explanation' : 'Show Explanation'}
          </button>
          {showExplanation && (
            <div className='bubble-show'>
            {/* <h3>Quick Sort Explanation</h3>
              <p>
                Quick Sort is a divide and conquer algorithm that selects a 'pivot' element from the array
                and partitions the other elements into two sub-arrays, according to whether they are less than
                or greater than the pivot. The sub-arrays are then sorted recursively.
              </p>
              <p>
                Here’s a step-by-step breakdown of the process:
              </p>
              <ol>
                <li><strong>Choose Pivot:</strong> Select an element from the array as the pivot (commonly the last element).</li>
                <li><strong>Partition:</strong> Reorder the array so that all elements less than the pivot come before it and all elements greater come after it.</li>
                <li><strong>Recursively Sort:</strong> Apply the same process to the sub-arrays of elements with smaller and larger values.</li>
              </ol>
              <p>
                This animation shows how Quick Sort partitions the array and recursively sorts the sub-arrays.
              </p> */}


              {/* <p>
                For a more interactive learning experience, you can check out <a href="https://www.cs.usfca.edu/~galles/visualization/QuickSort.html" target="_blank" rel="noopener noreferrer">this visualization tool</a> to see Quick Sort in action.
              </p> */}

              <h3>Quick Sort Explanation</h3>
              <p>
                Quick Sort is a divide-and-conquer algorithm that sorts by selecting a 'pivot' element and partitioning the other elements into two sub-arrays according to whether they are less than or greater than the pivot. Here’s a detailed explanation:
              </p>
              <div>
                <strong>Choose Pivot:</strong>
                <pre>{`let pivot = arr[high];`}</pre>
                <p>
                  <strong>Explanation:</strong> 
                  The pivot is the element chosen to divide the array into two parts. Elements less than the pivot will go to the left, and elements greater than the pivot will go to the right.
                </p>
              </div>
              <div>
                <strong>Partitioning:</strong>
                <pre>{`for (let j = low; j < high; j++) {
  if (arr[j] < pivot) {
    [arr[i], arr[j]] = [arr[j], arr[i]];
    i++;
  }
}`}</pre>
                <p>
                  <strong>Explanation:</strong> 
                  This loop iterates through the array from the `low` index to the `high` index. It swaps elements smaller than the pivot with the elements at index `i`, ensuring that all elements less than the pivot are placed before it.
                </p>
              </div>
              <div>
                <strong>Final Swap:</strong>
                <pre>{`[arr[i], arr[high]] = [arr[high], arr[i]];`}</pre>
                <p>
                  <strong>Explanation:</strong> 
                  After partitioning, the pivot is placed in its correct position by swapping it with the element at index `i`. This ensures that all elements to the left of the pivot are smaller and all elements to the right are greater.
                </p>
              </div>
              <div>
                <strong>Recursive Sort:</strong>
                <pre>{`await quickSort(arr, low, pivotIndex - 1);
await quickSort(arr, pivotIndex + 1, high);`}</pre>
                <p>
                  <strong>Explanation:</strong> 
                  The array is then recursively sorted by applying the same process to the sub-arrays created by partitioning around the pivot.
                </p>
              </div>
              <p>
                This process continues until the base case is reached, where the sub-array has one or zero elements, which means it's already sorted.
              </p>

              <h1>Geeks Quick Sort</h1>
              <h3>Quick Sort Explanation</h3>
<p>
  Quick Sort is a divide-and-conquer algorithm that sorts an array by selecting a 'pivot' element and partitioning the other elements into two sub-arrays according to whether they are less than or greater than the pivot. Here’s a detailed explanation using the provided code:
</p>

<div>
  <strong>Partition Function:</strong>
  <pre>{`function partition(arr, low, high) {
    // Choose the pivot
    const pivot = arr[high];

    let i = low - 1;

    // Traverse arr[low..high] and move all smaller
    // elements on the left side
    for (let j = low; j < high; j++) {
        if (arr[j] < pivot) {
            i++;
            [arr[i], arr[j]] = [arr[j], arr[i]];
        }
    }

    // Move pivot after smaller elements
    [arr[i + 1], arr[high]] = [arr[high], arr[i + 1]];
    return i + 1;
}`}</pre>
  <p>
    <strong>Explanation:</strong> 
    The `partition` function selects the last element as the pivot and arranges the array such that all elements smaller than the pivot are placed to the left. The function then swaps the pivot into its correct position and returns its index. 
  </p>
</div>

<div>
  <strong>QuickSort Function:</strong>
  <pre>{`function quickSort(arr, low, high) {
    if (low < high) {
        // pi is the partition return index of pivot
        const pi = partition(arr, low, high);

        // Recursively sort the two sub-arrays
        quickSort(arr, low, pi - 1);
        quickSort(arr, pi + 1, high);
    }
}`}</pre>
  <p>
    <strong>Explanation:</strong> 
    The `quickSort` function recursively sorts the array by partitioning it around the pivot and applying the same sorting logic to the sub-arrays on the left and right of the pivot. The recursion continues until the base case is reached, where the sub-arrays have one or zero elements.
  </p>
</div>

<div>
  <strong>Print Array Function:</strong>
  <pre>{`function printArray(arr) {
    console.log(arr.join(" "));
}`}</pre>
  <p>
    <strong>Explanation:</strong> 
    The `printArray` function prints the elements of the array in a single line, separated by spaces, for easy viewing.
  </p>
</div>

<div>
  <strong>Driver Code:</strong>
  <pre>{`const arr = [10, 7, 8, 9, 1, 5];
console.log("Given array is");
printArray(arr);

quickSort(arr, 0, arr.length - 1);

console.log("\\nSorted array is");
printArray(arr);`}</pre>
  <p>
    <strong>Explanation:</strong> 
    This code initializes an unsorted array, prints it, sorts it using the `quickSort` function, and then prints the sorted array. It demonstrates the full process of applying the Quick Sort algorithm.
  </p>
</div>
<p>
  The Quick Sort algorithm continues partitioning and sorting until the entire array is sorted, resulting in an efficient sorting method with a time complexity of O(n log n) on average.
</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Quick;
