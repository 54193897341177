import React from 'react';
import Ginger from '../assets/Ginger.mp3'
import ai2 from '../assets/ai2.mp4'
import Negative from '../assets/Negative.mp3'
import ai1 from '../assets/ai1.mp4'
import Jaye from '../assets/Jaye.mp3';
import JayeVd from '../assets/JayeVd.mp4'
import ai3 from '../assets/ai3.mp3';
import ola1 from '../assets/ola1.mp3';
import ola2 from '../assets/ola2.mp3';
import ola5 from '../assets/ola5.mp3';
import Transp from '../assets/Transp.mp4';

function Afro_AI() {
  return (
    <div >
   <h1>🎵</h1>
    <div className='intro2'>
    {/* <div className='port-2'>
        <video className="pfp1" alt="pfp" src={Transp} width="100%" height="350px"  autoplay="false" muted></video>
        <audio controls>
        <source src={ola2} />

        Your browser does not support the audio element.
    </audio>
        

        </div> */}
        {/* <div className='port-2'>
        <video className="pfp1" autoplay loop muted>
  <source src={Transp} type="video/mp4" />
  <source src="movie.ogg" type="video/ogg" />
  Your browser does not support the video tag.
</video>
        </div> */}
       
        {/* <div className='port-2'>
       
        <video className="pfp1" alt="pfp" src={ai1} width="100%" height="350px" loop 
  muted 
  autoplay></video>
        <audio controls>
        <source src={Negative} />

        Your browser does not support the audio element.
    </audio>
        

        </div> */}
        {/* <div className='port-2'>
        <video className="pfp1" alt="pfp" src={Transp} width="100%" height="350px"  autoplay="false" muted></video>
        <audio controls>
        <source src={Ginger} />

        Your browser does not support the audio element.
    </audio>
        

        </div> */}
        <div className='port-3'>
        <video className="pfp1" alt="pfp" src={Transp} width="100%" height="350px"  autoplay="false" muted></video>
        <audio controls>
        <source src={ai3} />

        Your browser does not support the audio element.
    </audio>
        

        </div>
        <div className='port-3'>
        <video className="pfp1" alt="pfp" src={Transp} width="100%" height="350px"  autoplay="false" muted></video>
        <audio controls>
        <source src={ola5} />

        Your browser does not support the audio element.
    </audio>
        

        </div>
      {/* Releveant */}
        <div className='port-3'>
        <video className="pfp1" alt="pfp" src={Transp} width="100%" height="350px"  autoplay="false" muted></video>
        <audio controls>
        <source src={ola1} />

        Your browser does not support the audio element.
    </audio>
        

        </div>
        <div>
        {/* <div className='port-2'>
        <video className="pfp1" alt="pfp" src={JayeVd} width="100%" height="350px"  autoplay="false" muted></video>
        <audio controls>
        <source src={Jaye} />

        Your browser does not support the audio element.
    </audio>
        

        </div> */}
    </div>
    </div>
    <div >
       
    </div>
  
    </div>
  )
}

export default Afro_AI