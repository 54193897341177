// import React, { useEffect, useRef, useState } from 'react';
// import * as d3 from 'd3';
// import './BarChart.css';
// import Button from '@mui/material/Button';
// import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';
// import mrg from '../assets/mrg.png';
// import './Merge.css';

// const Merge = () => {
//   const chartRef = useRef();
//   const [data, setData] = useState([30, 50, 35, 65, 70, 75, 80]);
//   const [inputValue, setInputValue] = useState('');
//   const [sortingCompleted, setSortingCompleted] = useState(false);
//   const [showExplanation, setShowExplanation] = useState(false);

//   useEffect(() => {
//     const margin = { top: 20, right: 20, bottom: 30, left: 40 },
//           width = 300 - margin.left - margin.right,
//           height = 400 - margin.top - margin.bottom;

//     const svg = d3.select(chartRef.current)
//       .html("")
//       .append("svg")
//         .attr("width", width + margin.left + margin.right)
//         .attr("height", height + margin.top + margin.bottom)
//       .append("g")
//         .attr("transform", `translate(${margin.left},${margin.top})`);

//     const x = d3.scaleBand()
//       .domain(data.map((_, i) => i))
//       .range([0, width])
//       .padding(0.1);

//     const y = d3.scaleLinear()
//       .domain([0, d3.max(data)])
//       .nice()
//       .range([height, 0]);

//     const bars = svg.selectAll(".bar")
//       .data(data)
//       .enter().append("rect")
//       .attr("class", "bar")
//       .attr("x", (d, i) => x(i))
//       .attr("y", d => y(d))
//       .attr("width", x.bandwidth())
//       .attr("height", d => height - y(d))
//       .attr("fill", "#69b3a2");

//     const updateChart = (newData) => {
//       bars.data(newData)
//         .transition()
//         .duration(500)
//         .attr("x", (d, i) => x(i))
//         .attr("y", d => y(d))
//         .attr("height", d => height - y(d));
//     };

//     const mergeSort = async (arr) => {
//       const merge = async (left, right) => {
//         let result = [];
//         while (left.length && right.length) {
//           if (left[0] <= right[0]) result.push(left.shift());
//           else result.push(right.shift());
//         }
//         return result.concat(left, right);
//       };

//       const sort = async (array) => {
//         if (array.length < 2) return array;
//         const mid = Math.floor(array.length / 2);
//         const left = array.slice(0, mid);
//         const right = array.slice(mid);
//         return merge(await sort(left), await sort(right));
//       };

//       const sortedData = await sort(arr);
//       updateChart(sortedData);
//       setSortingCompleted(true);
//     };

//     if (data.length > 0) {
//       mergeSort(data);
//     }
//   }, [data]);

//   const handleInputChange = (e) => {
//     setInputValue(e.target.value);
//   };

//   const handleSortClick = () => {
//     const newData = inputValue.split(',').map(Number);
//     setData(newData);
//     setSortingCompleted(false);
//   };

//   return (
//     <div className='merge-container'>
//     <div>

    
//       <h2>Merge Sort Visualization</h2>
//       <input 
//         type="text" 
//         value={inputValue} 
//         onChange={handleInputChange} 
//         placeholder="Enter numbers separated by commas" 
//       />
//        <Box
//       component="form"
//       sx={{
//         '& > :not(style)': { m: 1, width: '25ch' },
//       }}
//       noValidate
//       autoComplete="off"
//     ></Box>
//       {/* <TextField type="text" 
//         value={inputValue} 
//         onChange={handleInputChange} id="standard-basic" label="Enter numbers separated by commas" variant="outlined" /> */}
//       <button onClick={handleSortClick}>Sort</button>
// {/* <Button onClick={handleSortClick} variant="Contained">Sort</Button> */}

//       <div ref={chartRef}></div>
//       {sortingCompleted && (
//         <div>
//           <button onClick={() => setShowExplanation(!showExplanation)}>
//             {showExplanation ? 'Hide Explanation' : 'Show Explanation'}
//           </button>
         
//           {showExplanation && (
//             <div className="merge-box">
//               <h3>Merge Sort Explanation</h3>
//               <p>
//                 Merge Sort is a divide and conquer algorithm that divides the array into two halves,
//                 recursively sorts them, and then merges the sorted halves. The merging process involves
//                 combining two sorted arrays into one sorted array.
//               </p>
//               <p>
//                 This animation demonstrates how the algorithm splits the array into smaller parts and
//                 merges them step-by-step until the entire array is sorted.
//               </p>
//               <pre>
//                 {/* <code>
// {`const mergeSort = async (arr) => {
//   const merge = async (left, right) => {
//     let result = [];
//     while (left.length && right.length) {
//       if (left[0] <= right[0]) result.push(left.shift());
//       else result.push(right.shift());
//     }
//     return result.concat(left, right);
//   };

//   const sort = async (array) => {
//     if (array.length < 2) return array;
//     const mid = Math.floor(array.length / 2);
//     const left = array.slice(0, mid);
//     const right = array.slice(mid);
//     return merge(await sort(left), await sort(right));
//   };

//   const sortedData = await sort(arr);
//   updateChart(sortedData);
//   setSortingCompleted(true);
// };`}
//                 </code> */}
//               </pre>
//             </div>
//           )}
//         </div>
//       )}
//       </div>
//     </div>
//   );
// };

// export default Merge;




//With Transition
import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import './Bubble.css';

const Merge = () => {
  const chartRef = useRef();
  const [data, setData] = useState([30, 50, 35, 65, 70, 75, 80]);
  const [inputValue, setInputValue] = useState('');
  const [sortingCompleted, setSortingCompleted] = useState(false);
  const [showExplanation, setShowExplanation] = useState(false);
  const [transitionDuration, setTransitionDuration] = useState(500); // default to 500ms

  useEffect(() => {
    const margin = { top: 20, right: 20, bottom: 30, left: 40 },
          width = 300 - margin.left - margin.right,
          height = 400 - margin.top - margin.bottom;

    const svg = d3.select(chartRef.current)
      .html("")
      .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
      .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

    const x = d3.scaleBand()
      .domain(data.map((_, i) => i))
      .range([0, width])
      .padding(0.1);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data)])
      .nice()
      .range([height, 0]);

    const bars = svg.selectAll(".bar")
      .data(data)
      .enter().append("rect")
      .attr("class", "bar")
      .attr("x", (d, i) => x(i))
      .attr("y", d => y(d))
      .attr("width", x.bandwidth())
      .attr("height", d => height - y(d))
      .attr("fill", "#69b3a2");

    const updateChart = (newData) => {
      bars.data(newData)
        .transition()
        .duration(transitionDuration)
        .attr("x", (d, i) => x(i))
        .attr("y", d => y(d))
        .attr("height", d => height - y(d));
    };

    const mergeSort = async (arr) => {
      const merge = async (left, right) => {
        let result = [];
        while (left.length && right.length) {
          if (left[0] <= right[0]) result.push(left.shift());
          else result.push(right.shift());
        }
        return result.concat(left, right);
      };

      const sort = async (array) => {
        if (array.length < 2) return array;
        const mid = Math.floor(array.length / 2);
        const left = array.slice(0, mid);
        const right = array.slice(mid);
        return merge(await sort(left), await sort(right));
      };

      const sortedData = await sort(arr);
      updateChart(sortedData);
      setSortingCompleted(true);
    };

    if (data.length > 0) {
      mergeSort(data);
    }
  }, [data, transitionDuration]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSortClick = () => {
    const newData = inputValue.split(',').map(Number);
    setData(newData);
    setSortingCompleted(false);
  };

  const handleTransitionChange = (event, newValue) => {
    setTransitionDuration(newValue);
  };

  return (
    <div className='bubble-cont'>
      <h2>Merge Sort Visualization</h2>
      <input 
        type="text" 
        value={inputValue} 
        onChange={handleInputChange} 
        placeholder="Enter numbers separated by commas" 
      />
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      ></Box>
      <button onClick={handleSortClick}>Sort</button>
      <div ref={chartRef}></div>
      <div style={{ marginTop: '20px' }}>
        <Typography gutterBottom>Transition Speed (ms)</Typography>
        <Slider
          value={transitionDuration}
          onChange={handleTransitionChange}
          min={100}
          max={2000}
          step={100}
          valueLabelDisplay="auto"
          aria-labelledby="transition-speed-slider"
          sx={{
            width: 300,
            margin: '0 auto',
          }}
        />
        <Typography variant="caption" color="textSecondary" align="center">
          {transitionDuration} ms
        </Typography>
      </div>
      {sortingCompleted && (
        <div>
          <button onClick={() => setShowExplanation(!showExplanation)}>
            {showExplanation ? 'Hide Explanation' : 'Show Explanation'}
          </button>
          {showExplanation && (
            <div className='bubble-show'>
            <h3>Merge Sort Explanation</h3>
              <p>
                Merge Sort is a divide and conquer algorithm that sorts an array by dividing it into smaller subarrays,
                sorting those subarrays, and then merging them back together. Here’s a detailed explanation of how it works:
              </p>
              <div>
                <strong>Divide the Array:</strong>
                <pre>{`const mid = Math.floor(array.length / 2);
const left = array.slice(0, mid);
const right = array.slice(mid);`}</pre>
                <p>
                  <strong>Explanation:</strong> 
                  The array is divided into two halves, `left` and `right`. This step recursively continues until each subarray has only one element or is empty.
                </p>
              </div>
              <div>
                <strong>Merge the Subarrays:</strong>
                <pre>{`const merge = async (left, right) => {
  let result = [];
  while (left.length && right.length) {
    if (left[0] <= right[0]) result.push(left.shift());
    else result.push(right.shift());
  }
  return result.concat(left, right);
};`}</pre>
                <p>
                  <strong>Explanation:</strong> 
                  The merge function combines two sorted subarrays (`left` and `right`) into a single sorted array. It compares the first elements of each subarray, appending the smaller element to the result array and removing it from the original subarray.
                </p>
              </div>
              <div>
                <strong>Recursive Sorting:</strong>
                <pre>{`const sort = async (array) => {
  if (array.length < 2) return array;
  const mid = Math.floor(array.length / 2);
  const left = array.slice(0, mid);
  const right = array.slice(mid);
  const sortedLeft = await sort(left);
  const sortedRight = await sort(right);
  return merge(sortedLeft, sortedRight);
};`}</pre>
                <p>
                  <strong>Explanation:</strong> 
                  The `sort` function is called recursively to sort the `left` and `right` subarrays. Once both subarrays are sorted, they are merged back together using the `merge` function.
                </p>
              </div>
              <p>
                This process continues until the entire array is sorted. The algorithm efficiently handles large datasets by breaking the problem into smaller manageable chunks.
              </p>





            {/* <h3>Merge Sort Explanation</h3>
              <p>
                Merge Sort is a divide and conquer algorithm that divides the array into two halves,
                recursively sorts them, and then merges the sorted halves. The merging process involves
                combining two sorted arrays into one sorted array.
              </p>
              <p>
                Here’s a step-by-step breakdown of the process:
              </p>
              <ol>
                <li><strong>Divide:</strong> Split the array into two halves until each subarray contains a single element or is empty.</li>
                <li><strong>Conquer:</strong> Recursively sort each subarray. Since single-element arrays are inherently sorted, the algorithm sorts these as it splits.</li>
                <li><strong>Merge:</strong> Combine the sorted subarrays into a single sorted array. This is done by comparing the elements from each subarray and merging them in sorted order.</li>
              </ol>
              <p>
                This animation demonstrates how the algorithm splits the array into smaller parts and merges them step-by-step until the entire array is sorted.
              </p> */}
              {/* <p>
                For a more interactive learning experience, you can check out <a href="https://www.cs.usfca.edu/~galles/visualization/ComparisonSort.html" target="_blank" rel="noopener noreferrer">this visualization tool</a> to see Merge Sort in action.
              </p> */}
              <h1>Geeks Solution</h1>
              <h3>Merge Sort Explanation</h3>
<p>
  Merge Sort is a divide-and-conquer algorithm that splits the array into two halves, recursively sorts each half, and then merges them back together in sorted order. Below is the detailed explanation using the provided code:
</p>

<div>
  <strong>Merge Function:</strong>
  <pre>{`function merge(arr, left, mid, right) {
    const n1 = mid - left + 1;
    const n2 = right - mid;

    // Create temp arrays
    const L = new Array(n1);
    const R = new Array(n2);

    // Copy data to temp arrays L[] and R[]
    for (let i = 0; i < n1; i++)
        L[i] = arr[left + i];
    for (let j = 0; j < n2; j++)
        R[j] = arr[mid + 1 + j];

    let i = 0, j = 0;
    let k = left;

    // Merge the temp arrays back into arr[left..right]
    while (i < n1 && j < n2) {
        if (L[i] <= R[j]) {
            arr[k] = L[i];
            i++;
        } else {
            arr[k] = R[j];
            j++;
        }
        k++;
    }

    // Copy the remaining elements of L[], if there are any
    while (i < n1) {
        arr[k] = L[i];
        i++;
        k++;
    }

    // Copy the remaining elements of R[], if there are any
    while (j < n2) {
        arr[k] = R[j];
        j++;
        k++;
    }
}`}</pre>
  <p>
    <strong>Explanation:</strong> 
    The `merge` function merges two sub-arrays by comparing the elements of the temporary arrays `L[]` and `R[]` and placing the smaller elements into the main array `arr[]`. After all elements from one of the temporary arrays are exhausted, the remaining elements from the other array are copied into the main array.
  </p>
</div>

<div>
  <strong>MergeSort Function:</strong>
  <pre>{`function mergeSort(arr, left, right) {
    if (left >= right)
        return;

    const mid = Math.floor(left + (right - left) / 2);
    mergeSort(arr, left, mid);
    mergeSort(arr, mid + 1, right);
    merge(arr, left, mid, right);
}`}</pre>
  <p>
    <strong>Explanation:</strong> 
    The `mergeSort` function recursively divides the array into two halves by finding the middle point (`mid`), sorts each half recursively, and finally merges the sorted halves using the `merge` function. This process continues until the sub-arrays are of size one or zero, which are inherently sorted.
  </p>
</div>

<div>
  <strong>Print Array Function:</strong>
  <pre>{`function printArray(arr) {
    console.log(arr.join(" "));
}`}</pre>
  <p>
    <strong>Explanation:</strong> 
    The `printArray` function simply prints the elements of the array in a single line, separated by spaces, for easy visualization.
  </p>
</div>

<div>
  <strong>Driver Code:</strong>
  <pre>{`const arr = [12, 11, 13, 5, 6, 7];
console.log("Given array is");
printArray(arr);

mergeSort(arr, 0, arr.length - 1);

console.log("\\nSorted array is");
printArray(arr);`}</pre>
  <p>
    <strong>Explanation:</strong> 
    This driver code initializes an unsorted array, prints it, sorts it using the `mergeSort` function, and then prints the sorted array. It demonstrates the complete process of applying Merge Sort to an array.
  </p>
</div>

<p>
  Merge Sort ensures a stable sort and has a time complexity of O(n log n), making it efficient for large datasets. It is especially useful in applications where stability is a concern.
</p>

            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Merge;

