// src/Components/AzureAIArticle.js

import React from 'react';
import ai_dev from "../assets/ai_dev.MP4";
import Button from '@mui/material/Button';

const AzureAI = () => {
  return (
    <div className="container" style={{ padding: '20px', color: '#333', fontFamily: 'Arial, sans-serif' }}>
      <h1 className="heading">Exploring My Journey Through Azure AI Fundamentals Certification</h1>
      <form className="form">
      <h3>
        As technology continues to evolve, the significance of artificial intelligence (AI) in driving innovation and efficiency cannot be overstated. Recently, I decided to deepen my understanding of AI by pursuing the Azure AI Fundamentals certification. This journey through Microsoft’s learning platform has been both enlightening and rewarding, and I’m excited to share my experiences and insights gained from this certification.
      </h3>
      
      <h2>Discovering Azure AI Fundamentals</h2>
      <h3>
        Microsoft’s Azure AI Fundamentals certification is designed to provide a comprehensive overview of AI concepts and services, particularly within the Azure ecosystem. The course is perfect for both beginners and professionals looking to enhance their knowledge of AI technologies. It covers a wide range of topics, from basic AI principles to the practical use of Azure AI services.
      </h3>
      
      <h2>Course Highlights and Learning Outcomes</h2>
      <h3>
        Throughout the certification process, I explored various key aspects of AI. Here are some of the highlights:
      </h3>
      <h3>1. Introduction to AI</h3>
      <h3>
        This section provided a solid grounding in AI concepts, including the history and evolution of AI, as well as its real-world applications. Understanding these basics was crucial for appreciating the more advanced topics covered later in the course.
      </h3>
      <h3>2. Machine Learning on Azure</h3>
      <h3>
        A significant portion of the course focused on machine learning. I learned about the different types of machine learning models, how to train and evaluate these models, and how to deploy them using Azure Machine Learning services. This hands-on experience with Azure’s tools was incredibly valuable.
      </h3>
      <h3>3. Cognitive Services</h3>
      <h3>
        Azure Cognitive Services was another exciting area of the course. I gained practical knowledge on how to implement various AI capabilities such as vision, speech, and language understanding into applications using Azure's pre-built APIs.
      </h3>
      
      <h2>Applying What I've Learned</h2>
      <h3>
        To bring the theoretical knowledge to life, I created a short demo video showcasing a practical project I developed during the course. In the video, I demonstrate how to use Azure Cognitive Services to build a simple AI application that performs image recognition. This project not only reinforced my learning but also showcased the real-world applications of the skills I acquired.
      </h3>
      <h3>
        Creating the demo was a fantastic way to solidify my understanding and share my newfound skills with others. By walking through the project step-by-step, my goal is to demystify AI and inspire others to explore its possibilities.
      </h3>
      
      <h2>Why Azure AI Fundamentals Certification Matters</h2>
      <h3>
        Earning the Azure AI Fundamentals certification has been a transformative experience. It has equipped me with essential AI skills and a deeper understanding of how to leverage Azure’s powerful AI services. This certification not only boosts my professional credibility but also opens up new opportunities for career advancement in the rapidly growing field of AI.
      </h3>
      
      <h2>Final Thoughts</h2>
      <h3>
        My journey through the Azure AI Fundamentals certification has been incredibly rewarding. The practical skills and knowledge I’ve gained empower me to tackle real-world challenges and contribute meaningfully to the field of artificial intelligence. I highly recommend this certification to anyone interested in AI, whether you are just starting out or looking to deepen your expertise.
      </h3>
      <h3>
        Stay tuned for my demo video, where I’ll showcase the practical aspects of Azure AI services and share more insights from my learning journey. Thank you for joining me on this exciting adventure into the world of artificial intelligence!
      </h3>
      <div style={{ marginTop: '20px' }}>
        <h3>Watch My Demo Video</h3>
        <div>
        <iframe className="pfp1" alt="pfp" src={ai_dev} width="100%" height="350px"  autoplay="0" muted></iframe>
        <br/>
          <h2>Description: Azure AI Fundamentals </h2>
        </div>
      </div>
      <Button variant="outlined" href="/">Back</Button>
      </form>
      
      
      
    </div>
  );
};

export default AzureAI;
