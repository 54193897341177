// import React, { useEffect, useRef, useState } from 'react';
// import * as d3 from 'd3';
// import './BarChart.css';
// import Box from '@mui/material/Box';
// import './Bubble.css';
// const Bubble = () => {
//   const chartRef = useRef();
//   const [data, setData] = useState([30, 50, 35, 65, 70, 75, 80]);
//   const [inputValue, setInputValue] = useState('');
//   const [sortingCompleted, setSortingCompleted] = useState(false);
//   const [showExplanation, setShowExplanation] = useState(false);

//   useEffect(() => {
//     const margin = { top: 20, right: 20, bottom: 30, left: 40 },
//           width = 300 - margin.left - margin.right,
//           height = 400 - margin.top - margin.bottom;

//     const svg = d3.select(chartRef.current)
//       .html("")
//       .append("svg")
//         .attr("width", width + margin.left + margin.right)
//         .attr("height", height + margin.top + margin.bottom)
//       .append("g")
//         .attr("transform", `translate(${margin.left},${margin.top})`);

//     const x = d3.scaleBand()
//       .domain(data.map((_, i) => i))
//       .range([0, width])
//       .padding(0.1);

//     const y = d3.scaleLinear()
//       .domain([0, d3.max(data)])
//       .nice()
//       .range([height, 0]);

//     const bars = svg.selectAll(".bar")
//       .data(data)
//       .enter().append("rect")
//       .attr("class", "bar")
//       .attr("x", (d, i) => x(i))
//       .attr("y", d => y(d))
//       .attr("width", x.bandwidth())
//       .attr("height", d => height - y(d))
//       .attr("fill", "#69b3a2");

//     const updateChart = (newData) => {
//       bars.data(newData)
//         .transition()
//         .duration(500)
//         .attr("x", (d, i) => x(i))
//         .attr("y", d => y(d))
//         .attr("height", d => height - y(d));
//     };

//     const bubbleSort = async (arr) => {
//       const len = arr.length;
//       for (let i = 0; i < len; i++) {
//         for (let j = 0; j < len - i - 1; j++) {
//           if (arr[j] > arr[j + 1]) {
//             [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]];
//             updateChart(arr);
//             await new Promise(resolve => setTimeout(resolve, 500));
//           }
//         }
//       }
//       setSortingCompleted(true);
//     };

//     if (data.length > 0) {
//       bubbleSort(data);
//     }
//   }, [data]);

//   const handleInputChange = (e) => {
//     setInputValue(e.target.value);
//   };

//   const handleSortClick = () => {
//     const newData = inputValue.split(',').map(Number);
//     setData(newData);
//     setSortingCompleted(false);
//   };

//   return (
//     <div className='bubble-cont'>
//       <h2>Bubble Sort Visualization</h2>
//       <input 
//         type="text" 
//         value={inputValue} 
//         onChange={handleInputChange} 
//         placeholder="Enter numbers separated by commas" 
//       />
//          <Box
//       component="form"
//       sx={{
//         '& > :not(style)': { m: 1, width: '25ch' },
//       }}
//       noValidate
//       autoComplete="off"
//     ></Box>
//       <button onClick={handleSortClick}>Sort</button>
//       <div ref={chartRef}></div>
//       {sortingCompleted && (
//         <div>
//           <button onClick={() => setShowExplanation(!showExplanation)}>
//             {showExplanation ? 'Hide Explanation' : 'Show Explanation'}
//           </button>
//           {showExplanation && (
//             <div className='bubble-show'>
//               <h3>Bubble Sort Explanation</h3>
//               <p>
//                 Bubble Sort is a simple sorting algorithm that repeatedly steps through the list,
//                 compares adjacent elements, and swaps them if they are in the wrong order. The pass
//                 through the list is repeated until the list is sorted.
//               </p>
//               <p>
//                 This animation shows how the algorithm progresses by swapping elements and moving them
//                 to their correct positions gradually. After each pass, the largest unsorted element is
//                 placed at its correct position.
//               </p>
//               <pre>
//                 <code>
// {/* {`const bubbleSort = async (arr) => {
//   const len = arr.length;
//   for (let i = 0; i < len; i++) {
//     for (let j = 0; j < len - i - 1; j++) {
//       if (arr[j] > arr[j + 1]) {
//         [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]];
//         updateChart(arr);
//         await new Promise(resolve => setTimeout(resolve, 500));
//       }
//     }
//   }
//   setSortingCompleted(true);
// };`} */}
//                 </code>
//               </pre>
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default Bubble;


//With transition

import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import './BarChart.css';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import './Bubble.css';

const Bubble = () => {
  const chartRef = useRef();
  const [data, setData] = useState([30, 50, 35, 65, 70, 75, 80]);
  const [inputValue, setInputValue] = useState('');
  const [sortingCompleted, setSortingCompleted] = useState(false);
  const [showExplanation, setShowExplanation] = useState(false);
  const [transitionDuration, setTransitionDuration] = useState(500); // default to 500ms

  useEffect(() => {
    const margin = { top: 20, right: 20, bottom: 30, left: 40 },
          width = 300 - margin.left - margin.right,
          height = 400 - margin.top - margin.bottom;

    const svg = d3.select(chartRef.current)
      .html("")
      .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
      .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

    const x = d3.scaleBand()
      .domain(data.map((_, i) => i))
      .range([0, width])
      .padding(0.1);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data)])
      .nice()
      .range([height, 0]);

    const bars = svg.selectAll(".bar")
      .data(data)
      .enter().append("rect")
      .attr("class", "bar")
      .attr("x", (d, i) => x(i))
      .attr("y", d => y(d))
      .attr("width", x.bandwidth())
      .attr("height", d => height - y(d))
      .attr("fill", "#69b3a2");

    const updateChart = (newData) => {
      bars.data(newData)
        .transition()
        .duration(transitionDuration) // use the transition duration from state
        .attr("x", (d, i) => x(i))
        .attr("y", d => y(d))
        .attr("height", d => height - y(d));
    };

    const bubbleSort = async (arr) => {
      const len = arr.length;
      for (let i = 0; i < len; i++) {
        for (let j = 0; j < len - i - 1; j++) {
          if (arr[j] > arr[j + 1]) {
            [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]];
            updateChart(arr);
            await new Promise(resolve => setTimeout(resolve, transitionDuration));
          }
        }
      }
      setSortingCompleted(true);
    };

    if (data.length > 0) {
      bubbleSort(data);
    }
  }, [data, transitionDuration]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSortClick = () => {
    const newData = inputValue.split(',').map(Number);
    setData(newData);
    setSortingCompleted(false);
  };

  const handleTransitionChange = (event, newValue) => {
    setTransitionDuration(newValue);
  };

  return (
    <div className='bubble-cont'>
      <h2>Bubble Sort Visualization</h2>
      <input 
        type="text" 
        value={inputValue} 
        onChange={handleInputChange} 
        placeholder="Enter numbers separated by commas" 
      />
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      ></Box>
      <button onClick={handleSortClick}>Sort</button>
      <div ref={chartRef}></div>
      <div style={{ marginTop: '20px' }}>
        <Typography gutterBottom>Transition Speed (ms)</Typography>
        <Slider
          value={transitionDuration}
          onChange={handleTransitionChange}
          min={100}
          max={2000}
          step={100}
          valueLabelDisplay="auto"
          aria-labelledby="transition-speed-slider"
          sx={{
            width: 300,
            margin: '0 auto',
          }}
        />
        <Typography variant="caption" color="textSecondary" align="center">
          {transitionDuration} ms
        </Typography>
      </div>
      {sortingCompleted && (
        <div>
          <button onClick={() => setShowExplanation(!showExplanation)}>
            {showExplanation ? 'Hide Explanation' : 'Show Explanation'}
          </button>
          {showExplanation && (
            <div className='bubble-show'>
            {/* <h3>Bubble Sort Explanation</h3>
              <p>
                Bubble Sort is a simple sorting algorithm that repeatedly steps through the list, compares adjacent
                elements, and swaps them if they are in the wrong order. The pass through the list is repeated until
                the list is sorted.
              </p>
              <p>
                Here’s a step-by-step breakdown of the process:
              </p>
              <ol>
                <li><strong>Compare and Swap:</strong> Compare adjacent elements and swap them if necessary.</li>
                <li><strong>Repeat:</strong> Continue the process for the entire list, ignoring the last sorted elements.</li>
              </ol>
              <p>
                This animation shows how Bubble Sort repeatedly compares and swaps elements until the array is sorted.
              </p> */}

              <h3>Bubble Sort Explanation</h3>
              <p>
                Bubble Sort is a simple sorting algorithm that repeatedly steps through the list, compares adjacent elements, and swaps them if they are in the wrong order. Here’s a detailed explanation of how it works:
              </p>
              <div>
                <strong>Outer Loop:</strong>
                <pre>{`for (let i = 0; i < n - 1; i++) {}`}</pre>
                <p>
                  <strong>Explanation:</strong> 
                  The outer loop iterates through the entire array multiple times. Each pass through the array places the next largest element in its correct position.
                </p>
              </div>
              <div>
                <strong>Inner Loop:</strong>
                <pre>{`for (let j = 0; j < n - i - 1; j++) {}`}</pre>
                <p>
                  <strong>Explanation:</strong> 
                  The inner loop compares adjacent elements and performs swaps if necessary. It decreases the number of comparisons in each pass as the largest elements are placed in their final positions.
                </p>
              </div>
              <div>
                <strong>Swap Elements:</strong>
                <pre>{`[arr[j], arr[j + 1]] = [arr[j + 1], arr[j]];`}</pre>
                <p>
                  <strong>Explanation:</strong> 
                  When adjacent elements are found to be out of order, they are swapped to move the larger element towards the end of the array. This is done by swapping the elements in their current positions.
                </p>
              </div>
              <p>
                This process repeats until the array is sorted, meaning no more swaps are needed.
              </p>
              <h1>Geeks Explanation</h1>
              <h3>Bubble Sort Explanation</h3>
<p>
  Bubble Sort is a simple comparison-based algorithm where adjacent elements are compared and swapped if they are in the wrong order. The algorithm repeatedly steps through the list until no more swaps are needed. Below is the explanation using the provided optimized Bubble Sort code:
</p>

<div>
  <strong>BubbleSort Function:</strong>
  <pre>{`function bubbleSort(arr, n) {
    var i, j, temp;
    var swapped;
    for (i = 0; i < n - 1; i++) {
        swapped = false;
        for (j = 0; j < n - i - 1; j++) {
            if (arr[j] > arr[j + 1]) {
                // Swap arr[j] and arr[j+1]
                temp = arr[j];
                arr[j] = arr[j + 1];
                arr[j + 1] = temp;
                swapped = true;
            }
        }
        // IF no two elements were swapped by inner loop, then break
        if (swapped == false)
            break;
    }
}`}</pre>
  <p>
    <strong>Explanation:</strong> 
    The `bubbleSort` function iterates over the array and compares adjacent elements. If the first element is larger than the second, they are swapped. The inner loop ensures that the largest unsorted element bubbles up to the correct position. If no elements are swapped during a full pass, the array is already sorted, and the loop exits early.
  </p>
</div>

<div>
  <strong>Print Array Function:</strong>
  <pre>{`function printArray(arr, size) {
    var i;
    for (i = 0; i < size; i++)
        console.log(arr[i] + " ");
}`}</pre>
  <p>
    <strong>Explanation:</strong> 
    The `printArray` function loops through the array and prints each element on the same line, separated by a space. It is used to display both the initial unsorted array and the sorted array after applying Bubble Sort.
  </p>
</div>

<div>
  <strong>Driver Code:</strong>
  <pre>{`var arr = [ 64, 34, 25, 12, 22, 11, 90 ];
var n = arr.length;
bubbleSort(arr, n);
console.log("Sorted array: ");
printArray(arr, n);`}</pre>
  <p>
    <strong>Explanation:</strong> 
    This driver code initializes an unsorted array, prints the unsorted array, sorts it using `bubbleSort`, and prints the sorted array. It demonstrates the complete execution of the Bubble Sort algorithm.
  </p>
</div>

<p>
  Bubble Sort has a worst-case time complexity of O(n²) and is mainly useful for small datasets. The optimized version reduces unnecessary passes through the array when no swaps are made, making it slightly more efficient than the traditional implementation.
</p>

            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Bubble;
