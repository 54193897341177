// 


import React, { useState } from 'react';
import { Box, Button, Typography, TextField } from '@mui/material';
import './Tokenization.css'; // Ensure you have the appropriate CSS

const Tokenization = () => {
  const [text, setText] = useState('');
  const [tokens, setTokens] = useState([]);
  const [showExplanation, setShowExplanation] = useState(false);

  const tokenize = () => {
    // BPE-like tokenization simulation
    const tokenized = bpeTokenize(text);
    setTokens(tokenized);
  };

  // Simulated BPE-like tokenization function
  const bpeTokenize = (input) => {
    const tokens = [];
    const words = input.split(/\s+/);

    words.forEach(word => {
      // Simulate subword tokenization (naive approach for demo)
      if (word.length > 1) {
        for (let i = 1; i <= word.length; i++) {
          tokens.push({ token: word.slice(0, i), index: tokens.length });
        }
      } else {
        tokens.push({ token: word, index: tokens.length });
      }
    });

    return tokens;
  };

  return (
    <div className="tokenization-container bubble-cont">
      <Typography variant="h4" gutterBottom>Tokenization Engine</Typography>
      <TextField
        label="Enter text"
        variant="outlined"
        fullWidth
        value={text}
        onChange={(e) => setText(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Button variant="contained" onClick={tokenize}>Tokenize</Button>

      <div className="tokenization-box">
        {tokens.map(({ token, index }) => (
          <div key={index} className="token">
            <span className="token-index">{index}</span>
            <span className="token-value">{token}</span>
          </div>
        ))}
      </div>

      <div style={{ marginTop: '20px' }}>
        {/* <Button variant="outlined" onClick={() => setShowExplanation(!showExplanation)}>
          {showExplanation ? 'Hide Explanation' : 'Show Explanation'}
        </Button> */}
        {/* {showExplanation && (
          <div className="explanation-box ">
            <Typography variant="h3">How Tokenization Works</Typography>
            <Typography variant="h6">
              Tokenization involves breaking text into smaller units called tokens. In this simulation, we use a basic BPE-like approach to split words into subwords and characters.
            </Typography>
            <div>
              <strong>Initialization:</strong>
              <Typography variant="body1">
                The text is divided into words first. Each word is then further split into smaller units, simulating a subword tokenization process.
              </Typography>
            </div>
            <div>
              <strong>Create Tokens:</strong>
              <Typography variant="body1">
                For each word, we create tokens by progressively slicing the word into smaller chunks, resembling how BPE might handle subwords.
              </Typography>
            </div>
            <div>
              <strong>Display Tokens:</strong>
              <Typography variant="body1">
                Tokens and their positions are displayed, showing the division of text into smaller units for processing.
              </Typography>
            </div>
            <Typography variant="body1">
              This simulation provides a basic overview of subword tokenization. In practice, more advanced techniques like BPE or Unigram models are used to handle complex tokenization tasks.
            </Typography>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Tokenization;
