// import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import  './Footer.css'
// // import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';

// import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
// const Footer = () => {
//   return (
//     <footer style={footerStyle}>
//       <div>
//       <div className="social-icons">
//         <a href="#" className="social-icon"><FontAwesomeIcon icon={faFacebook} /></a>
        
//         <a href="#" className="social-icon"><FontAwesomeIcon icon={faTwitter} /></a>
//         <a href="#" className="social-icon"><FontAwesomeIcon icon={faInstagram} /></a>
//        </div>
//         {/* Add your footer content here, e.g., links to careers and other elements */}
//         <h2>Careers</h2>
        
//         {/* Other footer elements go here */}
//       </div>
//     </footer>
//   );
// };

// const footerStyle = {
//   position: 'fixed',
//   bottom: 0,
//   left: 0,
//   width: '100%',
//   height: '50px',
//   backgroundColor: '#333333',
//   color: 'black',
//   textAlign: 'center',
//   padding: '2px',
// };

// export default Footer;


// import React from 'react'

// function Footer() {
//   return (
//     <div>
//          <div class="footer-content">
//     <p>© 2024 Olayinka Fakanbi. All rights reserved.</p>
//     <p>OlaFolio</p>
//     {/* <p>[Your Email Address]</p> */}
//     {/* <p>[Your Phone Number]</p> */}
//   </div>
//     </div>
//   )
// }

// export default Footer
import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import EmailSubscription from './EmailSubscription';
import Checkbox from '@mui/material/Checkbox';

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const Footer = () => (

  
  <footer className='footer-container' style={{ color:'white' , backgroundColor: '#171515', padding: '25px'}}>

  
  {/* <Box sx={{ flexGrow: 2 }}>
      <Grid container spacing={3}> */}
        {/* <Grid md={3}>
        <div></div>
          <h6> SUPPORT</h6>
          <p className="footer-p-tags">Live Chat</p>
          <p className="footer-p-tags">Tutorial & guides</p>
          <p className="footer-p-tags">OlaFolio Courses</p>
          <p className="footer-p-tags">Support Contact</p>
          <p className="footer-p-tags">Terms of Use</p>
        </Grid> */}

        {/* <Grid md={3}>
          <h6> COMPANY INFORMATION </h6>
          <p className="footer-p-tags">About Us</p>
          <p className="footer-p-tags">Team</p>
          <p className="footer-p-tags">AI Model Competition</p>
          <p className="footer-p-tags">OlaFolio Blog</p>
          <p className="footer-p-tags">Careers</p>
          <p className="footer-p-tags">Partners</p>
        </Grid> */}

        {/* <Grid md={3}>
          <h6> GENERAL INFORMATION</h6>
          <p className="footer-p-tags">Web Privacy Policy</p>
          <p className="footer-p-tags">Cookies settings</p>
          <p className="footer-p-tags">Recycling</p>
          <p className="footer-p-tags">Sitemap</p>
          <p className="footer-p-tags">Students Discount</p>
          <p className="footer-p-tags">Faq</p>
        </Grid> */}

        {/* <Grid md={3}>
          <EmailSubscription/>
          <p><Checkbox {...label} /> Subscribe to OlaFolio Newsletter</p>
          <Button type="submit" variant="outlined">Subscribe</Button>
        </Grid>


      </Grid> */}
    {/* </Box> */}
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {/* <a href="https://www.facebook.com/" target="_blank" rel="noreferrer" style={{ margin: '0 10px' }}><img width="35px" height="35px" src="https://cdn-icons-png.flaticon.com/512/2504/2504903.png"></img>
        <i className="fab fa-facebook-square" style={{ fontSize: '24px' }} />
      </a> */}
      <a href="https://twitter.com/i/flow/login" target="_blank" rel="noreferrer" style={{ margin: '0 10px' }}><img width="35px" height="35px" src="https://cdn-icons-png.flaticon.com/512/2504/2504947.png"></img>
        <i className="fab fa-twitter-square" style={{ fontSize: '24px' }} />
      </a>
      {/* <a href="https://www.instagram.com/" target="_blank" rel="noreferrer" style={{ margin: '0 10px' }}><img width="35px" height="35px"  src="https://cdn-icons-png.flaticon.com/512/1409/1409946.png"></img>
        <i className="fab fa-instagram" style={{ fontSize: '24px' }} />
      </a> */}
      <a href="https://www.linkedin.com/" target="_blank" rel="noreferrer" style={{ margin: '0 10px' }}><img width="35px" height="35px"  src="https://cdn-icons-png.flaticon.com/512/174/174857.png?w=1380&t=st=1675449932~exp=1675450532~hmac=e99e636cce58f116999b10c6f776d9c89d1317339e7f10acc64ec58fe9350a93"></img>
        <i className="fab fa-linkedin" style={{ fontSize: '24px' }} />
      </a>
      <a href="https://github.com/" target="_blank" rel="noreferrer" style={{ margin: '0 10px' }}><img width="35px" height="35px"  src="https://cdn-icons-png.flaticon.com/512/2504/2504911.png"></img>
        <i className="fab fa-linkedin" style={{ fontSize: '24px' }} />
      </a>
   
    </div>
    <p style={{ textAlign: 'center', fontSize: '14px', marginTop: '20px' }}>
      &copy; {new Date().getFullYear()} OlaFolio | All rights reserved
    </p>
  </footer>
);

export default Footer;



