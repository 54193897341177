import React from 'react'
import transformer from '../assets/transformer.png'
import tra1 from '../assets/tra1.png'
import Button from '@mui/material/Button';
import './Transformer.css';
// import quantum from '../assets/quantum.jpg'
// import qvid from '../assets/qvid.mp4'

function Transformer() {
  return (
    <div className="container">
    <h2 className="heading">Transformer AI</h2>
    <form className="form">
    <h2>Transformer AI, a groundbreaking technology in the field of artificial intelligence, has emerged as a game-changer, revolutionizing industries and shaping the future of computing. With its ability to process vast amounts of data and perform complex tasks with unparalleled accuracy, Transformer AI is poised to drive innovation across various sectors. In this article, we explore the transformative power of Transformer AI and its profound impact on industries worldwide.</h2>
<img src={tra1} alt="Quantum Computing" className="pfp1 animate__backInDown" />
    <h2>One of the most significant contributions of Transformer AI is its advancements in natural language processing (NLP). Through models such as BERT (Bidirectional Encoder Representations from Transformers) and GPT (Generative Pre-trained Transformer), Transformer AI has achieved remarkable feats in understanding and generating human-like text. From chatbots and virtual assistants to language translation and sentiment analysis, Transformer AI has unlocked new possibilities in NLP, revolutionizing communication and information retrieval.</h2>
    {/* <iframe className="pfp1" alt="pfp" src={qvid} width="auto" height="auto" autoplay="false"></iframe> */}
    <h2>Beyond text, Transformer AI has also revolutionized image and video understanding, enabling machines to interpret and analyze visual data with unprecedented accuracy. Models like ViT (Vision Transformer) and CLIP (Contrastive Language-Image Pretraining) leverage Transformer architectures to extract meaningful information from images and videos, powering applications such as object detection, image recognition, and content moderation. Transformer AI's ability to process multimodal data is reshaping industries ranging from healthcare and agriculture to entertainment and surveillance.</h2>
    <img src={transformer} alt="Quantum Computing" className="pfp1 animate__backInDown" />
    <h2>Transformer AI is driving advancements in autonomous systems and robotics, paving the way for intelligent machines capable of perception, decision-making, and action. Through reinforcement learning and self-attention mechanisms, Transformer-based models enable robots to navigate complex environments, manipulate objects, and interact with humans more intuitively. From autonomous vehicles and drones to robotic assistants and industrial automation, Transformer AI is revolutionizing the way we perceive and interact with machines, unlocking new frontiers in robotics.</h2>
    <Button variant="contained" href="/" target="_blank">
 Back
</Button>
    </form>
    
    </div>
  )
}

export default Transformer