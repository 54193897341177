import React from 'react';
import "./QuantumComp.css"

function QuantumComp() {
  return (
    <div className="container">
    <div className="heading">
   <h1>Quantum Computing Curriculum</h1> 
       
        <div className="quantum-plan">
      <h1>Becoming an Expert in Quantum Computing</h1>
      <p>Becoming an expert in quantum computing within two months is an ambitious goal, but with a structured and intensive study plan, you can make significant progress. Here's a detailed curriculum and schedule to help you achieve this goal.</p>

      <h2>Month 1: Foundational Knowledge</h2>
      <h3>Week 1: Introduction to Quantum Computing</h3>
      <ul>
        <h2><strong>Day 1-2:</strong> Basics of Quantum Mechanics
          <h2>
            <li>Study resources: MIT OpenCourseWare on Quantum Mechanics, Khan Academy's Quantum Physics section.</li>
            <li>Key topics: Superposition, Entanglement, Quantum states.</li>
          </h2>
        </h2>
        <li><strong>Day 3-4:</strong> Introduction to Quantum Computing
          <ul>
            <li>Study resources: IBM's Quantum Computing Beginner's Guide, "Quantum Computing for the Very Curious" by Andy Matuschak and Michael Nielsen.</li>
            <li>Key topics: Qubits, Quantum Gates, Quantum Circuits.</li>
          </ul>
        </li>
        <li><strong>Day 5-7:</strong> Mathematics for Quantum Computing
          <ul>
            <li>Study resources: "Mathematics for Quantum Computing" by Mark Wilde, Khan Academy Linear Algebra.</li>
            <li>Key topics: Complex numbers, Linear algebra (vectors, matrices), Probability theory.</li>
          </ul>
        </li>
      </ul>

      <h3>Week 2: Quantum Computing Models and Algorithms</h3>
      <ul>
        <li><strong>Day 8-10:</strong> Quantum Gate Model
          <ul>
            <li>Study resources: IBM Qiskit Textbook, "Quantum Computation and Quantum Information" by Nielsen and Chuang (Chapter 4).</li>
            <li>Key topics: Single and multi-qubit gates, Circuit design.</li>
          </ul>
        </li>
        <li><strong>Day 11-14:</strong> Quantum Algorithms
          <ul>
            <li>Study resources: MIT OpenCourseWare on Quantum Algorithms, Qiskit tutorials.</li>
            <li>Key topics: Deutsch-Josza Algorithm, Grover's Algorithm, Shor's Algorithm.</li>
          </ul>
        </li>
      </ul>

      <h3>Week 3: Quantum Programming</h3>
      <ul>
        <li><strong>Day 15-18:</strong> Introduction to Qiskit
          <ul>
            <li>Study resources: Qiskit Textbook, IBM Q Experience tutorials.</li>
            <li>Key topics: Setting up Qiskit, Basic quantum circuits, Running simulations.</li>
          </ul>
        </li>
        <li><strong>Day 19-21:</strong> Writing Quantum Programs
          <ul>
            <li>Study resources: Qiskit Documentation, Hands-on Qiskit.</li>
            <li>Key topics: Creating and executing quantum programs, Visualizing quantum states.</li>
          </ul>
        </li>
      </ul>

      <h3>Week 4: Advanced Quantum Computing Concepts</h3>
      <ul>
        <li><strong>Day 22-24:</strong> Quantum Error Correction
          <ul>
            <li>Study resources: "Quantum Error Correction" by Daniel Gottesman, IBM Qiskit Textbook.</li>
            <li>Key topics: Quantum errors, Stabilizer codes, Surface codes.</li>
          </ul>
        </li>
        <li><strong>Day 25-27:</strong> Quantum Supremacy and NISQ Era
          <ul>
            <li>Study resources: Google AI Blog on Quantum Supremacy, Research papers on NISQ.</li>
            <li>Key topics: Noisy Intermediate-Scale Quantum (NISQ) technology, Quantum supremacy experiments.</li>
          </ul>
        </li>
        <li><strong>Day 28-30:</strong> Review and Practice
          <ul>
            <li>Consolidate knowledge by revisiting key topics and working on exercises from the Qiskit Textbook.</li>
          </ul>
        </li>
      </ul>

      <h2>Month 2: Application and Specialization</h2>
      <h3>Week 1: Quantum Machine Learning</h3>
      <ul>
        <li><strong>Day 31-33:</strong> Introduction to Quantum Machine Learning
          <ul>
            <li>Study resources: "Quantum Machine Learning" by Peter Wittek, Qiskit Machine Learning Module.</li>
            <li>Key topics: Quantum data encoding, Quantum neural networks.</li>
          </ul>
        </li>
        <li><strong>Day 34-37:</strong> Quantum Algorithms for Machine Learning
          <ul>
            <li>Study resources: Research papers on Quantum Machine Learning, Qiskit tutorials.</li>
            <li>Key topics: Quantum SVM, Quantum PCA, Quantum Boltzmann Machines.</li>
          </ul>
        </li>
      </ul>

      <h3>Week 2: Quantum Cryptography</h3>
      <ul>
        <li><strong>Day 38-40:</strong> Basics of Quantum Cryptography
          <ul>
            <li>Study resources: "Quantum Cryptography: An Introduction" by Eleanor Rieffel and Wolfgang Polak, IBM Qiskit Textbook.</li>
            <li>Key topics: Quantum key distribution, BB84 protocol, Quantum-secure encryption.</li>
          </ul>
        </li>
        <li><strong>Day 41-44:</strong> Quantum Safe Cryptography
          <ul>
            <li>Study resources: NIST Post-Quantum Cryptography Project, Research papers.</li>
            <li>Key topics: Post-quantum cryptographic algorithms, Implementing quantum-resistant protocols.</li>
          </ul>
        </li>
      </ul>

      <h3>Week 3: Quantum Computing Hardware</h3>
      <ul>
        <li><strong>Day 45-47:</strong> Quantum Hardware Basics
          <ul>
            <li>Study resources: IBM Q Experience, Rigetti Computing Documentation.</li>
            <li>Key topics: Qubit technologies (superconducting, ion trap, photonic), Quantum coherence.</li>
          </ul>
        </li>
        <li><strong>Day 48-51:</strong> Building and Using Quantum Hardware
          <ul>
            <li>Study resources: IBM Qiskit Hardware Module, Quantum hardware research papers.</li>
            <li>Key topics: Quantum circuit execution on real hardware, Noise in quantum systems.</li>
          </ul>
        </li>
      </ul>

      <h3>Week 4: Capstone Project and Review</h3>
      <ul>
        <li><strong>Day 52-54:</strong> Capstone Project Planning
          <ul>
            <li>Choose a project that involves creating a quantum algorithm or application. Examples: Quantum optimization for logistics, Quantum cryptography protocol implementation.</li>
          </ul>
        </li>
        <li><strong>Day 55-57:</strong> Capstone Project Development
          <ul>
            <li>Develop your project using Qiskit or other quantum programming tools.</li>
          </ul>
        </li>
        <li><strong>Day 58-60:</strong> Project Presentation and Review
          <ul>
            <li>Prepare a presentation for your project, review your learning, and identify areas for further study.</li>
          </ul>
        </li>
      </ul>

      <h2>Example Timetable</h2>
      <table>
  <thead>
    <tr>
      <th>Week</th>
      <th>Monday</th>
      <th>Wednesday</th>
      <th>Thursday</th>
      <th>Comments</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Week 1</td>
      <td><a href="https://learning.quantum.ibm.com/course/basics-of-quantum-information/quantum-circuits" target="_blank">Quantum Mechanics</a></td>
      <td><a href="https://learning.quantum.ibm.com/course/basics-of-quantum-information/single-systems" target="_blank">Quantum Computing</a></td>
      <td><a href="https://example.com/quantum-computing" target="_blank">Quantum Computing</a></td>
      <td><textarea placeholder="Add comments here..."></textarea></td>
    </tr>
    <tr>
      <td>Week 2</td>
      <td><a href="https://example.com/quantum-gates" target="_blank">Quantum Gates</a></td>
      <td><a href="https://example.com/quantum-algorithms" target="_blank">Quantum Algorithms</a></td>
      <td><a href="https://example.com/quantum-algorithms" target="_blank">Quantum Algorithms</a></td>
      <td><textarea placeholder="Add comments here..."></textarea></td>
    </tr>
    <tr>
      <td>Week 3</td>
      <td><a href="https://example.com/qiskit-setup" target="_blank">Qiskit Setup</a></td>
      <td><a href="https://example.com/quantum-programs" target="_blank">Quantum Programs</a></td>
      <td><a href="https://example.com/quantum-programs" target="_blank">Quantum Programs</a></td>
      <td><textarea placeholder="Add comments here..."></textarea></td>
    </tr>
    <tr>
      <td>Week 4</td>
      <td><a href="https://example.com/error-correction" target="_blank">Error Correction</a></td>
      <td><a href="https://example.com/nisq-era" target="_blank">NISQ Era</a></td>
      <td><a href="https://example.com/nisq-era" target="_blank">NISQ Era</a></td>
      <td><textarea placeholder="Add comments here..."></textarea></td>
    </tr>
    <tr>
      <td>Week 5</td>
      <td><a href="https://example.com/qml-intro" target="_blank">QML Intro</a></td>
      <td><a href="https://example.com/qml-algorithms" target="_blank">QML Algorithms</a></td>
      <td><a href="https://example.com/qml-algorithms" target="_blank">QML Algorithms</a></td>
      <td><textarea placeholder="Add comments here..."></textarea></td>
    </tr>
    <tr>
      <td>Week 6</td>
      <td><a href="https://example.com/quantum-cryptography" target="_blank">Quantum Cryptography</a></td>
      <td><a href="https://example.com/quantum-safe-cryptography" target="_blank">Quantum Safe Cryptography</a></td>
      <td><a href="https://example.com/quantum-safe-cryptography" target="_blank">Quantum Safe Cryptography</a></td>
      <td><textarea placeholder="Add comments here..."></textarea></td>
    </tr>
    <tr>
      <td>Week 7</td>
      <td><a href="https://example.com/quantum-hardware" target="_blank">Quantum Hardware</a></td>
      <td><a href="https://example.com/quantum-hardware" target="_blank">Quantum Hardware</a></td>
      <td><a href="https://example.com/quantum-hardware" target="_blank">Quantum Hardware</a></td>
      <td><textarea placeholder="Add comments here..."></textarea></td>
    </tr>
    <tr>
      <td>Week 8</td>
      <td><a href="https://example.com/project-planning" target="_blank">Project Planning</a></td>
      <td><a href="https://example.com/project-development" target="_blank">Project Development</a></td>
      <td><a href="https://example.com/project-development" target="_blank">Project Development</a></td>
      <td><textarea placeholder="Add comments here..."></textarea></td>
    </tr>
  </tbody>
</table>

    </div>
    </div>
    
    </div>
  )
}

export default QuantumComp