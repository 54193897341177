import React from 'react';
import Button from '@mui/material/Button';
import snake from '../assets/snake.png';
import Sgame from "../assets/Sgame.MP4";
// import Semi from "../assets/Semi.MP4";
import  './SnakeGame.css'
import ArduinoP from './ArduinoP';

function SnakeGame() {
  return (
    <div className="container">
        <div className="heading">
        <form className="form">
        <h2>C++ Interactive Snake Game on Arduino with LED Matrix Display</h2>
    <p><strong>Date:</strong> [Project Date]</p>
    <p><strong>Technologies Used:</strong> Arduino Uno, LED Matrix, C/C++, Joystick</p>
    <div class="project-description">
      <h3>Project Description</h3>
      <p>A fun and interactive Snake Game implemented using an Arduino Uno and an 8x8 LED matrix display...</p>
    </div>
    <div class="project-features">
      <h3>Project Features</h3>
      
        <p>User Controls: Control the snake using a joystick.</p>
        <p>Dynamic Gameplay: Snake grows longer as it eats apples.</p>
        <p>Edge Wrapping: When the snake moves off one edge.</p>
        <p>Collision Detection: Game resets when the snake collides with itself.</p>
        <p>Real-Time Display: Smooth animation of the snake on an 8x8 LED matrix.</p>
      
    </div>
    <div class="intro2">
      <div className='port-2'>
      <h3>Visuals and Demonstration</h3>
      {/* <img loading="lazy" src={snake} alt="Quantum Computing" className="pfp1" /> */}
      <iframe className="pfp1" alt="pfp" src={Sgame} width="100%" height="350px"  autoplay="0" muted></iframe>
      {/* <iframe className="pfp1" alt="pfp" src={Semi} width="100%" height="350px"  autoplay="0" muted></iframe> */}

      {/* <img src="path_to_gif.gif" alt="Project GIF" /> */}
      </div>
      
    </div>
    <div class="project-details">
      <h3>Technical Implementation</h3>
      <p>Details about the components, libraries, and code snippets used in the project...</p>
    </div>
    <div class="project-challenges">
      <h3>Challenges and Solutions</h3>
      <p>Discuss any challenges faced during the development...</p>
    </div>
    <div class="project-learnings">
      <h3>Learning Outcomes</h3>
      <p>What you learned from the project...</p>
    </div>
    <div class="project-links">
      <h3>Source Code and Documentation</h3>
      <a href="https://github.com/Olayinka19/Arduino_Temp_Project" target="_blank">GitHub Repository</a>
    </div>
    <div class="project-future">
      <h3>Future Enhancements</h3>
      <p>Ideas for future improvements...</p>

      <p>Multiplayer Mode</p>
      <p>Advanced Graphics (apple, etc)</p>
      <p>Sound Effects (eating apple, colliding with wall or itself)</p>
      <p>Game Mode Challenges</p>
    </div>

    <ArduinoP/>
        </form>

        </div>
    </div>
  )
}

export default SnakeGame