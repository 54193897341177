import React, { useState } from 'react';
import * as d3 from 'd3';
// import './DSA.css';

const StringVisualization = () => {
  const [str, setStr] = useState("hello world");
  const [showExplanation, setShowExplanation] = useState(false);

  const drawString = () => {
    const svg = d3.select('#string-svg');
    svg.html('');
    svg.selectAll('text')
      .data(str.split(''))
      .enter().append('text')
      .attr('x', (d, i) => i * 20)
      .attr('y', 50)
      .text(d => d)
      .attr('font-size', '20px')
      .attr('fill', '#69b3a2');
  };

  const handleInputChange = (e) => {
    setStr(e.target.value);
  };

  return (
    <div>
      <h2>String Visualization</h2>
      <input type="text" placeholder="Enter string" onChange={handleInputChange} />
      <button onClick={drawString}>Draw String</button>
      <svg id="string-svg" width="500" height="100"></svg>
      <button onClick={() => setShowExplanation(!showExplanation)}>
        {showExplanation ? 'Hide Explanation' : 'Show Explanation'}
      </button>
      {showExplanation && (
        <div>
          <h3>String Visualization Explanation</h3>
          <p>This visualization shows each character of a string as a separate text element. The characters are spaced horizontally based on their index in the string.</p>
          <pre>
            {`const drawString = () => {
              const svg = d3.select('#string-svg');
              svg.html('');
              svg.selectAll('text')
                .data(str.split(''))
                .enter().append('text')
                .attr('x', (d, i) => i * 20)
                .attr('y', 50)
                .text(d => d)
                .attr('font-size', '20px')
                .attr('fill', '#69b3a2');
            };`}
          </pre>
        </div>
      )}
    </div>
  );
};

export default StringVisualization;
