import React from 'react';
import Azureai from '../assets/Azureai.jpeg'

function Certifications() {
  return (
    <div>
        <h1>Certifications</h1>
        <div className='intro2'>
            <div className='port-2'>
            <img loading="lazy" className="pfp1" alt="pfp" src={Azureai} width="200px"></img>
            </div>
        </div>
    </div>
  )
}

export default Certifications;