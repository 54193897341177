import React from 'react'
import tensor from '../assets/tensor.png';
import Tens from '../assets/Tens.mp4'
import google from '../assets/google.MP4'
import Button from '@mui/material/Button';

function Tensor() {
  return (
    <div className="container">
    <h2 className="heading">TensorFlow Tutorial:</h2>
    <form className='form'>
    
    <h2>
    <ol>
    <li>TensorFlow makes it easy to create ML models that can run in any environment. Learn how to use the intuitive APIs through interactive code samples.</li>
        <li>Basic concepts of flex containers and flex items.</li>
        <li>Implementing a simple flex grid layout for a webpage.</li>
        <li>Exploring common use cases and best practices for flexbox.</li>

    </ol>
        
    <img src={tensor} alt="html file" height="600" width="100%" className="pfp1 animate__backInDown" />
 </h2>
 <iframe className="pfp1" alt="pfp" src={Tens} width="100%" height="350px"></iframe>
<Button variant="contained" href="https://codelabs.developers.google.com/tensorflowjs-transfer-learning-teachable-machine#0" target="_blank">
Make your own "Teachable Machine" model ☞
</Button>
<iframe className="pfp1" alt="pfp" src={google} width="100%" height="350px"></iframe>
<Button variant="contained" href="https://colab.research.google.com/github/tensorflow/docs/blob/master/site/en/tutorials/quickstart/beginner.ipynb" target="_blank">
TensorFlow 2 quickstart for beginners ☞
</Button>
  
    </form>
    </div>
  )
}

export default Tensor