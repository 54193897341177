// ... Previous code ...
import React, {useState} from 'react';
import Button from '@mui/material/Button';
import ahead3 from "../assets/ahead3.png";
import game from "../assets/game.png";
import pawster2 from "../assets/pawster2.png";
import rise from "../assets/rise.png";
import AheadSt2 from "../assets/AheadSt2.png";
import AheadSt from "../assets/AheadSt.png";
import connect from "../assets/connect.gif";
import nft from "../assets/nft.png"
import coin from "../assets/coin.jpg"
import OlaF1 from "../assets/OlaF1.png"
import OlaFolio from "../assets/OlaFolio.png"
import FaceRecognition from "./FaceRecognition";
import SentimentAnalysis from './SentimentAnalysis';
// import project3 from '../assets/project3.png'

const projectsData = [
  {
    title: 'Ola-Pawster Capstone Project',
    image: 'https://ola-pawster.netlify.app/',
    
    description: [
      "Ola-Pawster Capstone Project serves as a prominent demonstration of my adept SQL skills in crafting a sophisticated database-driven solution that seamlessly connects pets, users, and shelter homes within an intuitive web platform.",
      "Formulated a well-structured schema that effectively organizes and preserves pivotal data concerning users, pets, and shelter homes.",
      "Led and managed small-sized projects and components of larger projects/programs through their entire lifecycle.",
    ],
  
    githubLink: 'https://github.com/Olayinka19/Pawster-capstone-project',
  },
  {
    title: 'Pursuit Connect',
    image: '',
    description: [
      "Optimized PostgreSQL databases for dependable data storage, rapid retrieval, and secure handling of sensitive information.",
      "Designed captivating user interfaces with JavaScript, React, ensuring seamless adaptability across devices.",
      "Built a robust server-side foundation with Express for efficient data flow and rapid API development.",
    ],
    githubLink: 'https://github.com/Olayinka19',
  },
];
const Projects = () => {
  const [currentProject, setCurrentProject] = useState(0);
  const nextProject = () => {
    setCurrentProject((prevProject) => (prevProject + 1) % projectsData.length);
  };

    return (
      <div className="container">
     
        {/* <section className="projects"> */}
        {/* <br /> */}
        {/* <br /> */}
        {/* <br /> */}
        
          <h2>TECHNICAL PROJECTS</h2>
      
          {/* <form className="form"> */}
          

{/* Capstone Project: Github
- Worked with a team of 5 Developers  to build a responsive web application using React, Tailwind CSS, and React Bootstrap.
- Co-designed and implemented backend services with Express.js and PostgreSQL
- Utilized Git and GitHub for version control and effective team collaboration.
- Managed deployment with Netlify for the frontend and Heroku for the backend, ensuring seamless delivery.

Weather Application Project: Github
- Collaboratively Developed and Deployed 8-4 Weather App Project
- Implemented API integration to fetch real-time weather data using the wttr.in API.
- Conducted thorough testing with Cypress to ensure application reliability and robustness.
- Employed continuous integration using CircleCI for automated testing and deployment.


Budgeting Application Project: Github
- Created a budgeting application using React and JavaScript.
- Conducted thorough testing with Jest to ensure application reliability and robustness.
- Designed and implemented user-friendly UI components with React.
- Utilized Git and GitHub for version control and team collaboration.

Tuner Full Stack App : Github
- Partnered with a team to create a music playlist application using Express.js, PostgreSQL, and React.
- Designed and implemented RESTful APIs for song management, including create, read, update, and delete functionalities.
- Managed deployment with Heroku for seamless backend delivery and updates.

Captain Log React App: Github
- Created a log management application using React, JavaScript, and CSS.
- Designed and implemented user-friendly UI components and navigation.
- Utilized Git and GitHub for version control and effective team collaboration.
- Established deployment pipelines on Netlify for rapid and reliable application deployment.

Student Dashboard : Github
- Created a dynamic student dashboard using React, JavaScript, HTML, and CSS..
- Designed and implemented key features such as student lists, cohort lists, and interactive cohort filtering.
- Conducted thorough testing with Cypress to ensure application reliability and robustness.
- Managed deployment with Netlify for seamless delivery and updates.

Bird Sanctuary Project : Github
- Created a bird sanctuary donation app using React, JavaScript, HTML, and CSS.
- Implemented features such as bird listing, cart management, discounts, and bonus item rewards.
- Employed continuous integration using CircleCI for automated testing and deployment.
- Managed deployment with Netlify for seamless delivery and updates. */}

        
  
          {/* </form> */}
        {/* </section> */}
        {/* <section className="video-section">
  <h2>Portfolio Highlight Reel</h2>
  <iframe
    width="360"
    height="315"
    src="https://www.youtube.com/embed/YOUR_VIDEO_ID"
    title="Portfolio Highlight Reel"
    frameborder="0"
    allowfullscreen
  ></iframe>
</section> */}
        {/* <section className="featured-projects">
        <h2>Featured Projects</h2>
        <div className="featured-project">
          <div className="project-image" style={{ backgroundImage: "url('/featured-project.jpg')" }}></div>
          <div className="project-details">
            <h3>Featured Project Title</h3>
            <h2>Description of the featured project.</h2>
            <a href="#">View Project</a>
          </div>
        </div>
      </section> */}
        
      {/* <nav className="navigation">
        <ul>
          <li onClick={() => scrollToSection('about')}>About</li>
          <li onClick={() => scrollToSection('events')}>Events</li>
          <li onClick={() => scrollToSection('projects')}>Projects</li>
          <li onClick={() => scrollToSection('testimonials')}>Testimonials</li>
          <li onClick={() => scrollToSection('blog')}>Blog</li>
          <li onClick={() => scrollToSection('contact')}>Contact</li>
        </ul>
      </nav> */}
      {/* <Button variant="outlined" href="/" target="_blank">
 Back
</Button> */}
      </div>
    );
  };
  
  export default Projects;
  