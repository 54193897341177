// src/Components/GenerativeAI.js

import React from 'react';
import Generative from '../assets/Generative.png'
import  './Generative.css'
import ai_dev from "../assets/ai_dev.MP4";

const GenerativeAI = () => {
  return (
    <div className="container" >
    <div style={{ padding: '20px', color: '#333', fontFamily: 'Arial, sans-serif' }}>
      <h1 className="heading">Generative AI Certification</h1>
     
      <form className="form">
      {/* <img loading="lazy" className="pfp1" href="" alt="pfp" src={Generative} width="300px"></img> */}
      <p>
        As a technology enthusiast, diving into the realm of Generative AI felt like the perfect opportunity to expand my horizons. Recently, I completed a Generative AI certification course, and I am excited to share the knowledge and skills I have acquired. This journey has been transformative, providing deep insights into one of the most innovative areas of artificial intelligence.
      </p>
      
      <h2>Discovering Generative AI</h2>
      <p>
        Generative AI refers to systems that can create new content, such as images, music, text, or even entire virtual worlds, by learning patterns from existing data. The certification course I undertook offered a comprehensive exploration of these technologies, covering various models and their applications in the real world.
      </p>
      
      <h2>Course Highlights and Learning Outcomes</h2>
      <p>
        Throughout the certification process, I delved into several core aspects of Generative AI. Here are some highlights from my learning journey:
      </p>
      <h3>1. Introduction to Generative Models</h3>
      <p>
        This section provided an overview of different generative models, including Generative Adversarial Networks (GANs), Variational Autoencoders (VAEs), and Transformer models. Understanding these foundational concepts was crucial for grasping how generative AI works.
      </p>
      <h3>2. Deep Dive into GANs</h3>
      <p>
        One of the most fascinating parts of the course was learning about GANs. I explored how GANs consist of two neural networks—a generator and a discriminator—that work together to create realistic data. The practical exercises in this section were particularly enlightening, as I had the chance to generate synthetic images.
      </p>
      <h3>3. Natural Language Processing (NLP) with Transformers</h3>
      <p>
        Another exciting aspect of the course was applying generative AI to text through Transformer models, such as GPT-3. I learned how these models can generate coherent and contextually relevant text, opening up new possibilities for applications in content creation and chatbots.
      </p>
      
      <h2>Applying What I've Learned</h2>
      <p>
        To bring my theoretical knowledge to life, I created a short demo video showcasing a practical project I developed during the course. In the video, I demonstrate how to use a GAN to generate realistic images from scratch. This hands-on project was not only a great way to apply what I’ve learned but also an opportunity to share my newfound skills with others.
      </p>
      <p>
        Creating the demo was an excellent way to solidify my understanding and share the magic of Generative AI. By walking through the project step-by-step, I aim to inspire others to explore the fascinating world of AI.
      </p>
      
      <h2>Why Generative AI Certification Matters</h2>
      <p>
        Earning the Generative AI certification has been a game-changer. It has equipped me with cutting-edge skills and a deeper understanding of how to leverage these advanced AI models. This certification not only boosts my professional credibility but also opens up new opportunities for career growth in the rapidly evolving field of AI.
      </p>
      
      <h2>Final Thoughts</h2>
      <p>
        My journey through the Generative AI certification has been incredibly rewarding. The practical skills and knowledge I’ve gained empower me to tackle real-world challenges and contribute meaningfully to the field of artificial intelligence. I highly recommend this certification to anyone interested in AI, whether you are just starting out or looking to deepen your expertise.
      </p>
      <p>
        Stay tuned for my demo video, where I’ll showcase the practical aspects of Generative AI and share more insights from my learning journey. Thank you for joining me on this exciting adventure into the world of artificial intelligence!
      </p>
      </form>
      
      <div >
        <iframe className="pfp1" alt="pfp" src={ai_dev} width="100%" height="350px"  autoplay="0" muted></iframe>
        <br/>
          <h2>Description: Ai_Config Extension</h2>
        </div>
    </div>
    </div>
  );

};

export default GenerativeAI;
