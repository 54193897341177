// src/Components/VertexAIArticle.js

import React from 'react';
import ai_dev from "../assets/ai_dev.MP4";

const VertexAI = () => {
  return (
    <div style={{ padding: '20px', color: '#333', fontFamily: 'Arial, sans-serif' }}>
    {/* <div>

    </div> */}
      <h1 className="heading">Exploring My Journey Through Google Cloud Vertex AI Certification</h1>
      <form className="form">
      <h3>
        In an era where artificial intelligence (AI) is reshaping industries, I decided to deepen my understanding by pursuing a Google Cloud Vertex AI certification. This journey through Google Cloud’s learning platform has been both enlightening and rewarding. I'm excited to share my experiences and the valuable insights I've gained from this certification.
      </h3>
      
      <h2>Discovering Google Cloud Vertex AI</h2>
      <h3>
        Google Cloud Vertex AI is a comprehensive machine learning (ML) platform designed to make it easier to build, deploy, and scale ML models. The certification course provided a thorough introduction to Vertex AI’s capabilities and how it can be leveraged to solve real-world problems.
      </h3>
      
      <h2>Course Highlights and Learning Outcomes</h2>
      <h3>
        Throughout the certification process, I explored various key aspects of Vertex AI. Here are some of the highlights:
      </h3>
      <h3>1. Introduction to Vertex AI</h3>
      <h3>
        This section provided an overview of Vertex AI, its features, and how it integrates with other Google Cloud services. Understanding the basics was crucial for appreciating the more advanced topics covered later in the course.
      </h3>
      <h3>2. Building and Training Models</h3>
      <h3>
        A significant portion of the course focused on building and training ML models using Vertex AI. I learned about the different types of models, how to prepare data, and how to use Vertex AI’s tools to streamline the training process.
      </h3>
      <h3>3. Deploying and Managing Models</h3>
      <h3>
        Another crucial aspect of the course was learning how to deploy and manage models. I gained practical experience in deploying models to production, monitoring their performance, and managing their lifecycle using Vertex AI’s features.
      </h3>
      
      <h2>Applying What I've Learned</h2>
      <h3>
        To bring the theoretical knowledge to life, I created a short demo video showcasing a practical project I developed during the course. In the video, I demonstrate how to use Vertex AI to build and deploy a machine learning model for image classification. This hands-on project not only reinforced my learning but also showcased the real-world applications of the skills I acquired.
      </h3>
      <h3>
        Creating the demo was a fantastic way to solidify my understanding and share my newfound skills with others. By walking through the project step-by-step, my goal is to demystify AI and inspire others to explore its possibilities.
      </h3>
      
      <h2>Why Google Cloud Vertex AI Certification Matters</h2>
      <h3>
        Earning the Google Cloud Vertex AI certification has been a transformative experience. It has equipped me with essential AI skills and a deeper understanding of how to leverage Google Cloud’s powerful AI services. This certification not only boosts my professional credibility but also opens up new opportunities for career advancement in the rapidly growing field of AI.
      </h3>
      
      <h2>Final Thoughts</h2>
      <h3>
        My journey through the Google Cloud Vertex AI certification has been incredibly rewarding. The practical skills and knowledge I’ve gained empower me to tackle real-world challenges and contribute meaningfully to the field of artificial intelligence. I highly recommend this certification to anyone interested in AI, whether you are just starting out or looking to deepen your expertise.
      </h3>
      <h3>
        Stay tuned for my demo video, where I’ll showcase the practical aspects of Google Cloud Vertex AI and share more insights from my learning journey. Thank you for joining me on this exciting adventure into the world of artificial intelligence!
      </h3>
      <iframe className="pfp1" alt="pfp" src={ai_dev} width="100%" height="350px"  autoplay="0" muted></iframe>
        <br/>
          <h2>Description: Vertex AI Demo Video</h2>
      </form>

      {/* <div style={{ marginTop: '20px' }}>
        <h3>Watch My Demo Video</h3>
       
        <video width="600" controls>
          <source src="path_to_your_demo_video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div> */}
      
    </div>
  );
};

export default VertexAI;
