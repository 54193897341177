import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./Energy.css";

const EnergyConsum = () => {
  const barChartRef = useRef();
  const bubbleChartRef = useRef();
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch('/energy_consumption_data.json')
      .then(response => response.json())
      .then(data => {
        setData(data);
        drawCharts(data);
      })
      .catch(error => console.error("Error fetching data: ", error));
      console.log(JSON.stringify(data));

    const drawCharts = (data) => {
      const margin = { top: 50, right: 30, bottom: 50, left: 60 },
            width = 350 - margin.left - margin.right,
            height = 400 - margin.top - margin.bottom;

      // Bar Chart
      const barData = data.map(d => ({
        month: d.month,
        electricity: d.electricity
      }));

      const xScale = d3.scaleBand()
        .domain(barData.map(d => d.month))
        .range([0, width])
        .padding(0.3);

      const yScale = d3.scaleLinear()
        .domain([0, d3.max(barData, d => d.electricity)])
        .nice()
        .range([height, 0]);

      const barSvg = d3.select(barChartRef.current)
        .html("")
        .append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
        .append("g")
          .attr("transform", `translate(${margin.left},${margin.top})`);

      barSvg.append("g")
        .selectAll(".bar")
        .data(barData)
        .enter().append("rect")
          .attr("class", "bar")
          .attr("x", d => xScale(d.month))
          .attr("y", d => yScale(d.electricity))
          .attr("width", xScale.bandwidth())
          .attr("height", d => height - yScale(d.electricity));

      barSvg.append("g")
        .attr("class", "x-axis")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(xScale));

      barSvg.append("g")
        .attr("class", "y-axis")
        .call(d3.axisLeft(yScale));

      // Bubble Chart
      const bubbleData = data.map(d => ({
        month: d.month,
        value: d.electricity
      }));

      const radiusScale = d3.scaleSqrt()
        .domain([0, d3.max(bubbleData, d => d.value)])
        .range([0, 15]);

      const bubbleSvg = d3.select(bubbleChartRef.current)
        .html("")
        .append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
        .append("g")
          .attr("transform", `translate(${margin.left},${margin.top})`);

      bubbleSvg.selectAll(".bubble")
        .data(bubbleData)
        .enter().append("circle")
          .attr("class", "bubble")
          .attr("cx", d => xScale(d.month) + xScale.bandwidth() / 2)
          .attr("cy", d => yScale(d.value))
          .attr("r", d => radiusScale(d.value));
    };
  }, []);

  return (
    <div className="bubble-cont">
<div className="chart-container">
      <h1>Building Interactive Energy Consumption Visualizations with React and D3</h1>
      
      
      <div ref={barChartRef} className="bar-chart"></div>
      <div ref={bubbleChartRef} className="bubble-chart"></div>
      
      
    </div>
    </div>
    
  );
};

export default EnergyConsum;
