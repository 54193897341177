import React from 'react'
// import soccer1 from '../assets/soccer1.MP4'
// import soccer from '../assets/soccer.MP4'
// import soccer2 from '../assets/soccer2.MP4'
// import soccer3 from '../assets/soccer3.MP4'

function Soccer() {
  return (
    <div >
    <h1>Live streaming soccer matches on Twitch.</h1>
    <div className="intro2">
      <div className="port-2">
          {/* <iframe className="pfp1" alt="pfp" src={soccer} width="100%" height="350px"  autoplay="0" muted></iframe> */}
        <br/>
          <h2>Twitch Streaming Highlight 1</h2>
        </div>
        <div className="port-2">
          {/* <iframe className="pfp1" alt="pfp" src={soccer2} width="100%" height="350px"  autoplay="0" muted></iframe> */}
        <br/>
          <h2>Twitch Streaming Highlight 2</h2>
        </div>
        <div className="port-2">
          {/* <iframe className="pfp1" alt="pfp" src={soccer2} width="100%" height="350px"  autoplay="0" muted></iframe> */}
        <br/>
          <h2>Twitch Streaming Highlight 2</h2>
        </div>
        {/* <div className="port-2">
          <iframe className="pfp1" alt="pfp" src={soccer2} width="100%" height="350px"  autoplay="0" muted></iframe>
        <br/>
          <h2>Twitch Streaming Highlight 2</h2>
        </div> */}
        {/* <div className="port-2">
          <iframe className="pfp1" alt="pfp" src={soccer2} width="100%" height="350px"  autoplay="0" muted></iframe>
        <br/>
          <h2>Twitch Streaming Highlight 2</h2>
        </div> */}
        {/* <div className="port-2">
          <iframe className="pfp1" alt="pfp" src={soccer2} width="100%" height="350px"  autoplay="0" muted></iframe>
        <br/>
          <h2>Twitch Streaming Highlight 2</h2>
        </div> */}
        {/* <div className="port-2">
          <iframe className="pfp1" alt="pfp" src={soccer2} width="100%" height="350px" muted></iframe>
        <br/>
          <h2>Twitch Streaming Highlight 2</h2>
        </div> */}
        {/* <div className="port-2">
          <iframe className="pfp1" alt="pfp" src={soccer2} width="100%" height="350px"  autoplay="0" muted></iframe>
        <br/>
          <h2>Twitch Streaming Highlight 2</h2>
        </div> */}
        {/* <div className="port-2">
          <iframe className="pfp1" alt="pfp" src={soccer2} width="100%" height="350px"  autoplay="0" muted></iframe>
        <br/>
          <h2>Twitch Streaming Highlight 2</h2>
        </div> */}
        </div>
        
    </div>
  )
}

export default Soccer