import React from 'react'
import o2 from "../assets/o2.jpeg";
import upwork from "../assets/upwork.png"
import Button from '@mui/material/Button';
function Developer() {
  return (
    <div className="container">
        {/* <h1>Olayinka Fakanbi</h1> */}
        <form className="form">
      
      <img loading="lazy" src={o2} className="pfp1 animate__backInDown" alt="logo" />
      
        <h2 className="paragraph">
            I am a Software Engineer with experience in building web applications <br/>
            ☞ Programming Languages: JavaScript | React | Python | Node.js | TypeScript <br/>

            ☞ Frameworks: A-Frame | Express | Jupyter Notebook, Tailwind CSS, Material UI, Azure AI <br/>
 
            ☞ Databases: Dialogflow CX | MySQL, PostgreSQL, and Google Cloud <br/>

            ☞ Intermediate: Angular | Django | React Native | MongoDB <br/>

            ☞ Software: Microsoft Office Suite | Google Suite <br/>

            ☞ UX: Figma | Trello Board <br/>
            {/* I have a passion for Robotics and creating user-friendly and responsive web applications that provide value to users. 
            I am constantly learning new technologies and improving my skills to stay up-to-date with the latest trends in web development. */}


        </h2>
        {/* <p><img src={upwork} width="130px" height="50px" /></p> */}
        {/* <h2>
        Upwork Freelance Profile Link Below:
        
        </h2> */}
        {/* <Button target="_blank" variant="contained" color="primary" href="https://www.upwork.com/freelancers/~0191a94ab83fc1347e?mp_source=share">
            Learn More </Button> */}
    
        
      </form>
    </div>
  )
}

export default Developer